/* eslint-disable */
import React, { Component } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';

import {FaChevronDown} from  "react-icons/fa";
import DropdownIcon from '-!svg-react-loader!../icons/chevron-down.svg';


class FAQ extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false };
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    render() {

        return (
            <div className="faq" data-is-open={this.state.collapse} data-dropdown-alt={this.props.altDropdown}>
                <div className="title" onClick={this.toggle}>
                    {this.props.question}
                    { (this.props.altDropdown) ?
                        <DropdownIcon />
                        :
                        <FaChevronDown />
                    }
                </div>
                <Collapse isOpen={this.state.collapse}>
                    <Card>
                        <CardBody>
                            <div dangerouslySetInnerHTML={{__html: this.props.answer}} />
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        );

    }

}

export default FAQ;
