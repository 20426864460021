/* eslint-disable */
import PenIcon from '-!svg-react-loader!../icons/pen.svg';
import React, { Component } from 'react';
import './EditButton.css';



class TagManager extends Component {

    render() {

        return (
            <button className="edit-button" onClick={this.props.onClick}>
                <span>Edit</span>
                <PenIcon />
            </button>
        );

    }

}

export default TagManager;
