/* eslint-disable */
import AddIcon from '-!svg-react-loader!../icons/add-circle.svg';
import CircleCheckIcon from '-!svg-react-loader!../icons/checkmark.svg';
import CloseIcon from '-!svg-react-loader!../icons/close-circle.svg';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import ConfirmationInput from './ConfirmationInput';
import './QuickJobStatusToggle.css';
import './WishlistCollectionPicker.css';

class QuickJobStatusToggle extends Component {

    constructor(props) {

        super(props);

        this.lastEntityId = null;

        this.state = {
            collections: [],
            selected: "Default",
            showNew: false,
            newName: "",
            all: []
        }

    }

    reloadSaves() {

        let wishList = localStorage.getItem("candidateWishList");
        if (wishList) {
            wishList = JSON.parse(wishList);
        }

        let wishlistNames = localStorage.getItem("wishlistNames");
        if (wishlistNames) {
            wishlistNames = JSON.parse(wishlistNames);
        }

        this.setState({ all: wishList || [], collections: wishlistNames || [] });

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.id) {
            this.reloadSaves();
        }

    }

    select(collection) {
        this.props.select(collection);
        this.reloadSaves();
        // this.props.toggle();
    }

    toggleNew() {
        
        if (this.state.showNew) {
            this.save(this.state.newName);
        } else {
            this.setState({ newName: ""})
        }

        this.setState({ showNew: !this.state.showNew }, function() {
            this.setState(this.state)
            this.reloadSaves();
        })

    }

    delete(name) {


        let collection = localStorage.getItem("wishlistNames");
        if (collection) {
            collection = JSON.parse(collection);
            collection.splice(collection.indexOf(name), 1);
            localStorage.setItem("wishlistNames", JSON.stringify(collection));
        }

        this.reloadSaves();

        window.globalVars.server.api({
            method: "delete",
            url: "api/user/wishlist-collection",
            data: { name: name },
            then: function(res) {
            }.bind(this),
            catch: function(e) {
                alert("Failed to save");
            }.bind(this)
        })

    }

    save(name) {


        let collection = localStorage.getItem("wishlistNames");
        if (collection) {
            collection = JSON.parse(collection);

            if (name === "" || name === "Default" || collection.indexOf(name) !== -1 ) {
                return;
            }

            collection.push(name);
            localStorage.setItem("wishlistNames", JSON.stringify(collection));
        }

        window.globalVars.server.api({
            method: "post",
            url: "api/user/wishlist-collection",
            data: { name: name },
            then: function(res) {
            }.bind(this),
            catch: function(e) {
                alert("Failed to save");
            }.bind(this)
        })

    }

    render() {

        return (
            <Modal className="wishlist-picker" isOpen={this.props.isOpen} toggle={() => { this.props.toggle(); }}>
                <div className="body">
                    <h3>
                        Select Collection
                    </h3>
                    <div class="border-line-active"></div>
                    <div className="add_collection">
                        <h4>
                            Add Collection
                        </h4>
                        <AddIcon onClick={() => { this.toggleNew(); }} />
                    </div>
 
                    <div className="picker">
                        { this.state.collections.map(item => {
                            return (
                                <div
                                    onClick={() => { this.select(item); }}
                                    data-selected={(this.state.all.indexOf(this.props.id + "||" + item) !== -1)}
                                >
                                    {item}
                                    { (item !== "Wish List 1") &&
                                        <CloseIcon className="delete-btn" onClick={(e) => { 
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            this.delete(item);
                                        }} />
                                    }
                                    { (this.state.all.indexOf(this.props.id + "||" + item) !== -1) && <CircleCheckIcon className="selected-icon" /> }
                                </div>
                            )
                        }) }
                    </div>
                    { (this.state.showNew) &&
                         <div className="new-collection">
                            <ConfirmationInput
                                field="name"
                                onEdited={ () => {  } }
                                onSaved={ () => {  } }
                                id={this.state.id}
                                value={this.state.newName}
                                domId="name"
                                noServerSave={true}
                                type="text"
                                placeholder="New collection name"
                                immediateChange={true}
                                onChange={(value) => {
                                    this.setState({ newName: value }, function() {
                                        this.toggleNew()
                                    })
                                }}
                                tabIndex="100"
                            />
                        </div>
                    }
                    <button className="main" onClick={() => { this.props.toggle() }}>
                        Save changes
                    </button>
                </div>
            </Modal>
        );

    }

}

export default QuickJobStatusToggle;
