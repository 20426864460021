/* eslint-disable */
import FavoriteIcon from "-!svg-react-loader!../icons/favorite.svg";
import { FaRegHeart } from "react-icons/fa";
import { AiFillHeart } from 'react-icons/ai'
import DeleteIcon from "-!svg-react-loader!../icons/x-alt.svg";
import ShareIcon from "-!svg-react-loader!../icons/share.svg";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import uniqid from "uniqid";
import PotNoodleBadge from "../images/potnoodle-badge-2.png";
import permissions from "./Permissions";
import TagsContainer from "./TagsContainer.js";
import utilities from "./Utilities";
import VacancyEmojis from "./VacancyEmojis.js";
import "./VacancyPreviewCard.css";
import { GiSuitcase } from "react-icons/gi";
import { IoTrashOutline } from "react-icons/io5";
import CountdownPopup from "./CountdownPopup";

let Utilities = new utilities();
let Permissions = new permissions();

class VacancyPreviewCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            favorite: false,
            potnoodle: false,
            lastOpened: null,
            modal: false,
        };
    }

    apply = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.props.openQuickApplyModal(this.props.data.id);
    };

    clickOn = (e) => {
        this.props.onClick && this.props.onClick()
        if (this.props.data.id === undefined) {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }
    };

    pushTags = (e, tag) => {
        if (typeof this.props.tagsPush !== "undefined") {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            this.props.tagsPush([tag]);
        }
    };
    removeOne = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        let vacancyWishList = JSON.parse(
            localStorage.getItem("vacancyWishList")
        );
        var remainingvacancyWishList = _.filter(vacancyWishList, function(num) {
            return num != 124;
        });
        localStorage.setItem(
            "vacancyWishList",
            JSON.stringify(remainingvacancyWishList)
        );
    };
    toggleWishList = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        let wishList = [];

        if (Utilities.isENU(window.globalVars.user)) {
            let wishListType = JSON.parse(
                localStorage.getItem("guestWishListType")
            );

            if (wishListType !== "candidate") {
                wishList = JSON.parse(localStorage.getItem("guestWishList"));
            } else {
                if (
                    window.confirm(
                        "You have already started a " +
                            wishListType +
                            " Wishlist, do you want to clear that?"
                    )
                ) {
                    localStorage.setItem("guestWishList", JSON.stringify([]));
                    localStorage.removeItem("guestWishListType");
                    let node = document.getElementsByClassName(
                        "wish-list-count"
                    );
                    if (node[0] !== undefined) node[0].innerHTML = 0;
                    if (node[1] !== undefined) node[1].innerHTML = 0;
                    this.toggleWishList(e);
                }
                return;
            }
        } else {
            wishList = JSON.parse(localStorage.getItem("vacancyWishList"));
        }

        let favorite = false;
        let serverAction = "add";

        if (wishList === undefined || wishList === null) {
            wishList = [];
        }

        let location = wishList.indexOf(this.props.data.id.toString());
        if (location > -1) {
            wishList.splice(location, 1);
            serverAction = "remove";
        } else {
            wishList.push(this.props.data.id.toString());
            favorite = true;
            window.globalVars.utilities.trackEvent(
                "wishlist",
                "Added to Wishist",
                this.state.id,
                {
                    id: this.props.data.id,
                }
            );
        }

        if (Utilities.isENU(window.globalVars.user)) {
            localStorage.setItem("guestWishList", JSON.stringify(wishList));
            localStorage.setItem(
                "guestWishListType",
                JSON.stringify("vacancy")
            );
        } else {
            localStorage.setItem("vacancyWishList", JSON.stringify(wishList));
        }

        this.setState({
            favorite: favorite,
        });

        var node = document.getElementsByClassName("wish-list-count");
        if (node[0] !== undefined) node[0].innerHTML = wishList.length;
        if (node[1] !== undefined) node[1].innerHTML = wishList.length;

        if (!Utilities.isENU(window.globalVars.user)) {
            window.globalVars.server.api({
                method: "post",
                url: "api/user/wishlist/" + serverAction,
                data: {
                    id: this.props.data.id,
                },
                then: function(res) {}.bind(this),
                catch: function(e) {}.bind(this),
            });
        }
    };

    componentDidMount() {
        if (this.props.data.id !== undefined) {
            let wishList = JSON.parse(localStorage.getItem("vacancyWishList"));

            if (Utilities.isENU(window.globalVars.user)) {
                let guestWishListType = JSON.parse(
                    localStorage.getItem("guestWishListType")
                );

                if (guestWishListType === "vacancy") {
                    wishList = JSON.parse(
                        localStorage.getItem("guestWishList")
                    );
                }
            }

            if (wishList !== undefined && wishList !== null) {
                if (
                    wishList.indexOf(this.props.data.id) > -1 ||
                    wishList
                        .map((item) => parseInt(item))
                        .indexOf(this.props.data.id) > -1
                ) {
                    this.setState({
                        favorite: true,
                    });
                }
            }

            let user = localStorage.getItem("user");

            if (!window.globalVars.utilities.isENU(user)) {
                user = JSON.parse(user);

                if (!window.globalVars.utilities.isENU(user.viewHistory)) {
                    for (let i = 0; i < user.viewHistory.length; i++) {
                        let history = user.viewHistory[i];

                        if (
                            history.category === "vacancy" &&
                            history.bullhornId === this.props.data.id
                        ) {
                            this.setState({
                                lastOpened:
                                    "Viewed " +
                                    window.globalVars.utilities.timeSince(
                                        history.date
                                    ),
                            });
                        }
                    }
                }
            }

            if (
                this.props.data.tags !== undefined &&
                this.props.data.tags.length !== 0
            ) {
                for (let i = 0; i < this.props.data.tags.length; i++) {
                    let tag = this.props.data.tags[i];
                    if (tag.name === "Pot Noodle") {
                        this.setState({
                            potnoodle: true,
                        });
                    }
                }
            }
        }
    }

    openCountdownPopup = () => {
        this.setState({ modal: true });
    };

    render() {
        let job = this.props.data || {
            id: undefined,
            type: undefined,
            companyDescription: undefined,
            tags: undefined,
            emojis: undefined,
        };

        let preparedTags = [];

        if (!Utilities.isENU(job.id)) {
            if (this.props.type === "vacancy") {
                if (
                    job.customTextBlock4 !== undefined &&
                    job.customTextBlock4 !== null
                ) {
                    preparedTags = job.customTextBlock4.map((item) => {
                        return {
                            name: item,
                            id: uniqid(),
                        };
                    });
                }

                job.companyDescription = Utilities.shorten(
                    Utilities.htmlToText(
                        job.clientCorporation
                            ? job.clientCorporation.companyDescription
                            : ""
                    ),
                    180
                );
                job.type = job.title;
                job.tags = preparedTags;
                job.emojis = job.customTextBlock1;
            } else if (this.props.type === "outside-vacancy") {
                if (job.tags !== undefined && job.tags !== null) {
                    let jobTags = JSON.parse(JSON.stringify(job.tags));

                    preparedTags = jobTags.map((item) => {
                        if (item.name && typeof item.name === "string") {
                            return item;
                        }
                        return {
                            name: item,
                            id: uniqid(),
                        };
                    });
                }

                job.companyDescription = Utilities.shorten(
                    job ? job.description : "",
                    180
                );
                job.type = job.title || job.category;
                job.tags = JSON.parse(JSON.stringify(preparedTags));
                job.emojis = [];
            }
        }

        let FavoriteButton = null;

        if (this.props.type === "vacancy") {
            // Show favorite button if logged out or logged in as a grad
            if (Permissions.isAllowed("JOB_WISHLIST")) {
                if (job.id !== undefined) {
                    FavoriteButton = (
                        <>
                            {this.props.deleteable ? (
                                <button
                                    className="favorite-btn trash_icon"
                                    onClick={(e) => {
                                        this.props.onDelete(job, e);
                                    }}
                                >
                                    <AiFillHeart />
                                </button>
                            ) : (
                                <>
                                <button
                                    className="favorite-btn"
                                    onClick={(e) => {
                                        this.toggleWishList(e);
                                    }}
                                    data-active={this.state.favorite}
                                >
                                    {this.state.favorite ? <AiFillHeart /> : <FaRegHeart /> }
                                </button>
                                </>
                            )}
                        </>
                    );
                } else {
                    FavoriteButton = (
                        <button
                            className="favorite-btn"
                            data-active={this.state.favorite}>
                            <Skeleton width="30px" />
                        </button>
                    );
                }
            }
        }

        return (
            <div
                /* className="col-md-6 col-lg-4" */ className={`${
                    this.props.slider
                        ? "slide-item"
                        : "col-lg-6"
                }`}
            >
                <Link
                    onClick={(e) => {
                        this.clickOn(e);
                        /*this.openCountdownPopup()*/
                    }}
                    to={
                        this.props.type === "vacancy"
                            ? "/jobs/post/" + job.id
                            : "/jobs/" + this.props.city + "/post/" + job.id
                    }
                    className="vacancy-preview-card"
                    data-potnoodle={this.state.potnoodle}
                    data-favorite-show={FavoriteButton === null ? false : true}
                    data-loading={job.id === undefined ? "true" : "false"}
                >
                <div className="content_wrapper">
                    <div class="category_filter_wrap">
                        <span className="suitcase_icon hide">
                            {job.clientContact?.customText14 ? (
                                <img
                                    src={
                                        job.clientContact?.customText14 &&
                                        window.globalVars.bucket +
                                            window.globalVars
                                                .bucketBusinessProfilePictureDir +
                                            job.clientContact.id +
                                            "/" +
                                            job.clientContact.customText14
                                    }
                                    height={40}
                                    width={40}
                                />
                            ) : (
                                <GiSuitcase size={25} color={"white"} />
                            )}
                        </span>
                        <p className="internships_tag">Internship</p>
                        <div className="d-flex align-items-center">
                            {FavoriteButton}
                        </div>
                    </div>
                    <h4>
                        {job.type !== undefined ? (
                            job.type
                        ) : (
                            <Skeleton count={2} width="260px" />
                        )}{" "}
                    </h4>
                    {/* <div class="border-line-active"></div> */}
                    {/* {
                        (job.id !== undefined) ?
                            <button className="share-btn">
                                <ShareIcon />
                                <span>Share</span>
                            </button>
                        :
                            <button className="share-btn">
                                <Skeleton width="70px" />
                            </button>
                    } */}
                    {job.id !== undefined ? (
                        <p className="description">
                            {!Utilities.isENU(job.companyDescription)
                                ? job.companyDescription
                                : "No Company Description"}
                        </p>
                    ) : (
                        <Skeleton count="3" />
                    )}
                    </div>
                    {this.state.lastOpened !== null && (
                        <label className="last-open">
                            {this.state.lastOpened}
                        </label>
                    )}
                    <div className="hide">
                        <TagsContainer
                            tags={preparedTags}
                            selectedTag={this.props.selectedTag}
                            closeable={false}
                            theme="2"
                            onClick={(e, tag) => {
                                this.pushTags(e, tag);
                            }}
                        />
                    </div>
                    <div className="cta">
                        <div className="hide">
                            {job.id !== undefined ? (
                                <VacancyEmojis limit={4} items={job.emojis} />
                            ) : (
                                <Skeleton width="140px" />
                            )}
                        </div>
                        {job.id !== undefined ? (
                            <button className={`apply-for-job-btn default_cta_btn`}>
                                Apply Now
                            </button>
                        ) : (
                            <Skeleton width="100px" />
                        )}
                    </div>
                    {this.state.potnoodle && (
                        <img
                            alt="PotNoodle Badge"
                            className="potnoodle-badge hide"
                            src={PotNoodleBadge}
                        />
                    )}
                </Link>
                {this.state.modal && (
                    <CountdownPopup
                        modal={this.state.modal}
                        toggle={(state) => this.setState({ modal: state })}
                    />
                )}
            </div>
        );
    }
}

export default VacancyPreviewCard;