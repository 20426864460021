import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './Modal.css';
import Countdown from './Countdown'
import * as Validations from '../helpers/validations'
import { toast } from 'react-toastify';
import LoaderImg from '../images/new-design/waiting.gif'
import utilities from './Utilities';
import {FiPlus} from "react-icons/fi";

const DATES = [
    new Date(2022, 3, 14),
    new Date(2022, 3, 14),
    new Date(2022, 3, 14)
]

let Utilities = new utilities();

class CountdownPopup extends Component {

    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem("user"));

        let now = new Date();
        let currentRound = DATES[0];
        let currentRoundI = 0;
        if (DATES[0] < now) {
            currentRound = DATES[1]
            currentRoundI = 1;
        }
        if (DATES[1] < now) {
            currentRound = DATES[2]
            currentRoundI = 2;
        }

        this.state = {
            currentRound: currentRound,
            currentRoundI: currentRoundI,
            modal: props.modal || false,
            email: '',
            isLoggedIn: Utilities.isENU(user) ? false : true
        }
    }

    toggle = (isApi) => {
        if(isApi){
            if (!Validations.isEmpty(this.state.email)) {
                if (!Validations.isEmail(this.state.email) || Validations.isBlankSpace(this.state.email)){
                    return toast.error("Please enter valid email.")
                }else{
                    window.globalVars.server.api({
                        method: "post",
                        url: "/api/internships-go-live/emails",
                        data: {
                            email: this.state.email
                        },
                        then: function(res) {
                            toast.success("Your email has been subscribed successfully.")
                        }.bind(this),
                        catch: function(e) {
            
                        }.bind(this)
                    })
                } 
            }
    
            let user = JSON.parse(localStorage.getItem("user"));
            if (Utilities.isENU(user)) {
                setTimeout(function () {
                    window.location.href = "/login"
                }.bind(this), 300)
            }else{
                setTimeout(function () {
                    if(user.type === 3){
                        window.location.href = "/business/onboarding/0"
                    }else if(user.type === 4){
                        window.location.href = "/edit-profile"
                    }
                    
                }.bind(this), 300)
            }
        }
        this.setState({
          modal: !this.state.modal
        },()=>{
            this.props.toggle(this.state.modal)
        });
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} className="coming_soon_modal">
                 <span className="close_btn" onClick={()=>this.toggle(false)}><FiPlus /></span>
                <ModalBody>
                    <figure>
                        <img src={LoaderImg} alt="loader image" />
                    </figure>
                    <p>{this.props.title || 'Internships Live Go Live In'}</p>
                    <div className="countdown_wrap">
                        <Countdown target={this.state.currentRound} targetI={this.state.currentRoundI} />
                        <fieldset>
                            <label>Email Me When Applications Open</label>
                            <input onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} name="email" placeholder='Enter Email' />
                        </fieldset>    
                        <button className="okay-btn" type='button' onClick={()=>this.toggle(true)}>{this.state.isLoggedIn ? 'Update Profile Now' : 'Okay'}</button>
                    </div>
                </ModalBody>
            </Modal>
        );

    }

}

export default CountdownPopup;
