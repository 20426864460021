/* eslint-disable */
import BirthdayBenefits from '-!svg-react-loader!../icons/emojis/BirthdayBenefits.svg';
import CareerDevelopmentPolicy from '-!svg-react-loader!../icons/emojis/CareerDevelopmentPolicy.svg';
import CompanyTechProvided from '-!svg-react-loader!../icons/emojis/CompanyTechProvided.svg';
import DentalInsurance from '-!svg-react-loader!../icons/emojis/DentalInsurance.svg';
import EmployeeDiscounts from '-!svg-react-loader!../icons/emojis/EmployeeDiscounts.svg';
import FlexibleWorking from '-!svg-react-loader!../icons/emojis/FlexibleWorking.svg';
import GenerousHolidayAllowance from '-!svg-react-loader!../icons/emojis/GenerousHolidayAllowance.svg';
import GymMembership from '-!svg-react-loader!../icons/emojis/GymMembership.svg';
import HealthInsurance from '-!svg-react-loader!../icons/emojis/HealthInsurance.svg';
import HighEarningPotential from '-!svg-react-loader!../icons/emojis/HighEarningPotential.svg';
import InternationalOffices from '-!svg-react-loader!../icons/emojis/InternationalOffices.svg';
import MusicInTheOffice from '-!svg-react-loader!../icons/emojis/MusicInTheOffice.svg';
import OfficeGames from '-!svg-react-loader!../icons/emojis/OfficeGames.svg';
import OfficeSocials from '-!svg-react-loader!../icons/emojis/OfficeSocials.svg';
import PersonalWellbeing from '-!svg-react-loader!../icons/emojis/PersonalWellbeing.svg';
import PetFriendlyOffice from '-!svg-react-loader!../icons/emojis/PetFriendlyOffice.svg';
import ShowerFacilities from '-!svg-react-loader!../icons/emojis/ShowerFacilities.svg';
import SociallyResponsibleCompany from '-!svg-react-loader!../icons/emojis/SociallyResponsibleCompany.svg';
import StockedKitchen from '-!svg-react-loader!../icons/emojis/StockedKitchen.svg';
import TeamBondingAwayDays from '-!svg-react-loader!../icons/emojis/TeamBondingAwayDays.svg';
import TravelWithWork from '-!svg-react-loader!../icons/emojis/TravelWithWork.svg';

import BirthdayBenefitsPN from '-!svg-react-loader!../images/potnoodle/perks/BirthdayBenefits.svg';
import CareerDevelopmentPolicyPN from '-!svg-react-loader!../images/potnoodle/perks/CareerDevelopmentPolicy.svg';
import CompanyTechProvidedPN from '-!svg-react-loader!../images/potnoodle/perks/CompanyTechProvided.svg';
import DentalInsurancePN from '-!svg-react-loader!../images/potnoodle/perks/DentalInsurance.svg';
import EmployeeDiscountsPN from '-!svg-react-loader!../images/potnoodle/perks/EmployeeDiscounts.svg';
import FlexibleWorkingPN from '-!svg-react-loader!../images/potnoodle/perks/FlexibleWorking.svg';
import GenerousHolidayAllowancePN from '-!svg-react-loader!../images/potnoodle/perks/GenerousHolidayAllowance.svg';
import GymMembershipPN from '-!svg-react-loader!../images/potnoodle/perks/GymMembership.svg';
import HealthInsurancePN from '-!svg-react-loader!../images/potnoodle/perks/HealthInsurance.svg';
import HighEarningPotentialPN from '-!svg-react-loader!../images/potnoodle/perks/HighEarningPotential.svg';
import InternationalOfficesPN from '-!svg-react-loader!../images/potnoodle/perks/InternationalOffices.svg';
import MusicInTheOfficePN from '-!svg-react-loader!../images/potnoodle/perks/MusicInTheOffice.svg';
import OfficeGamesPN from '-!svg-react-loader!../images/potnoodle/perks/OfficeGames.svg';
import OfficeSocialsPN from '-!svg-react-loader!../images/potnoodle/perks/OfficeSocials.svg';
import PersonalWellbeingPN from '-!svg-react-loader!../images/potnoodle/perks/PersonalWellbeing.svg';
import PetFriendlyOfficePN from '-!svg-react-loader!../images/potnoodle/perks/PetFriendlyOffice.svg';
import ShowerFacilitiesPN from '-!svg-react-loader!../images/potnoodle/perks/ShowerFacilities.svg';
import SociallyResponsibleCompanyPN from '-!svg-react-loader!../images/potnoodle/perks/SociallyResponsibleCompany.svg';
import StockedKitchenPN from '-!svg-react-loader!../images/potnoodle/perks/StockedKitchen.svg';
import TeamBondingAwayDaysPN from '-!svg-react-loader!../images/potnoodle/perks/TeamBondingAwayDays.svg';
import TravelWithWorkPN from '-!svg-react-loader!../images/potnoodle/perks/TravelWithWork.svg';

import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import "./VacancyEmojis.css";



class VacancyEmojis extends Component {

    generateRandomStr(length) {

    	var text = "";
    	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    	for (var i = 0; i < length; i++)
    		text += possible.charAt(Math.floor(Math.random() * possible.length));

    	return text;

    }

    render() {

        let items = this.props.items;
        let limit = this.props.limit + 1;
        let randomId = this.generateRandomStr(10);

        if ( (items === undefined) || (items === null) || (items === "") || (items.length === 0) ) {
            return null;
        }

        items = items.slice(0, limit);

        return (
            <div className="vacancy-emojis clearfix" data-direction={this.props.direction}>
                { (items.indexOf("GenerousHolidayAllowance") > -1) &&
                    <div>
                        { (window.region === "GB") ? <GenerousHolidayAllowance id={"GenerousHolidayAllowance" + randomId} /> : <GenerousHolidayAllowancePN id={"GenerousHolidayAllowance" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"GenerousHolidayAllowance" + randomId}>
                            Generous Holiday Allowance
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Generous Holiday Allowance</label> }
                    </div>
                }
                { (items.indexOf("BirthdayBenefits") > -1) &&
                    <div>
                        { (window.region === "GB") ? <BirthdayBenefits id={"BirthdayBenefits" + randomId} /> : <BirthdayBenefitsPN id={"BirthdayBenefits" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"BirthdayBenefits" + randomId}>
                            Birthday Benefits
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Birthday Benefits</label> }
                    </div>
                }
                { (items.indexOf("PetFriendlyOffice") > -1) &&
                    <div>
                        { (window.region === "GB") ? <PetFriendlyOffice id={"PetFriendlyOffice" + randomId} /> : <PetFriendlyOfficePN id={"PetFriendlyOffice" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"PetFriendlyOffice" + randomId}>
                            Office pet
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Office pet</label> }
                    </div>
                }
                { (items.indexOf("OfficeGames") > -1) &&
                    <div>
                        { (window.region === "GB") ? <OfficeGames id={"OfficeGames" + randomId} /> : <OfficeGamesPN id={"OfficeGames" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"OfficeGames" + randomId}>
                            Office games e.g. ping pong, tables tennis, office socials
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Office games e.g. ping pong, tables tennis, office socials</label> }
                    </div>
                }
                { (items.indexOf("GymMembership") > -1) &&
                    <div>
                        { (window.region === "GB") ? <GymMembership id={"GymMembership" + randomId} /> : <GymMembershipPN id={"GymMembership" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"GymMembership" + randomId}>
                            Gym membership / access
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Gym membership / access</label> }
                    </div>
                }
                { (items.indexOf("CompanyTechProvided") > -1) &&
                    <div>
                        { (window.region === "GB") ? <CompanyTechProvided id={"CompanyTechProvided" + randomId} /> : <CompanyTechProvidedPN id={"CompanyTechProvided" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"CompanyTechProvided" + randomId}>
                            Company phone / laptop /gear
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Company phone / laptop /gear</label> }
                    </div>
                }
                { (items.indexOf("EmployeeDiscounts") > -1) &&
                    <div>
                        { (window.region === "GB") ? <EmployeeDiscounts id={"EmployeeDiscounts" + randomId} /> : <EmployeeDiscountsPN id={"EmployeeDiscounts" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"EmployeeDiscounts" + randomId}>
                            Employee discounts
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Employee discounts</label> }
                    </div>
                }
                { (items.indexOf("TeamBondingAwayDays") > -1) &&
                    <div>
                        { (window.region === "GB") ? <TeamBondingAwayDays id={"TeamBondingAwayDays" + randomId} /> : <TeamBondingAwayDaysPN id={"TeamBondingAwayDays" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"TeamBondingAwayDays" + randomId}>
                            Team Bonding / Activities/ Away Days
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Team Bonding / Activities/ Away Days</label> }
                    </div>
                }
                { (items.indexOf("StockedKitchen") > -1) &&
                    <div>
                        { (window.region === "GB") ? <StockedKitchen id={"StockedKitchen" + randomId} /> : <StockedKitchenPN id={"StockedKitchen" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"StockedKitchen" + randomId}>
                            Stocked Kitchen / Breakfast provided
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Stocked Kitchen / Breakfast provided</label> }
                    </div>
                }
                { (items.indexOf("HealthInsurance") > -1) &&
                    <div>
                        { (window.region === "GB") ? <HealthInsurance id={"HealthInsurance" + randomId} /> : <HealthInsurancePN id={"HealthInsurance" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"HealthInsurance" + randomId}>
                            Healthcare
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Healthcare</label> }
                    </div>
                }
                { (items.indexOf("ShowerFacilities") > -1) &&
                    <div>
                        { (window.region === "GB") ? <ShowerFacilities id={"ShowerFacilities" + randomId} /> : <ShowerFacilitiesPN id={"ShowerFacilities" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"ShowerFacilities" + randomId}>
                            Showers at Office
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Showers at Office</label> }
                    </div>
                }
                { (items.indexOf("DentalInsurance") > -1) &&
                    <div>
                        { (window.region === "GB") ? <DentalInsurance id={"DentalInsurance" + randomId} /> : <DentalInsurancePN id={"DentalInsurance" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"DentalInsurance" + randomId}>
                            Dental Care
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Dental Care</label> }
                    </div>
                }
                { (items.indexOf("FlexibleWorking") > -1) &&
                    <div>
                        { (window.region === "GB") ? <FlexibleWorking id={"FlexibleWorking" + randomId} /> : <FlexibleWorkingPN id={"FlexibleWorking" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"FlexibleWorking" + randomId}>
                            Flexi time working
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Flexi time working</label> }
                    </div>
                }
                { (items.indexOf("TravelWithWork") > -1) &&
                    <div>
                        { (window.region === "GB") ? <TravelWithWork id={"TravelWithWork" + randomId} /> : <TravelWithWorkPN id={"TravelWithWork" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"TravelWithWork" + randomId}>
                            Travel with work
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Travel with work</label> }
                    </div>
                }
                { (items.indexOf("InternationalOffices") > -1) &&
                    <div>
                        { (window.region === "GB") ? <InternationalOffices id={"InternationalOffices" + randomId} /> : <InternationalOfficesPN id={"InternationalOffices" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"InternationalOffices" + randomId}>
                            The company has multiple / international offices
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>The company has multiple / international offices</label> }
                    </div>
                }
                { (items.indexOf("OfficeSocials") > -1) &&
                    <div>
                        { (window.region === "GB") ? <OfficeSocials id={"OfficeSocials" + randomId} /> : <OfficeSocialsPN id={"OfficeSocials" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"OfficeSocials" + randomId}>
                            Office Socials / Nights Out
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Office Socials / Nights Out</label> }
                    </div>
                }
                { (items.indexOf("HighEarningPotential") > -1) &&
                    <div>
                        { (window.region === "GB") ? <HighEarningPotential id={"HighEarningPotential" + randomId} /> : <HighEarningPotentialPN id={"HighEarningPotential" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"HighEarningPotential" + randomId}>
                            Generous Salary or Bonus or competitive salary
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Generous Salary or Bonus or competitive salary</label> }
                    </div>
                }
                { (items.indexOf("SociallyResponsibleCompany") > -1) &&
                    <div>
                        { (window.region === "GB") ? <SociallyResponsibleCompany id={"SociallyResponsibleCompany" + randomId} /> : <SociallyResponsibleCompanyPN id={"SociallyResponsibleCompany" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"SociallyResponsibleCompany" + randomId}>
                            Socially responsible company
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Socially responsible company</label> }
                    </div>
                }
                { (items.indexOf("CareerDevelopmentPolicy") > -1) &&
                    <div>
                        { (window.region === "GB") ? <CareerDevelopmentPolicy id={"CareerDevelopmentPolicy" + randomId} /> : <CareerDevelopmentPolicyPN id={"CareerDevelopmentPolicy" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"CareerDevelopmentPolicy" + randomId}>
                            Strong personal development opportunity e.g. they pay for you to upskill
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Strong personal development opportunity e.g. they pay for you to upskill</label> }
                    </div>
                }
                { (items.indexOf("PersonalWellbeing") > -1) &&
                    <div>
                        { (window.region === "GB") ? <PersonalWellbeing id={"PersonalWellbeing" + randomId} /> : <PersonalWellbeingPN id={"PersonalWellbeing" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"PersonalWellbeing" + randomId}>
                            Yoga / wellness classes
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Yoga / wellness classes</label> }
                    </div>
                }
                { (items.indexOf("MusicInTheOffice") > -1) &&
                    <div>
                        { (window.region === "GB") ? <MusicInTheOffice id={"MusicInTheOffice" + randomId} /> : <MusicInTheOfficePN id={"MusicInTheOffice" + randomId} /> }
                        <UncontrolledTooltip placement="bottom" target={"MusicInTheOffice" + randomId}>
                            Music in the office
                        </UncontrolledTooltip>
                        { (this.props.direction === "vertical") && <label>Music in the office</label> }
                    </div>
                }
            </div>
        );

    }

}

export default VacancyEmojis;
