import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './FooterPN.css';
import {FaMapMarkerAlt, FaPhoneAlt  } from "react-icons/fa";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import PowerdImg from '../images/new-design/PoweredbyGradbay.png'
import { IoPinOutline } from 'react-icons/io5';
import { HiOutlinePhone } from 'react-icons/hi';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {  }
    }

    onClickToRedirect = () => {
        console.log('onClickToRedirect ===>>>', window.location.pathname)
        if(window.location.pathname !== "/contact-us"){
            window.location.href = '/contact-us'
        }
    }

    render() {

        return (
            <footer>
                <div className="main-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="footer-content">
                                    <h3>Our Passion Points</h3>
                                   
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <ul className="footer_links">
                                                <li>
                                                    <Link to="/tech"> Tech</Link>
                                                </li>
                                                <li>
                                                    <Link to="/music">Music</Link>
                                                </li>
                                                <li>
                                                    <Link to="/film">Film</Link>
                                                </li>
                                                <li>
                                                    <Link to="/gaming">Gaming</Link>
                                                </li>
                                                <li>
                                                    <Link to="/publishing">Publishing </Link>
                                                </li>
                                                <li>
                                                    <Link to="/hospitality">Hospitality </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <h3 className="mt-4 mb-3">Social Links</h3> */}
                                    
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="footer-content">
                                    <h3>Quick Links</h3>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="footer_links">
                                               
                                                <li>
                                                    <Link to="/faqs">FAQs</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact-us">Contact</Link>
                                                </li>
                                               
                                                <li>
                                                    <a target="_blank" href="https://www.potnoodle.com/products.html">Products</a>
                                                </li>
                                                <li>
                                                    <Link to="/terms">Terms & conditions apply</Link>
                                                </li>
                                                
                                                <li>
                                                    <a target="_blank" href="https://www.unilevernotices.com/united-kingdom/english/cookie-notice/notice.html">Cookie Notice</a>
                                                </li>
                                                <li>
                                                    <Link to="/privacy-notice"  href="/privacy-notice">Privacy Notice</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-content">
                                    <h3>Contact Us</h3>
                                    <ul className="footer_address mb-4">
                                        <li>
                                            <IoPinOutline size={22} />
                                            145 City Road, London, EC1V 1AZ, England
                                        </li>
                                        <li>
                                            <HiOutlinePhone size={22} />
                                            <a href="tel:+44-0203 976 6500" >0203 976 6500</a>
                                        </li>
                                    </ul>
                                   
                                    {/* <ul className="social-icon_footer">
                                        <li>
                                            <a href="https://www.facebook.com/potnoodle/?ref=page_internal" target="_blank">
                                                <FaFacebookF />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/potnoodle" target="_blank">
                                                <FaTwitter />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/user/potnoodle" target="_blank">
                                                <FaYoutube />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/potnoodle/" target="_blank">
                                                <FaInstagram />
                                            </a>
                                        </li>
                                    </ul> */}
                                    {/* <button className="footer_btn" onClick={this.onClickToRedirect}>SEND US MESSAGE</button> */}
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                        <div className="row">
                        <div className="col-md-12">
                                <div className='powered_by'>
                                    <div className="copyright-note">
                                        <p>Copyright © 2022 Unilever | All Right Reserved</p>
                                    </div>
                                    <img src={PowerdImg} alt="pn powered by Unilever" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
