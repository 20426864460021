import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from "react-router-dom";
import BackgroundImage from '../images/statistics.jpg';
import './Statistics.css';


class Statistics extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

        window.globalVars.server.api({
            method: "get",
            url: "api/statistics",
            then: function(res) {


                let stats = {};

                if (res && res.data && res.data.data) {
                    for (let i = 0; i < res.data.data.length; i++) {
                        let row = res.data.data[i];
                        stats[row.key] = row.value;
                    }
                }

                this.setState({ stats: stats })

            }.bind(this),
            catch: function(e) {

            }.bind(this),
            ignoreUnauthorized: true
        })

    }

    render() {

        return (
            <div className="statistics-home">

                <h2>Our Community</h2>

                <div className="background" style={{
                    backgroundImage: `url('${BackgroundImage}')`
                }}>

                    <div className="grid">

                        <div className="metric">
                            <p>{this.state.stats ? (this.state.stats.gradCount || 0) : 0}</p>
                            <label>Registered Users</label>
                            <Link to="/signup">Start Applying</Link>
                        </div>

                        <div className="metric">
                            <p>{this.state.stats ? (this.state.stats.jobCount || 0) : 0}</p>
                            <label>Live Jobs</label>
                            <Link to="/signup/business">Post Now</Link>
                        </div>

                        <div className="metric">
                            <p>{this.state.stats ? (this.state.stats.languageCount || 0) : 0}</p>
                            <label>Languages Covered</label>
                        </div>

                        <div className="metric">
                            <p>{this.state.stats ? (this.state.stats.stemRatio + "%" || 0) : 0}</p>
                            <label>STEM</label>
                        </div>

                        {/*<div className="metric">
                            <p>{this.state.stats ? (this.state.stats.degreeCount || 0) : 0}</p>
                            <label>Subjects</label>
                        </div>

                        <div className="metric">
                            <p>{this.state.stats ? (this.state.stats.lgbtCount || 0) : 0}</p>
                            <label>LBGTQ+</label>
                        </div> */}

                        <div className="metric">
                            <p>{this.state.stats ? (this.state.stats.universityCount || 0) : 0}</p>
                            <label>Universities</label>
                        </div>

                        <div className="metric">
                            <p>{this.state.stats ? (this.state.stats.gradVideoCount || 0) : 0}</p>
                            <label>Videos</label>
                        </div>

                        <div className="metric">
                            <CircularProgressbar value={this.state.stats ? (this.state.stats.femaleRatio || 0) : 0} text={`${this.state.stats ? (this.state.stats.femaleRatio || 0) : 0}%`} />
                            <label>Female</label>
                        </div>

                        <div className="metric">
                            <CircularProgressbar value={this.state.stats ? (this.state.stats.bameRatio || 0) : 0} text={`${this.state.stats ? (this.state.stats.bameRatio || 0) : 0}%`} />
                            <label>BAME</label>
                        </div>

                        {/*<div className="metric">
                            <CircularProgressbar value={this.state.stats ? (this.state.stats.stemRatio || 0) : 0} text={`${this.state.stats ? (this.state.stats.stemRatio || 0) : 0}%`} />
                            <label>STEM</label>
                        </div>*/}

                        <div className="metric">
                            <CircularProgressbar value={this.state.stats ? (this.state.stats.lgbtRatio || 0) : 0} text={`${this.state.stats ? (this.state.stats.lgbtRatio || 0) : 0}%`} />
                            <label>LGBT+</label>
                        </div>

                    </div>
                </div>
            </div>

        );

    }

}

export default Statistics;
