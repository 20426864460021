import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CTACard from './CTACard';
import './OurService.css';




class OurService extends Component {

    render() {

        return (

            <div className="our-service">

                <div className="main-container">

                    <h2>How it works</h2>

                    <div className="cta-card-container business">

                        <CTACard style={1}>
                            <h4>For Graduates</h4>
                            <p> For Graduates GradBay is an inclusive market place for graduates to find and apply to jobs specifically aimed at the internship, entry and graduate level. We cater for graduates and students from all backgrounds, academic achievement and varying levels of work experience. We understand the challenegs of the post graduation job hunt and have built GradBay to  make this process an effective and positive one for your career by focusing on you as an individual and not just another CV. </p>
                            <div className="list">
                                <h5>Next steps</h5>
                                <p><span>1</span> Sign up </p>
                                <p><span>2</span> Complete your profile</p>
                                <p><span>3</span> Search jobs and apply</p>
                                <p><span>4</span> Start interviewing and get hired</p>
                            </div>
                            <Link to="/signup">Sign up</Link>
                        </CTACard>

                        <CTACard style={2}>
                            <h4>For Businesses</h4>
                            <p>For businesses looking to engage and retain graduate talent we offer new forms of hiring solutions focused on quality of service, speed of delivery and highly targeted results.</p>
                            <p>GradBay is built on data driven insight and years of graduate hiring experience to help your business access the next generation of talent with a product built this decade, not the last one.</p>
                            <p style={{marginBottom: 28}}><span style={{ visibility: "hidden" }}>nextt with auilt this decade, notone.</span></p>
                            <div className="list">
                                <h5>Next steps</h5>
                                <p><span>1</span> Sign up</p>
                                <p><span>2</span> Select a service</p>
                                <p><span>3</span> Submit job info and review applications</p>
                                <p><span>4</span> Interview candidates and start hiring</p>
                            </div>
                            <Link to="/signup/business">Sign up</Link>
                        </CTACard>

                    </div>

                </div>

            </div>

        );

    }

}

export default OurService;
