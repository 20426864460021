/* eslint-disable */
import FavoriteIcon from "-!svg-react-loader!../icons/favorite.svg";
import MessagesIcon from "-!svg-react-loader!../icons/message.svg";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Collapse,
    Container,
    Nav,
    Navbar,
    NavbarToggler,
    NavItem,
} from "reactstrap";
import Logo from "../icons/logo.png";
import LogoPN from "../images/potnoodle/logo.png";
import noProfilePhotoImage from "../images/no-profile-picture.png";
import Dropdown from "./Dropdown";
import "./Navbar.css";
import permissions from "./Permissions";
import utilities from "./Utilities";
import SidebarSearch from "./SidebarSearchNew";
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMenuOutline } from "react-icons/io5";
import CryptoJS from "crypto-js";

let Permissions = new permissions();
let Utilities = new utilities();

class NavbarMain extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            wishListCount: 0,
            baseLocation: "London",
            modal: false,
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
        // this.closeSidebar();
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
            this.setState({ isOpen: false })
        }
    }
    

    logout = () => {
        window.globalVars.server.logout();
    };

    logBack = () => {
        let adminToken = localStorage.getItem("adminToken");
        localStorage.clear();
        window.globalVars.server.saveAuthenticationTokenToLocalStorage(
            adminToken
        );
        window.location.href = "/admin";
    };

    logBackBussiness = () => {
        let adminToken = localStorage.getItem("adminBussinessToken");
        localStorage.clear();
        window.globalVars.server.saveAuthenticationTokenToLocalStorage(
            adminToken
        );
        window.location.href = "/business/company";
    };

    hide() {
        this.setState({
            isOpen: false,
        });
    }

    hide_dropdroun() {
        this.setState({
            isOpen: false,
        });
        document.querySelector('.intership_dropdown').setAttribute("style","visibility: hidden")
    }

    loadWishList() {
        let wishList = [];
        let user = JSON.parse(localStorage.getItem("user"));

        if (Utilities.isENU(user)) {
            wishList = JSON.parse(localStorage.getItem("guestWishList"));
        } else if (Permissions.isAllowed("CANDIDATE_WISHLIST")) {
            wishList = JSON.parse(localStorage.getItem("candidateWishList"));
        } else {
            wishList = JSON.parse(localStorage.getItem("vacancyWishList"));
        }

        if (wishList === null || wishList === undefined) {
            wishList = [];
            localStorage.setItem("vacancyWishList", JSON.stringify(wishList));
            localStorage.setItem("candidateWishList", JSON.stringify(wishList));
            localStorage.setItem("guestWishList", JSON.stringify(wishList));
        }

        this.setState({
            wishListCount: wishList.length,
        });
    }

    componentWillReceiveProps() {
        this.loadWishList();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside)
        this.loadWishList();

        let baseLocation = localStorage.getItem("baseLocation");
        if (baseLocation) {
            this.setState({
                baseLocation: baseLocation,
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    getQueryParams = () => {
        var url = window.location.href;
        var vars = {};
        var hashes = url && url.split("?")[1];
        var hash = hashes && hashes.split("&");
        if (hash) {
            for (var i = 0; i < hash.length; i++) {
                let params = hash[i].split("=");
                vars[params[0]] = params[1];
            }
        }
        return vars;
    };

    sidebarClose = () => {
        document.getElementById("sidebar-search").classList.remove("active");
        document.getElementById("root").classList.remove("modal-open");
    };

    sidebarOpen = () => {
        document.getElementById("sidebar-search").classList.add("active");
        document.body.classList.toggle("modal-open");
        var root = document.getElementsByTagName("html")[0];
        root.setAttribute("class", "modal-open");
        document.getElementById("root").classList.toggle("modal-open");
    };

    openCountdownPopup = () => {
        this.setState({ modal: true });
    };

    render() {
        let loggedInDropDownItems = [];

        let link = "";
        let currentUrl = this.props.currentUrl || "";

        let user = this.props.user;

        let profilePicture = localStorage.getItem("profilePicture");
        let adminToken = localStorage.getItem("adminToken");
        let adminBussinessToken = localStorage.getItem("adminBussinessToken");

        if (!profilePicture) {
            profilePicture = noProfilePhotoImage;
        }

        if (Utilities.isENU(user)) {
            let wishListType = JSON.parse(
                localStorage.getItem("guestWishListType")
            );

            if (Utilities.isENU(wishListType)) {
                link = "/saved/jobs";
            } else {
                link =
                    wishListType === "vacancy" ? "/saved/jobs" : "/saved/grads";
            }
        } else if (user.type === 3) {
            link = "/saved/grads";
            loggedInDropDownItems = [
                {
                    title: "Dashboard",
                    data: "Dashboard",
                    link: "/my-profile/business",
                }, 
                {
                    title: "Edit Profile",
                    data: "Edit Profile",
                    link: "/business/onboarding/0",
                },
                  
                {
                    title: "Company",
                    data: "Company",
                    link: "/business/company",
                },
                {
                    title: "Logout",
                    data: "Logout",
                    onClick: function() {
                        window.globalVars.server.logout();
                    },
                },
            ];
        } else if (user.type === 4) {
            link = "/saved/jobs";
            loggedInDropDownItems = [
                {
                    title: "Dashboard",
                    data: "Dashboard",
                    link: "/dashboard",
                }, 
                {
                    title: "Edit Profile",
                    data: "Edit Profile",
                    link: "/edit-profile",
                },
                {
                    title: "Settings",
                    data: "Settings",
                    link: "/settings",
                },
                {
                    title: "My Files",
                    data: "My Files",
                    link: "/settings#my-files",
                },
                {
                    title: "Logout",
                    data: "Logout",
                    onClick: function() {
                        window.globalVars.server.logout();
                    },
                },
            ];
        }

        let jobListLink = "/jobs";
        let gradsListLink = "/graduates";
        if (this.state.baseLocation !== "London") {
            jobListLink +=
                "/" +
                window.globalVars.outsideLocationCodes[this.state.baseLocation];
            gradsListLink +=
                "/" +
                window.globalVars.outsideLocationCodes[this.state.baseLocation];
        }
        let dashboardUrl = "/";
        let gradSearchDisabled = false;
        if (user) {
            if (user.type === 3) {
                dashboardUrl = !user.onboardingCompleted
                    ? "/business/onboarding"
                    : "/my-profile/business";
            } else if (user.type === 4) {
                dashboardUrl = !user.onboardingCompleted
                    ? "/edit-profile"
                    : "/dashboard";
            } else if (user.type === 5) {
                dashboardUrl = "/admin-marketing";
            } else {
                dashboardUrl = "/admin";
            }

            if (user.role && user.privileges) {
                if (user.privileges.indexOf("searchGrads") === -1) {
                    gradSearchDisabled = true;
                }
            }
        }

        if (user && (user.type === 2 || user.type === 1)) {
            loggedInDropDownItems = [
                {
                    title: "Logout",
                    data: "Logout",
                    onClick: function() {
                        window.globalVars.server.logout();
                    },
                },
            ];
        }

        if (user && user.type === 5) {
            loggedInDropDownItems = [
                {
                    title: "Logout",
                    data: "Logout",
                    onClick: function() {
                        window.globalVars.server.logout();
                    },
                },
            ];
        }
        if (user) {
            loggedInDropDownItems.unshift({
                title: "FAQ",
                data: "FAQ",
                link: "/faqs",
            });
        }

        if (user && user.type === 3) user.canMessage = true;

        let query_params = "";
        let is_url_params = false;

        if (this.getQueryParams() && this.getQueryParams().url !== undefined) {
            query_params = `?url=${this.getQueryParams().url}`;
            is_url_params = true;
        }

        return (
            <>
                <header ref={this.wrapperRef}>
                    <div
                        className="navbar-container"
                        data-open={this.state.isOpen}
                        data-logged-in={!Utilities.isENU(user)}
                    >
                        <div className="container">
                            <Navbar light expand="lg">
                                <Link className="navbar-brand" to="/">
                                    <img
                                        alt="Logo"
                                        onClick={() => this.hide()}
                                        className="logo"
                                        src={
                                            window.region === "GB"
                                            ? Logo
                                            : LogoPN
                                        }
                                    />
                                </Link>
                                <NavbarToggler data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggle}>
                                    <span>
                                        <IoMenuOutline />
                                    </span>
                                </NavbarToggler>
                                {user && (
                                    <div className="favorites mobile">
                                        <Link
                                            className="nav-link"
                                            to={link}
                                            data-active={
                                                currentUrl.indexOf(link) > -1
                                            }
                                        >
                                            <FavoriteIcon />
                                            <span className="label wish-list-count">
                                                {this.state.wishListCount}
                                            </span>
                                        </Link>
                                    </div>
                                )}
                                <Collapse isOpen={this.state.isOpen} navbar id="navbarSupportedContent">
                                    <Nav className="ml-auto" navbar>
                                        <NavItem>
                                            <Link
                                                onClick={() => this.hide()}
                                                className="nav-link"
                                                to="/"
                                                data-active={ currentUrl === `${window.location.origin}/` ? true : false }
                                            >
                                                Home
                                            </Link>
                                        </NavItem>
                                        <NavItem className="carrerHub_btnaa">
                                            <Link
                                                onClick={() => this.hide()}
                                                className="nav-link"
                                                to="/advice-portal"
                                                data-active={
                                                    currentUrl.indexOf(
                                                        "/advice-portal"
                                                    ) > -1
                                                }
                                            >
                                                Stir It Up Advice
                                            </Link>
                                        </NavItem>
                                        {/* <NavItem>
                                            <Link onClick={() => this.hide()} className="nav-link" to="/insight" data-active={(currentUrl.indexOf("/insight") > -1)}>Insight</Link>
                                        </NavItem> */}
                                            {/* 
                                        <NavItem>
                                            <a onClick={() => this.hide()} className="nav-link" href={`https://fair.potnoodleinternships.com/directory${user ? `?gb=${CryptoJS.AES.encrypt(JSON.stringify(user?._id), 'gb_uuid').toString()}` : ''}`} data-active={(currentUrl.indexOf("/insight") > -1)}>Careers Fair</a>
                                        </NavItem> */}
                                        {user?.type === 3 ?
                                            ''
                                        :
                                            <NavItem className="internship_cta">
                                                <div
                                                    onMouseEnter={() => document.querySelector('.intership_dropdown').setAttribute("style","visibility: visible")}
                                                    onMouseLeave={() => document.querySelector('.intership_dropdown').setAttribute("style","visibility: hidden")}
                                                    className="nav-link"
                                                    data-active={["music","gaming","tech","film","publishing","hospitality","internships"]?.includes(currentUrl.split('/')?.pop()?.toLowerCase() || '') }>
                                                    <Link to="#">
                                                        <span>Internships</span>
                                                        <div className="intership_dropdown">
                                                        <ul className="dropdown_lists">
                                                            <li>
                                                                <Link onClick={() => this.hide_dropdroun()} to="/music" className="music">Music</Link>
                                                            </li>
                                                            <li>
                                                                <Link onClick={() => this.hide_dropdroun()} to="/gaming" className="gaming">Gaming</Link>
                                                            </li>
                                                            <li>
                                                                <Link onClick={() => this.hide_dropdroun()} to="/tech" className="tech">Tech</Link>
                                                            </li>
                                                            <li>
                                                                <Link onClick={() => this.hide_dropdroun()} to="/film" className="film">Film</Link>
                                                            </li>
                                                            <li>
                                                                <Link onClick={() => this.hide_dropdroun()} to="/publishing" className="publishing">Publishing</Link>
                                                            </li>
                                                            <li>
                                                                <Link onClick={() => this.hide_dropdroun()} to="/hospitality" className="hospitality">Hospitality</Link>
                                                            </li>
                                                            <li>
                                                                <Link onClick={() => this.hide_dropdroun()} to="/all/internships" className="all">All</Link>
                                                            </li>
                                                        </ul>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </NavItem>
                                        }
                                        <NavItem>
                                            <Link
                                                onClick={() => this.hide()}
                                                className="nav-link"
                                                to="/contact-us"
                                                data-active={
                                                    currentUrl.indexOf(
                                                        "/contact-us"
                                                    ) > -1
                                                }
                                            >
                                                Contact
                                            </Link>
                                        </NavItem>
                                        {user?.type === 3 ?
                                            ''
                                        :
                                            <NavItem className="btn-solid pn-profile-btn search_btn_wrap">
                                                <a
                                                    onClick={() =>
                                                        this.sidebarOpen()
                                                    }
                                                    className="nav-link"
                                                    href="javaScript:void(0)"
                                                    data-active={
                                                        currentUrl.indexOf(
                                                            "/internships"
                                                        ) > -1
                                                    }
                                                >
                                                    Search Internships{" "}
                                                    <BiSearchAlt2 />
                                                </a>
                                            </NavItem>
                                        
                                        
                                        }
                                        {!gradSearchDisabled &&
                                            window.region === "GB" && (
                                                <NavItem className="nav-link-left">
                                                    <Link
                                                        onClick={() =>
                                                            this.hide()
                                                        }
                                                        className="nav-link"
                                                        to={gradsListLink}
                                                        data-active={
                                                            currentUrl.indexOf(
                                                                "/graduates"
                                                            ) > -1
                                                        }
                                                    >
                                                        Search Grads
                                                    </Link>
                                                </NavItem>
                                            )}
                                        {window.region === "GB" && (
                                            <NavItem className="nav-link-left">
                                                <Link
                                                    onClick={() => this.hide()}
                                                    className="nav-link"
                                                    to="/kickstart"
                                                    data-active={
                                                        currentUrl.indexOf(
                                                            "/kickstart"
                                                        ) > -1 &&
                                                        currentUrl.indexOf(
                                                            "jobs/kickstart"
                                                        ) === -1
                                                    }
                                                >
                                                    Kickstart Scheme
                                                </Link>
                                            </NavItem>
                                        )}
                                        {adminToken && (
                                            <NavItem>
                                                <button
                                                    onClick={() => {
                                                        this.hide();
                                                        this.logBack();
                                                    }}
                                                    className="nav-link"
                                                >
                                                    Back to admin
                                                </button>
                                            </NavItem>
                                        )}
                                        {adminBussinessToken && (
                                            <NavItem>
                                                <button
                                                    onClick={() => {
                                                        this.hide();
                                                        this.logBackBussiness();
                                                    }}
                                                    className="nav-link"
                                                >
                                                    Back to own account
                                                </button>
                                            </NavItem>
                                        )}
                                        {user &&
                                            (user.type === 2 ||
                                                user.type === 1) && (
                                                <NavItem>
                                                    <Link
                                                        onClick={() =>
                                                            this.hide()
                                                        }
                                                        className="nav-link"
                                                        to="/admin"
                                                        data-active={
                                                            currentUrl.indexOf(
                                                                "/admin"
                                                            ) > -1
                                                        }
                                                    >
                                                        Admin
                                                    </Link>
                                                </NavItem>
                                            )}
                                        {/* {user && user.type === 3 && (
                                          <NavItem>
                                            <Link
                                              onClick={() => this.hide()}
                                              className="nav-link"
                                              to="/my-profile/business"
                                              data-active={currentUrl.indexOf("/my-profile") > -1}
                                            >
                                              Dashboard
                                            </Link>
                                          </NavItem>
                                        )}

                                        {user && user.type === 4 && (
                                          <NavItem>
                                            <Link
                                              onClick={() => this.hide()}
                                              className="nav-link"
                                              to="/dashboard"
                                              data-active={currentUrl.indexOf("/dashboard") > -1}
                                            >
                                              Dashboard
                                            </Link>
                                          </NavItem>
                                        )} */}
                                        {user && user.type === 5 && (
                                            <NavItem>
                                                <Link
                                                    onClick={() => this.hide()}
                                                    className="nav-link"
                                                    to="/admin-marketing"
                                                    data-active={
                                                        currentUrl.indexOf(
                                                            "/admin"
                                                        ) > -1
                                                    }
                                                >
                                                    Admin
                                                </Link>
                                            </NavItem>
                                        )}
                                        <div className="user-log-wrapper">
                                            {user !== undefined &&
                                                user.canMessage && (
                                                    <NavItem>
                                                        <Link
                                                            onClick={() => {
                                                                this.hide();
                                                            }}
                                                            className="nav-link messages"
                                                            to={"/messages"}
                                                            data-active={
                                                                currentUrl.indexOf(
                                                                    "/messages"
                                                                ) > -1
                                                            }
                                                        >
                                                            <MessagesIcon />
                                                            <span className="message-count">
                                                                {
                                                                    user.unreadMessageCount
                                                                }
                                                            </span>
                                                        </Link>
                                                    </NavItem>
                                                )}

                                            {user && (
                                                <>
                                                    <NavItem className="favorites">
                                                        <Link
                                                            className="nav-link"
                                                            to={link}
                                                            data-active={
                                                                currentUrl.indexOf(
                                                                    link
                                                                ) > -1
                                                            }
                                                        >
                                                            <FavoriteIcon />
                                                            <span className="label wish-list-count">
                                                                {
                                                                    this.state
                                                                        .wishListCount
                                                                }
                                                            </span>
                                                        </Link>
                                                    </NavItem>
                                                </>
                                            )}

                                            {user && (
                                                <div className="profile-dropdown-container">
                                                    <Link
                                                        onClick={() =>
                                                            this.hide()
                                                        }
                                                        className="nav-link"
                                                        to={dashboardUrl}
                                                    >
                                                        <img
                                                            alt="Profile Picture"
                                                            id="profile-pic"
                                                            className=""
                                                            src={
                                                                profilePicture ||
                                                                noProfilePhotoImage
                                                            }
                                                        />
                                                    </Link>
                                                    <Dropdown
                                                        title={""}
                                                        id=""
                                                        onClickCb={() => {
                                                            this.hide();
                                                        }}
                                                        // icon="alt"
                                                        type="internal-link"
                                                        onChange={null}
                                                        light="true"
                                                        theme="3"
                                                        items={
                                                            loggedInDropDownItems
                                                        }
                                                        onClick={function(
                                                            data
                                                        ) {
                                                            this.handleCategoryChange(
                                                                data
                                                            );
                                                        }.bind(this)}
                                                    ></Dropdown>
                                                </div>
                                            )}
                                        </div>
                                        {!user && (
                                            <NavItem className="auth-buttons">
                                                <a
                                                    href={`/login${query_params}`}
                                                    onClick={() => this.hide()}
                                                    className="nav-link"
                                                    data-active={
                                                        currentUrl.indexOf(
                                                            "/login"
                                                        ) > -1
                                                    }
                                                >
                                                    Sign In
                                                </a>
                                            </NavItem>
                                        )}
                                    </Nav>
                                </Collapse>
                                
                            </Navbar>
                        </div>
                    </div>
                </header>
                <SidebarSearch {...this.props} />
            </>
        );
    }
}

export default NavbarMain;
