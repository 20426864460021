/* eslint-disable */
import CloseIcon from '-!svg-react-loader!../icons/close-circle.svg';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import './Input.css';
import './Modal.css';
import './VideoPlayerModal.css';
import ReactPlayer from 'react-player';


class VideoPlayerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.url !== null
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ open: nextProps.url });
    }

    close = () => {
        this.props.close();
    }

    render() {

        return (
            <Modal isOpen={this.state.open} toggle={this.close} className="video-player-modal">
                <CloseIcon onClick={() => {this.close()}} />
                <div className="body">
                    {this.props.type !== undefined && this.props.type === 'youtube_url' ? 
                        <div className="current">
                            <ReactPlayer width="700px" playing={true} controls={true} url={this.props.url} />
                        </div> : 
                        <video controls preload="meta" autoplay autoPlay={this.props.autoplay || false}>
                            <source src={this.props.url} type="video/mp4" />
                        </video>
                    }
                </div>
            </Modal>
        );

    }

}

export default VideoPlayerModal;
