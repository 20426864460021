/* eslint-disable */
import React, { Component } from 'react';
// import './SidebarSearch.css'
import './SidebarSearchNew.css'
import { FiX, FiChevronDown, FiSearch } from "react-icons/fi";
import { Collapse, CardBody, Card } from 'reactstrap';
import _ from 'lodash'
import axios from 'axios';
import TechnologiesImg from '../images/new-design/category/sports.png';
import { Link } from 'react-router-dom';


const getAllTags = (type = 'Job') => {
    return new Promise((resolve, reject) => {
        let tags = JSON.parse(localStorage.getItem("tags"))?.tags;

        let candidateTags = JSON.parse(localStorage.getItem("tags"))?.candidateTags;
        let vacancyTags = JSON.parse(localStorage.getItem("tags"))?.vacancyTags;
        let featuredCandidateTags = JSON.parse(localStorage.getItem("tags"))?.featured.candidate;
        let featuredVacancyTags = JSON.parse(localStorage.getItem("tags"))?.featured.vacancy;

        let perks = tags.find(tag => tag.cat == "J Perks");
        if (!perks) {
            tags.push(
                { id: '1000979', text: 'Generous Holiday Allowance', cat: 'J Perks' },
                { id: '1000980', text: 'Birthday Benefits', cat: 'J Perks' },
                { id: '1000980', text: 'Pet Friendly Office', cat: 'J Perks' },
                { id: '1000980', text: 'Office Games', cat: 'J Perks' },
                { id: '1000980', text: 'Gym Membership', cat: 'J Perks' },
                { id: '1000980', text: 'Company Tech Provided', cat: 'J Perks' },
                { id: '1000980', text: 'Employee Discounts', cat: 'J Perks' },
                { id: '1000980', text: 'Team Bonding Away Days', cat: 'J Perks' },
                { id: '1000980', text: 'Health Insurance', cat: 'J Perks' },
                { id: '1000980', text: 'Shower Facilities', cat: 'J Perks' },
                { id: '1000980', text: 'Dental Insurance', cat: 'J Perks' },
                { id: '1000980', text: 'Flexible Working', cat: 'J Perks' },
                { id: '1000980', text: 'Travel With Work', cat: 'J Perks' },
                { id: '1000980', text: 'International Offices', cat: 'J Perks' },
                { id: '1000980', text: 'Office Socials', cat: 'J Perks' },
                { id: '1000980', text: 'High Earning Potential', cat: 'J Perks' },
                { id: '1000980', text: 'Socially Responsible Company', cat: 'J Perks' },
                { id: '1000980', text: 'Career Development Policy', cat: 'J Perks' },
                { id: '1000980', text: 'Personal Wellbeing', cat: 'J Perks' },
                { id: '1000980', text: 'Music In The Office', cat: 'J Perks' },
            )

            let dateNow = Date.now();
            let tagsStorage = {
                timestamp: dateNow,
                tags: tags,
                candidateTags: candidateTags,
                vacancyTags: vacancyTags,
                featured: {
                    candidate: featuredCandidateTags,
                    vacancy: featuredVacancyTags,
                }
            };
            localStorage.setItem("tags", JSON.stringify(tagsStorage));
        }

        let jCatArr = [/*"J Job Type"*/, "J Sector"/* , "J Skills" , "J Location", "J Trait"/* , "J Languages" , "J Perks"*/]
        let gCatArr = ["G Job Type", "G Skills", "G Location", "G Sector", "G Trait", "G Languages", "G Perks"]
        let catArr = type === 'Job' ? jCatArr : gCatArr
        let allTags = _.without(_.chain(tags).groupBy("cat").map((value, key) => {
            if (_.includes(catArr, key)) {
                let temp = catArr.findIndex(ele=>ele==key);
                return { cat: key, tags: value,index:temp }
            }
        }).value(), undefined)

        if (!_.isEmpty(allTags)) {
            resolve(allTags)
        } else {
            reject([])
        }
    })
}

const ucWord = (str) => {
    str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });
    return str
}

class SidebarSearchNew extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            collapse: 'J Job Type',
            tags: [],
            search: '',
            selectedTags: [],
            loaded: false
        };
    }

    toggle(cat) {
        this.setState({ collapse: cat });
    }

    onSearch = () => {
        window.location.href = `/all/internships?keyword=${this.state.search}`
    }

    sidebarClose = () => {
        // document.getElementById("sidebar-search").classList.remove('active');
    }

    sidebarOpen = () => {
        document.getElementById("sidebar-search").classList.add('active');
        this.getLatestTags()
    }

    selectedTagsShow = () => {
        this.state.tags.map((item) => {
            _.uniqBy(item.tags, 'text').map((tag, i) => {
                let selectedTags = JSON.parse(localStorage.getItem('searchTags'))
                this.setState({ selectedTags: selectedTags });
                selectedTags && selectedTags.map((selected) => {
                    if (selected.text === tag.text) {
                        this.addTags(tag)
                    }
                })
            })
        })
    }
  
    componentDidMount() {
        this.getLatestTags()
        document.addEventListener('click', this.openJobAndGradSearchDropdown)
        getAllTags().then(res => {
            let allTags = _.sortBy(res, ['index'], 'asc');    
            localStorage.setItem("categoryTags", JSON.stringify(allTags));
            this.setState({ tags: allTags, allTags }, () => {
                this.selectedTagsShow();
            })
        }).catch(error => {
            console.log('all tags error ===>>>', error)
        })

    }

    openJobAndGradSearchDropdown = (event) => {
        const target = document.querySelector('.sidebar-overlay')
        const searchbtn = document.querySelector('#search-btn')
        const closeBtn = document.querySelector(".close_btn")
        const gaming = document.querySelector(".Gaming")
        const tech = document.querySelector(".Tech")
        const music = document.querySelector(".Music")
        const film = document.querySelector(".Film")
        const publishing = document.querySelector(".Publishing")
        const hospitality = document.querySelector(".Hospitality")
        const all = document.querySelector(".All")

        const withinBoundaries = event.composedPath().includes(target)
        const searchbtnBoundaries = event.composedPath().includes(searchbtn)
        const closeBtnBoundaries = event.composedPath().includes(closeBtn)
        const gamingBoundaries = event.composedPath().includes(gaming)
        const techBoundaries = event.composedPath().includes(tech)
        const musicBoundaries = event.composedPath().includes(music)
        const filmBoundaries = event.composedPath().includes(film)
        const publishingBoundaries = event.composedPath().includes(publishing)
        const hospitalityBoundaries = event.composedPath().includes(hospitality)
        const allBoundaries = event.composedPath().includes(all)

        if (withinBoundaries || (searchbtnBoundaries || closeBtnBoundaries || gamingBoundaries || techBoundaries || musicBoundaries || filmBoundaries || publishingBoundaries || hospitalityBoundaries || allBoundaries)) {
            document.getElementById("sidebar-search").classList.remove('active');
            document.getElementById("root").classList.remove("modal-open");
            document.body.classList.toggle("modal-open");
            var root = document.getElementsByTagName( 'html' )[0]; 
            root.removeAttribute( 'class', 'modal-open' );
        }
    }

    onSearchTextChange = async (e) => {
        let search = e.target.value.toLowerCase()
        this.setState({search})
    }

    getLatestTags = () => {

        let tags = JSON.parse(localStorage.getItem("tags"));

        if ( (tags === null) || (tags === undefined) || (tags.vacancyTags === undefined) || (tags.candidateTags === undefined) || (Date.now() > (tags.timestamp + 900000)) ) {

           axios({
                method: "get",
                url: "api/tags/all",
            }).then(res => {
                let tags = res.data.data.all;
                let featuredTags = res.data.data.featured
                let vacancyTags = [];
                let candidateTags = [];
                let featuredCandidateTags = [];
                let featuredVacancyTags = [];

                for (let i = 0; i < tags.length; i++) {

                    let categoryName = tags[i].categoryName;

                    tags[i] = {
                        id: tags[i].tagId.toString(),
                        text: tags[i].tagName,
                        cat: tags[i].categoryName
                    }

                    if (categoryName[0] === "J") {
                        vacancyTags.push(tags[i]);
                    } else {
                        candidateTags.push(tags[i]);
                    }

                }

                for (let i = 0; i < featuredTags.length; i++) {

                    let categoryName = featuredTags[i].category;

                    let fullCategoryName = null;

                    for (let j = 0; j < tags.length; j++) {
                        //
                        if (tags[j].text.split(" - ")[0] === featuredTags[i].tagName.split(" - ")[0]) {
                            fullCategoryName = tags[j].cat;
                            break;
                        }
                    }

                    featuredTags[i] = {
                        id: featuredTags[i]._id.toString(),
                        text: featuredTags[i].tagName,
                        count: featuredTags[i].count,
                        type: featuredTags[i].category,
                        category: featuredTags[i].tagName,
                    }

                    if (fullCategoryName) {
                        featuredTags[i].catName = fullCategoryName.split(" ")[1];
                    }

                    if (categoryName === "G") {
                        featuredCandidateTags.push(featuredTags[i]);
                    } else {
                        featuredVacancyTags.push(featuredTags[i]);
                    }

                }

                let dateNow = Date.now();

                let tagsStorage = {
                    timestamp: dateNow,
                    tags: tags,
                    candidateTags: candidateTags,
                    vacancyTags: vacancyTags,
                    featured: {
                        candidate: featuredCandidateTags,
                        vacancy: featuredVacancyTags,
                    }
                };

                localStorage.setItem("tags", JSON.stringify(tagsStorage));
                setTimeout(()=>{
                    getAllTags().then(res => {
                        let allTags = _.sortBy(res, ['index'], 'asc');    
                        this.setState({ tags: allTags, allTags, loaded: true }, () => {
                            this.selectedTagsShow();
                        })
                    }).catch(error => {
                        console.log('all tags error ===>>>', error)
                    })
                })
               

            }).catch(error => {
                console.log('tag error ==>>>', error)
            })

        }

    }

    render() {
        return (
            <div id='sidebar-search' className="search-job search-sidebar job_sector_sidebar">
                <div className="sidebar-overlay"></div>
                {/* <div className="sidebar_head"> */}
                    {/* <h2 className="search-heading">Search Internships</h2> */}
                <div className='close_btn'>
                    <FiX onClick={this.sidebarClose} style={{ cursor: 'pointer' }} size={30} />
                </div>
                {/* </div> */}
                <div className="accordian_wrap" id="accordian">
                    <div className="form-content" data-is-open={this.state.collapse} data-dropdown-alt={this.props.altDropdown}>
                        {this.state.tags.map((item, index) => {
                            return <div className="accordion_inner_wrap" key={index}>
                                    <div className="side_content">
                                        <Card>
                                            <CardBody>
                                                <div className="searhc_wrap">
                                                    {/* <div className="input_wrap form-group">
                                                        <input type="text" placeholder="Search Internships" name={`search`} value={this.state.search} className="form-control" onChange={this.onSearchTextChange} />
                                                        <span className="search_icon" id='search-btn' onClick={this.onSearch}><FiSearch size={20} /></span>
                                                    </div> */}
                                                    {/* <button className="search-btn" id='search-btn' onClick={this.onSearch}>Search</button> */}
                                                </div>
                                                <div className="tab_content">
                                                    <div className="tag_wrap_all">
                                                        <Link onClick={this.sidebarClose} className={`tag_card`} to={'/music'}>
                                                            <figure className="Music">
                                                                <span>Music</span>
                                                            </figure>
                                                        </Link>
                                                        <Link onClick={this.sidebarClose} className={`tag_card`} to={'/gaming'}>
                                                            <figure className="Gaming">
                                                                <span>Gaming</span>
                                                            </figure>
                                                        </Link>
                                                        <Link onClick={this.sidebarClose} className={`tag_card`} to={'/tech'}>
                                                            <figure className="Tech">
                                                                <span>Tech</span>
                                                            </figure>
                                                        </Link>
                                                        <Link onClick={this.sidebarClose} className={`tag_card`} to={'/film'}>
                                                            <figure className="Film">
                                                                <span>Film</span>
                                                            </figure>
                                                        </Link>
                                                        <Link onClick={this.sidebarClose} className={`tag_card`} to={'/publishing'}>
                                                            <figure className="Publishing">
                                                                <span>Publishing</span>
                                                            </figure>
                                                        </Link>
                                                        <Link onClick={this.sidebarClose} className={`tag_card`} to={'/hospitality'}>
                                                            <figure className="Hospitality">
                                                                <span>Hospitality</span>
                                                            </figure>
                                                        </Link>
                                                        <Link onClick={this.sidebarClose} className={`tag_card`} to={'/all/internships'}>
                                                            <figure className="All">
                                                                <span>All</span>
                                                            </figure>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                            </div>
                        })}
                    </div>
                    {/* <div className="search_bar_btns">
                        <button type="submit" className="search-btn reset_btn" onClick={() => this.resetTag()}>Reset</button>
                        <button className="search-btn" id='search-btn' onClick={this.onSearch}>Search</button>
                    </div> */}
                </div>
            </div>
        );

    }

}

export default SidebarSearchNew;
