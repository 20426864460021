import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./VideoCard.css";
import PlayBtn from "../../images/2023/play.svg";
import PauseBtn from "../../images/2023/pause.svg";
import { AiFillPlayCircle } from "react-icons/ai";
import { BsPauseFill, BsPlayFill } from "react-icons/bs";


const VideoCard = (props) => {
    const [videoData, setVideoData] = useState({});

    const videoRef = useRef();
    const [isPlay, setIsPlay] = useState(false);
    const [showPause, setShowPause] = useState('none');

    const getVideo = (passionPoint) => {
        if (passionPoint === "Film") {
            return {
                url:
                    "https://gradbay.s3.eu-west-2.amazonaws.com/pn-video/PNI_ASSET2_FILM_FINAL.mp4",
                title: "There's No Business Like Show Business",
                desc: "<p>It's a popular saying because it's totally true. The film industry is a melting pot of creative thinkers, producers, writers, directors, visionaries, and then some.</p>" +
                    "<p>From multilingual recording studios to creative production agencies, to camera lens developers and more, these fully paid internships are the perfect fit for any hardcore film fanatic.</p>" +
                    "<p>Think you've got what it takes? Then go get 'em.</p>" +
                    "<p>And may the force of Pot Noodle be with you.</p>",
            };
        }
        if (passionPoint === "Gaming") {
            return {
                url:
                    "https://gradbay.s3.eu-west-2.amazonaws.com/pn-video/PNI_ASSET2_GAMING_FINAL.mp4",
                title: "Get Your Head In The Game",
                desc: "<p>The gaming industry isn't all about being paid to sit on your sofa playing Xbox (although that would be pretty nice, right?)</p>" +
                    "<p>It's about being behind-the-scenes, creating and testing some of the most innovative and advanced games on the market. Which is way better!</p>" +
                    "<p>If you’re a puzzle prodigy, a social samurai, or a VR fanatic, then gaming could be your playground.</p>" +
                    "<p>From app development, gamified education, and e-sports, get stuck into finding a fully paid internship that will bring you 100% gamer glory!</p>",
            };
        }
        if (passionPoint === "Music") {
            return {
                url:
                    "https://gradbay.s3.eu-west-2.amazonaws.com/pn-video/PNI_ASSET2_MUSIC_FINAL.mp4",
                title: "If Music Makes You Lose Control, Let's Go",
                desc: "<p>Working in the music industry involves a whole lot more than performing centre-stage; there are so many opportunities available for music moguls when you pull back the curtain.</p>" +
                    "<p>What could be cooler than working backstage at your favourite artist's gig, or helping to set up festivals that you've always loved?</p>" +
                    "<p>Take the first step with a fully paid internship in music. The possibilities are endless.</p>" +
                    "<p>We've secured paid internship opportunities at some of the UK’s biggest festivals and venues, music management agencies, and sound and mix specialists.</p>" +
                    "<p>If music is your passion, then what are you waiting for?</p>",
            };
        }
        if (passionPoint === "Publishing") {
            return {
                url:
                    "https://gradbay.s3.eu-west-2.amazonaws.com/pn-video/PNI_ASSET2_PUBLISHING_FINAL.mp4",
                title: "Writing Your Next Chapter",
                desc: "<p>If you're the type of person who's always got their nose in a book, there couldn't be an industry more perfect for you.</p>" +
                    "<p>Imagine getting paid to read work by talented and up-and-coming writers? That's what a career in publishing could look like.</p>" +
                    "<p>We have opportunities working in the biggest newspapers and specialist magazines, personal biographies, podcasts and travel media.</p>" +
                    "<p>If you fancy yourself as a wordsmith, then secure one of our paid internships in publishing and make your next chapter a good one.</p>",
            };
        }
        if (passionPoint === "Tech") {
            return {
                url:
                    "https://gradbay.s3.eu-west-2.amazonaws.com/pn-video/PNI_ASSET2_TECH_FINAL.mp4",
                title: "Become The Future... Of The Future",
                desc: "<p>Jobs in the tech world are truly taking off right now. Who knows where they're headed? If you’re curious and always questioning, then this is the industry for you.</p>" +
                    "<p>Real-life problems meet insanely innovative solutions. You could secure a front row seat to watch the world of tech evolve.</p>" +
                    "<p>With fully paid internships in everything from AI to cloud software, virtual workplaces to mobile apps, you can help shape the future and get paid experience while doing it!</p>" +
                    "<p>Unlock your pot-ential and open up worlds beyond our imaginations.</p>",
            };
        }
        if (passionPoint === "Hospitality") {
            return {
                title: "A chance to create a different world...",
                desc: "<p>If you're the type of person who loves to meet new people and go above and beyond to make others feel welcome, there couldn't be an industry more perfect for you than hospitality. Imagine being the one to make people's vacations unforgettable, helping them celebrate special moments and celebrate lasting memories in their lives. That's what a career in hospitality could look like.</p>" +
                    "<p>In the hospitality industry, you have the opportunity to touch people's lives in meaningful ways and create experiences that they will cherish for a lifetime. Whether you're working in a luxury hotel, a cozy bed and breakfast, or a trendy restaurant, you'll be at the heart of the hospitality experience, making a difference in the lives of guests every day.</p>" +
                    "<p>If you have a passion for service and a knack for making people feel at home, then secure one of our paid internships in hospitality and start your journey to a fulfilling career.</p>"
            }
        }
    };

    const handleVideo = () => {
        const media = document.querySelector('video');
        console.log("media.paused", media.paused);
        console.log("media", media)
        if (media.paused) {
            media.play();
            setIsPlay(true)
        } else {
            media.pause()
            setIsPlay(false)
        }
    };

    useEffect(() => {
        setVideoData(getVideo(props.data));
    }, [props]);

    return (
        <div className="video_wrapper">
            {props.bottomLeftImgArticle ? (
                <span
                    className={`top_right_media bottom_left_media ${props.bottomLeftImgArticlePosition}`}
                >
                    <img src={props.bottomLeftImgArticle} alt="" />
                </span>
            ) : null}
            <div className="container">
                {videoData.url &&
                    <div className="video_element" onClick={handleVideo} onMouseOver={() => setShowPause(false)} onMouseOut={() => setShowPause('none')} >

                        <video ref={videoRef} controls={false} key={videoData?.url} id="sector_video">
                            <source src={videoData?.url || ""} type="video/mp4" />
                            <source src={videoData?.url || ""} type="video /ogg" />
                        </video>

                        {!isPlay ?
                            <div class="play-btn">
                                <BsPlayFill />
                            </div>
                            :
                            <div class="play-btn pause-btn" style={{ display: showPause }}>
                                <BsPauseFill />
                            </div>
                        }
                    </div>
                }
                <h3 className="video_title">{videoData?.title || ""}</h3>
                <p className="video_description" dangerouslySetInnerHTML={{ __html: videoData?.desc || "" }} />
                <Link to={"../advice-portal"} className="btn video_btn">
                    See our stir it up Advice
                </Link>
            </div>
        </div>
    );
};

export default VideoCard;
