/* eslint-disable */
import ArrowDoubleIcon from '-!svg-react-loader!../icons/arrow-double.svg';
import ArrowIcon from '-!svg-react-loader!../icons/arrow.svg';
import CloseIcon from '-!svg-react-loader!../icons/close-circle.svg';
import SearchIcon from '-!svg-react-loader!../icons/search.svg';
import StarIcon from '-!svg-react-loader!../icons/star-2.svg';
import moment from "moment";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { isMobile } from 'react-device-detect';
import 'react-input-range/lib/css/index.css';
import Pagination from "react-js-pagination";
import { WithContext as ReactTags } from 'react-tag-input';
import { UncontrolledTooltip } from 'reactstrap';
import uniqid from 'uniqid';
import Person1 from '../images/people/1.png';
import Person2 from '../images/people/2.png';
import Person3 from '../images/people/3.png';
import Person4 from '../images/people/4.png';
import Person5 from '../images/people/5.png';
import Person6 from '../images/people/6.png';
import Person7 from '../images/people/7.png';
import Person8 from '../images/people/8.png';
import Person9 from '../images/people/9.png';
import PotNoodle1 from '../images/potnoodle/hero-1.png';
import PotNoodle2 from '../images/potnoodle/hero-2.png';
import PotNoodle3 from '../images/potnoodle/hero-3.png';
import PotNoodle4 from '../images/potnoodle/hero-4.png';
import PotNoodle5 from '../images/potnoodle/hero-5.png';
import PotNoodle6 from '../images/potnoodle/hero-6.png';
import PotNoodle7 from '../images/potnoodle/hero-7.png';
import PotNoodle8 from '../images/potnoodle/hero-8.png';
import PotNoodle9 from '../images/potnoodle/hero-9.png';
import BackButton from './BackButton.js';
import CandidatePreviewCard from './CandidatePreviewCard.js';
import './DateRangePickerCustom.css';
import Dropdown from './Dropdown.js';
import FAQContainer from './FAQContainer.js';
import './GlobalSearch.css';
import './Pagination.css';
import QuickSearch from './QuickSearch.js';
import RecentlyViewed from './RecentlyViewed';
import './RecentlyViewed.css';
import './SearchList.css';
import './SearchOptions.css';
import './SkillLevelPicker.css';
import './TagEditor.css';
import TagsContainer from './TagsContainer.js';
import './Toggle.css';
import utilities from './Utilities';
import VacancyPreviewCard from './VacancyPreviewCard.js';
import InputRange from 'react-input-range';
import Logo from '../icons/logo.png';

let Utilities = new utilities();

const HERO_REVIEWS = (window.region === "GB") ? [
    {
        name: "Fiyan A.",
        stars: 5,
        role: "Accounting & Finance",
        image: Person1
    },
    {
        name: "Joel F.",
        stars: 5,
        role: "Digital Marketing",
        image: Person2
    },
    {
        name: "CJ C.",
        stars: 5,
        role: "Video Production",
        image: Person3
    },
    {
        name: "Millie G.",
        stars: 5,
        role: "Account Manager",
        image: Person4
    },
    {
        name: "Edi C.",
        stars: 5,
        role: "Business Development",
        image: Person5
    },
    {
        name: "Andrew T.",
        stars: 5,
        role: "Financial Services",
        image: Person6
    },
    {
        name: "Emma H.",
        stars: 5,
        role: "Research Analyst",
        image: Person7
    },
    {
        name: "Sue L.",
        stars: 5,
        role: "Data Scientist",
        image: Person8
    },
    {
        name: "Josh S.",
        stars: 5,
        role: "Ecologist",
        image: Person9
    }
] : [
    { image: PotNoodle1 },
    { image: PotNoodle2 },
    { image: PotNoodle3 },
    { image: PotNoodle4 },
    { image: PotNoodle5 },
    { image: PotNoodle6 },
    { image: PotNoodle7 },
    { image: PotNoodle8 },
    { image: PotNoodle9 },
]

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const LEVELS_LANGUAGES = [
    "Elementary",
    "Limited working",
    "Professional working",
    "Full professional",
    "Native or bilingual",
]

const LEVELS_SKILLS = [
    "Basic",
    "Intermediate",
    "Advanced",
    "Expert",
]

const delimiters = [KeyCodes.comma, KeyCodes.enter];

let CHECK_INTERVAL = null;

class GlobalSearch extends Component {

    state = {

    }

    constructor(props) {

        super(props);

        this.state = {

            activePage: 1,
            totalItems: 1,
            itemsCountPerPage: 12,

            tags: [],
            sort: 'date',
            universityFilter: [],
            cityFilter: [],
            sectorFilter: [],
            salaryFromFilter: 0,
            salaryToFilter: 80000,
            salaryUnit: "Per Year",
            countryFilter: [],
            educationEndDateFilter: [],
            degreeFilter: [],
            noticePeriodFilter: [],
            mustNotHaveFilter: [],
            mustHaveFilter: [],
            subjectFilter: [],
            activityFilter: [],
            remoteWorkFilter: [],
            employmentHoursFilter: [],
            kickstartFilter: [],
            employmentTypeFilter: [],

            candidates: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            vacancies: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],

            errorMsg: "",
            infoMsg: "",

            allTags: [],
            featuredTags: [],

            // When we are at a page on which we don't want to display results
            nextSearchType: (props.type || props.nextSearchType || 'vacancy'),

            nextPageText: "NEXT",
            lastPageText: "LAST",
            prevPageText: "PREV",
            firstPageText: "FIRST",
            pageRangeDisplayed: 5,

            searchBarActive: false,
            suggestions: {
                "Job Type": [],
                "Skills": [],
                "Languages": [],
                "Sector": [],
                "Location": [],
                "Trait": []
            },
            suggestionsKeyword: "",

            ignoreHandleBlur: false,
            showFeaturedTags: true,

            showAdvancedFilters: false,
            focusedInput: false,
            educationEndDateEndDate: moment(),
            educationEndDateStartDate: moment(),
            cvSearchKeywords: "",
            cvSearchKeywordsNot: "",
            cvSearchKeywordsNice: "",
            cvSearchAllResults: [],
            baseLocation: "London",

            skillLevel: {
                editing: false,
                skill: '',
                type: ''
            },

            // heroReview: HERO_REVIEWS[0],

        };

        this.listRef = React.createRef();

    }

    updateBaseLocation = (location) => {
        if (location !== this.state.baseLocation) {
            localStorage.setItem("baseLocation", location)
            if (location === "London") {
                window.location.href = (this.props.type === "candidate" ) ? "/graduates" : "/jobs"
            } else {
                window.location.href = ((this.props.type === "candidate" ) ? "/graduates/" : "/jobs/") + window.globalVars.outsideLocationCodes[location];
            }
        }
    }

    suggestTags = (keyword) => {

        keyword = keyword.toLowerCase().trim();
        let suggestions = {
            "Job Type": [],
            "Skills": [],
            "Languages": [],
            "Sector": [],
            "Location": [],
            "Trait": []
        }

        for (let i = 0; i < this.state.allTags.length; i++) {
            let tag = this.state.allTags[i];
            if (tag.text.toLowerCase().indexOf(keyword) > -1) {
                tag.cat = tag.cat.replace("J ", "").replace("G ", "");
                if (suggestions[tag.cat]) {
                    suggestions[tag.cat].push(this.state.allTags[i]);
                }
            }
        }


        this.setState({
            suggestions: suggestions,
            suggestionsKeyword: keyword
        })

    }

    handleInputFocus = () => {
        this.setState({
            searchBarActive: true
        })
    }

    handleInputBlur = () => {

        setTimeout(function() {

            if ( (!this.state.ignoreHandleBlur) && (!this.state.skillLevel.editing) ) {

                this.setState({
                    searchBarActive: false,
                    suggestionsKeyword: ""
                })

            } else {

            }

        }.bind(this), 200);

    }

    addTagToEditor = (id, name, runSearch) => {

        this.setState({
            ignoreHandleBlur: true,
        }, function() {

            this.handleTagAddition({
                id: id,
                text: name,
            })

            setTimeout(function() {
                this.setState({
                    ignoreHandleBlur: false
                })
            }.bind(this), 200);

            if (runSearch) {
                this.runSearch();
            } else {
                let node = document.getElementsByClassName('ReactTags__tagInputField');
                if (node[0] !== null) {
                    node[0].focus();
                }
    
            }

        })

    }

    static contextTypes = {
        router: PropTypes.object.isRequired
    }

    handleTagDelete = (i) => {

        let tags = this.state.tags;

        if ( (!isNaN(i)) && (i < 1000) ) {
            this.setState({ activePage: 1, tags: tags.filter((tag, index) => index !== i) }, function() {
                if (this.state.tags.length === 0) {
                    localStorage.removeItem("currentSearch");
                    let el = document.querySelectorAll(".ReactTags__tagInputField");
                    if (el && el[0]) {
                        el[0].blur();
                    }
                    this.runSearch();
                }
            });
        } else {
            this.setState({ activePage: 1, tags: tags.filter((tag, index) => i !== tag.id) }, function() {
                if (this.state.tags.length === 0) {
                    localStorage.removeItem("currentSearch");
                    let el = document.querySelectorAll(".ReactTags__tagInputField");
                    if (el && el[0]) {
                        el[0].blur();
                    }
                    this.runSearch();
                }
            });
        }

    }

    handleTagUniversityAddition = (tag) => {
        if (tag.text.endsWith(", University of")) {
            let newTagName = "University of " + tag.text.replace(", University of", "");
            tag.text = newTagName;
            tag.id = newTagName;
        }
        for (let key in this.state.universityFilter) { if (this.state.universityFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, universityFilter: [...this.state.universityFilter, tag] });
    }

    handleTagUniversityDeletion = (i) => {
        this.setState({ activePage: 1, universityFilter: this.state.universityFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagSectorAddition = (tag) => {
        for (let key in this.state.sectorFilter) { if (this.state.sectorFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, sectorFilter: [...this.state.sectorFilter, tag] });
    }

    handleTagSectorDeletion = (i) => {
        this.setState({ activePage: 1, sectorFilter: this.state.sectorFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagCityAddition = (tag) => {
        for (let key in this.state.cityFilter) { if (this.state.cityFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, cityFilter: [...this.state.cityFilter, tag] });
    }

    handleTagCityDeletion = (i) => {
        this.setState({ activePage: 1, cityFilter: this.state.cityFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagCountryAddition = (tag) => {
        for (let key in this.state.countryFilter) { if (this.state.countryFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, countryFilter: [...this.state.countryFilter, tag] });
    }

    handleTagCountryDeletetion = (i) => {
        this.setState({ activePage: 1, countryFilter: this.state.countryFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagNoticePeriodAddition = (tag) => {
        for (let key in this.state.noticePeriodFilter) { if (this.state.noticePeriodFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, noticePeriodFilter: [...this.state.noticePeriodFilter, tag] });
    }

    handleTagNoticePeriodDeletetion = (i) => {
        this.setState({ activePage: 1, noticePeriodFilter: this.state.noticePeriodFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagMustHaveAddition = (tag) => {
        for (let key in this.state.mustHaveFilter) { if (this.state.mustHaveFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, mustHaveFilter: [...this.state.mustHaveFilter, tag] });
    }

    handleTagMustNotHaveDeletetion = (i) => {
        this.setState({ activePage: 1, mustNotHaveFilter: this.state.mustNotHaveFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagMustNotHaveAddition = (tag) => {
        for (let key in this.state.mustNotHaveFilter) { if (this.state.mustNotHaveFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, mustNotHaveFilter: [...this.state.mustNotHaveFilter, tag] });
    }

    handleTagMustHaveDeletetion = (i) => {
        this.setState({ activePage: 1, mustHaveFilter: this.state.mustHaveFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagDegreeAddition = (tag) => {
        for (let key in this.state.degreeFilter) { if (this.state.degreeFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, degreeFilter: [...this.state.degreeFilter, tag] });
    }

    handleTagDegreeDeletion = (i) => {
        this.setState({ activePage: 1, degreeFilter: this.state.degreeFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagSubjectAddition = (tag) => {
        for (let key in this.state.subjectFilter) { if (this.state.subjectFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, subjectFilter: [...this.state.subjectFilter, tag] });
    }

    handleTagSubjectDeletetion = (i) => {
        this.setState({ activePage: 1, subjectFilter: this.state.subjectFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagActivityAddition = (tag) => {
        for (let key in this.state.activityFilter) { if (this.state.activityFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, activityFilter: [...this.state.activityFilter, tag] });
    }

    handleTagActivityDeletetion = (i) => {
        this.setState({ activePage: 1, activityFilter: this.state.activityFilter.filter((tag, index) => index !== i) }, function() {});
    }


    handleTagRemoteWorkAddition = (tag) => {
        for (let key in this.state.remoteWorkFilter) { if (this.state.remoteWorkFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, remoteWorkFilter: [...this.state.remoteWorkFilter, tag] });
    }

    handleTagRemoteWorkDeletetion = (i) => {
        this.setState({ activePage: 1, remoteWorkFilter: this.state.remoteWorkFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagEmploymentTypeAddition = (tag) => {
        for (let key in this.state.employmentTypeFilter) { if (this.state.employmentTypeFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, employmentTypeFilter: [...this.state.employmentTypeFilter, tag] });
    }

    handleTagEmploymentTypeDeletetion = (i) => {
        this.setState({ activePage: 1, employmentTypeFilter: this.state.employmentTypeFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagEmploymentHoursAddition = (tag) => {
        for (let key in this.state.employmentHoursFilter) { if (this.state.employmentHoursFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, employmentHoursFilter: [...this.state.employmentHoursFilter, tag] });
    }

    handleTagEmploymentHoursDeletetion = (i) => {
        this.setState({ activePage: 1, employmentHoursFilter: this.state.employmentHoursFilter.filter((tag, index) => index !== i) }, function() {});
    }

    handleTagKickstartAddition = (tag) => {
        for (let key in this.state.kickstartFilter) { if (this.state.kickstartFilter[key].id === tag.id) { return; } }
        this.setState({ activePage: 1, kickstartFilter: [...this.state.kickstartFilter, tag] });
    }

    handleTagKickstartDeletion = (i) => {
        this.setState({ activePage: 1, kickstartFilter: this.state.kickstartFilter.filter((tag, index) => index !== i) }, function() {});
    }

    addLevelsTag = (level) => {

        // ;

        this.handleTagAddition({
            text: this.state.skillLevel.skill.text + " - " + level,
            id: this.state.skillLevel.skill.id + " - " + level
        }, true)

    }

    handleTagAddition = (tag, levelTag) => {

        if (levelTag) {
            this.setState({
                tags: [...this.state.tags, tag],
                activePage: 1,
            })
            return;
        }

        // Disable freetext
        if (tag.id === tag.text) {
            return;
        }

        this.setState({
            ignoreHandleBlur: true,
            activePage: 1,
        }, function() {

            for (let key in this.state.tags) {
                if (this.state.tags[key].id.split(" - ")[0] === tag.id.split(" - ")[0]) {
                    this.setState({
                        ignoreHandleBlur: false
                    })
                    return;
                }
            }

            let tags = JSON.parse(localStorage.getItem("tags")).tags;

            for (let i = 0; i < tags.length; i++) {
                if (tags[i].text === tag.text.split(" - ")[0]) {
                    if ( (tags[i].cat.indexOf("Languages") > -1) || (tags[i].cat.indexOf("Skills") > -1) ) {
                        if (tag.text !== "Pot Noodle") {
                            this.setState({
                                skillLevel: {
                                    editing: true,
                                    skill: tags[i],
                                    type: tags[i].cat
                                },
                            })
                            return;
                        }
                    }
                }
            }

            this.setState({
                tags: [...this.state.tags, tag],
                activePage: 1,
            }, function() {
                setTimeout(function() {
                    this.setState({
                        ignoreHandleBlur: false
                    })
                }.bind(this), 100)
            });

        })

    }

    updateSearchParams = (type, value) => {

        // Page change
        if (value === undefined) {
            value = type
            type = "activePage"
        }

        let state = {};
        state[type] = value;

        this.setState(state, () => {
            if ( (this.props.cvSearch) && (type === "activePage") ) {
                this.setState({
                    candidates: this.state.cvSearchAllResults.slice((value - 1)*12, (value - 1)*12 + 12),
                    activePage: value
                })
                let previousSearch = JSON.parse(localStorage.getItem("previousSearch"));
                previousSearch.page = value,
                localStorage.setItem("previousSearch", JSON.stringify(previousSearch))
                window.scrollTo({
                    top: this.listRef.current.offsetTop,
                    left: 0,
                    behavior: "smooth"
                });
            } else {
                this.runSearch(true);
            }
        })

    }

    runSearch = (scrollToList, ignoreOnInitPanel, initiator) => {
       
        if ((window.location.href.indexOf("/view-profile/") !== -1 || window.location.href.indexOf("/jobs/post/") !== -1 || window.location.href.indexOf("/saved/") !== -1 ) && initiator !== "button") {
            return;
        }

        // Prevent Search on home, about... pages before Search is pressed
        if (ignoreOnInitPanel) {
            return;
        }

        this.handleInputBlur();

        localStorage.setItem("currentSearch", JSON.stringify({
            tags: this.state.tags,
            universityFilter: this.state.universityFilter,
            sort: this.state.sort,
            cityFilter: this.state.cityFilter,
            sectorFilter: this.state.sectorFilter,
            salaryToFilter: this.state.salaryToFilter,
            salaryFromFilter: this.state.salaryFromFilter,
            countryFilter: this.state.countryFilter,
            educationEndDateFilter: this.state.educationEndDateFilter,
            subjectFilter: this.state.subjectFilter,
            remoteWorkFilter: this.state.remoteWorkFilter,
            employmentTypeFilter: this.state.employmentTypeFilter,
            employmentHoursFilter: this.state.employmentHoursFilter,
            kickstartFilter: this.state.kickstartFilter,
            activityFilter: this.state.activityFilter,
            degreeFilter: this.state.degreeFilter,
            noticePeriodFilter: this.state.noticePeriodFilter,
            mustNotHaveFilter: this.state.mustNotHaveFilter,
            mustHaveFilter: this.state.mustHaveFilter,
            educationEndDateStartDate: this.state.educationEndDateStartDate,
            educationEndDateEndDate: this.state.educationEndDateEndDate,
            cvSearchKeywords: this.state.cvSearchKeywords,
            cvSearchKeywordsNot: this.state.cvSearchKeywordsNot,
            cvSearchKeywordsNice: this.state.cvSearchKeywordsNice,
            category: (this.props.city) ? "outside" : (this.props.cvSearch) ? "cv" : "regular",
            outsideCity: (this.props.city) ? this.props.city : null
        }))

        // Need redirecting to listing page, save search to local
        if (this.props.type !== this.state.nextSearchType) {

            let nextLink = "";

            if (this.state.nextSearchType === "vacancy") {
                nextLink = "/jobs";
            } else if (this.state.nextSearchType === "candidate") {
                nextLink = "/graduates";
            }

            if (this.state.baseLocation !== "London") {
                nextLink += "/" + window.globalVars.outsideLocationCodes[this.state.baseLocation];
            }

            if ( (window.location.href.indexOf("/insight") > -1) || (window.location.href.indexOf("/terms") > -1) || (window.location.href.indexOf("/privacy") > -1) || (window.location.href.indexOf("/about") > -1) ) {
                return;
            }

            if (window.location.href.indexOf("/contact-us") !== -1 && window.region === "PN") {
                return;
            }
            
            window.location.href = nextLink;

            return;

        }

        if (scrollToList) {

            if (this.listRef.current !== null) {
                window.scrollTo({
                    top: this.listRef.current.offsetTop,
                    left: 0,
                    behavior: "smooth"
                });
            } else {
                if (this.state.nextSearchType === "vacancy") window.location.href = "/jobs";
                else window.location.href = "/graduates";
            }

        }

        let state = {};
        let stateNow = {...this.state}
        let apiURL;

        let previousSearch = JSON.parse(localStorage.getItem("previousSearch"));

        if (!Utilities.isENU(previousSearch)) {

            if ( (JSON.stringify(previousSearch.tags) !== JSON.stringify(stateNow.tags)) || (this.props.cvSearch) ) {
                stateNow.activePage = 1;
            }

        }

        if (this.props.cvSearch) {
            state.activePage = 1;
        }

        let user = window.localStorage.getItem('user');

        if (this.props.type === "candidate") {
            state.candidates = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
            apiURL = (user) ? "/api/graduate/search-auth" : "/api/graduate/search"
        } else if (this.props.type === "vacancy") {
            state.vacancies = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
            apiURL = "/api/vacancy/search"
        } else if (this.props.type == "outside-vacancy") {
            state.vacancies = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
            apiURL = "/api/outside-vacancy/search"
        }

        state.errorMsg = "";
        this.setState(state);

        let formattedTags = [];

        for (var i = 0; i < stateNow.tags.length; i++) {
            formattedTags.push(stateNow.tags[i].text);
        }

        if (this.props.cvSearch) {

            window.globalVars.utilities.trackEvent("graduate", "CV Search", "|" + this.state.cvSearchKeywords + "|", {
                keywords: this.state.cvSearchKeywords,
                nice: this.state.cvSearchKeywordsNice,
                not: this.state.cvSearchKeywordsNot
            });

            this.setState({
                tags: []
            })

        } else {

            window.globalVars.utilities.trackEvent((this.props.type === "vacancy") ? "job" : "graduate", (this.props.type === "vacancy") ? "Job Search" : "Gradaute Search", "|" + formattedTags.join("|") + "|", {
                tags: formattedTags,
                universityFilter: this.state.universityFilter,
                sort: this.state.sort,
                cityFilter: this.state.cityFilter,
                sectorFilter: this.state.sectorFilter,
                salaryToFilter: this.state.salaryToFilter,
                salaryFromFilter: this.state.salaryFromFilter,
                subjectFilter: this.state.subjectFilter,
                remoteWorkFilter: this.state.remoteWorkFilter,
                employmentTypeFilter: this.state.employmentTypeFilter,
                employmentHoursFilter: this.state.employmentHoursFilter,
                kickstartFilter: this.state.kickstartFilter,
                activityFilter: this.state.activityFilter,
                degreeFilter: this.state.degreeFilter,
                noticePeriodFilter: this.state.noticePeriodFilter,
                mustNotHaveFilter: this.state.mustNotHaveFilter,
                mustHaveFilter: this.state.mustHaveFilter,
                educationEndDateStartDate: this.state.educationEndDateStartDate,
                educationEndDateEndDate: this.state.educationEndDateEndDate,
                category: (this.props.city) ? "external" : "internal",
                externalCity: (this.props.city) ? this.props.city : null
            });

        }


        let cvSearchUrl = (user) ? "/api/graduate/cv-search-auth" : "/api/graduate/cv-search"

        window.globalVars.server.api({
            method: "post",
            url: (this.props.cvSearch) ? cvSearchUrl : apiURL,
            data: {
                cvSearch: (this.props.cvSearch) ? this.state.cvSearchKeywords : null,
                cvSearchNice: (this.props.cvSearch) ? this.state.cvSearchKeywordsNice : null,
                cvSearchNot: (this.props.cvSearch) ? this.state.cvSearchKeywordsNot : null,
                activePage: stateNow.activePage,
                tags: stateNow.tags,
                featuredCandidates: stateNow.featuredCandidates,
                sort: stateNow.sort,
                universityFilter: this.state.universityFilter,
                sort: this.state.sort,
                cityFilter: this.state.cityFilter,
                sectorFilter: this.state.sectorFilter,
                salaryToFilter: this.state.salaryToFilter,
                salaryFromFilter: this.state.salaryFromFilter,
                salaryUnit: this.state.salaryUnit,
                countryFilter: this.state.countryFilter,
                educationEndDateFilter: this.state.educationEndDateFilter,
                subjectFilter: this.state.subjectFilter,
                remoteWorkFilter: this.state.remoteWorkFilter,
                employmentTypeFilter: this.state.employmentTypeFilter,
                employmentHoursFilter: this.state.employmentHoursFilter,
                kickstartFilter: this.state.kickstartFilter,
                activityFilter: this.state.activityFilter,
                degreeFilter: this.state.degreeFilter,
                noticePeriodFilter: this.state.noticePeriodFilter,
                mustNotHaveFilter: this.state.mustNotHaveFilter,
                mustHaveFilter: this.state.mustHaveFilter,
                educationEndDateStartDate: this.state.educationEndDateStartDate,
                educationEndDateEndDate: this.state.educationEndDateEndDate,
                outsideCity: (this.props.city) ? this.props.city : null
            },
            then: function(res) {
                this.afterSearch(res)
            }.bind(this),
            catch: function(e) {

                var state = {...this.state};
                state.candidates = [];
                state.vacancies = [];
                state.errorMsg = "Failed to load results, please try again later";
                state.infoMsg = "";
                this.setState(state);

            }.bind(this)
        })

    }

    updateNextSearchType = (type) => {
        this.setState({nextSearchType: type}, function() {
            this.updateFeaturedTags();
        })
    }

    parseStringQuery = (tags) => {

        let tagsBootstrap = tags.map(item => {
            return {
                id: uniqid(),
                text: item
            }
        })
        this.setState({
            tags: tagsBootstrap
        }, function() {
            this.runSearch(true);
        })
    }

    updateTags = (tags) => {

        this.setState({
            tags: tags
        }, function() {
            this.runSearch(true);
        })

    }

    afterSearch = (response) => {

        let state = {};
        let stateNow = {...this.state};

        if ( (!response.data.success) && (this.props.cvSearch) ) {
            this.setState({
                candidates: [],
                vacancies: [],
                errorMsg: (response.data.error || "Failed to load results, please try again later"),
                infoMsg: "",
            });
            return;
        }

        if (this.props.type === "candidate") {

            state.totalItems = response.data.data.total;
            state.candidates = response.data.data.data;

        } else if (this.props.type === "vacancy") {

            state.totalItems = response.data.data.total;
            state.vacancies = response.data.data.data;

        } else if (this.props.city) {

            if (response.data.data.total !== undefined) {
                state.totalItems = response.data.data.total
            } else {
                state.totalItems = stateNow.totalItems
            }

            state.vacancies = response.data.data.data;

        }

        if (this.props.cvSearch) {
            state.cvSearchAllResults = response.data.data.data;
            state.candidates = response.data.data.data.slice(0, 12);
        }

        localStorage.setItem("previousSearch", JSON.stringify({
            category: (this.props.city) ? "outside" : (this.props.cvSearch) ? "cv" : "regular",
            outsideCity: (this.props.city) ? this.props.city : null,
            page: this.state.activePage,
            total: response.data.data.total,
            type: this.props.type,
            tags: this.state.tags,
            universityFilter: this.state.universityFilter,
            sort: this.state.sort,
            cityFilter: this.state.cityFilter,
            sectorFilter: this.state.sectorFilter,
            salaryToFilter: this.state.salaryToFilter,
            salaryFromFilter: this.state.salaryFromFilter,
            salaryUnit: this.state.salaryUnit,
            countryFilter: this.state.countryFilter,
            educationEndDateFilter: this.state.educationEndDateFilter,
            subjectFilter: this.state.subjectFilter,
            remoteWorkFilter: this.state.remoteWorkFilter,
            employmentTypeFilter: this.state.employmentTypeFilter,
            employmentHoursFilter: this.state.employmentHoursFilter,
            kickstartFilter: this.state.kickstartFilter,
            activityFilter: this.state.activityFilter,
            degreeFilter: this.state.degreeFilter,
            noticePeriodFilter: this.state.noticePeriodFilter,
            mustNotHaveFilter: this.state.mustNotHaveFilter,
            mustHaveFilter: this.state.mustHaveFilter,
            educationEndDateStartDate: this.state.educationEndDateStartDate,
            educationEndDateEndDate: this.state.educationEndDateEndDate,
            results: ( (this.props.type === "vacancy") || (this.props.type === "outside-vacancy") ) ? state.vacancies : state.candidates,
            cvResults: state.cvSearchAllResults,
            cvSearchKeywords: this.state.cvSearchKeywords,
            cvSearchKeywordsNot: this.state.cvSearchKeywordsNot,
            cvSearchKeywordsNice: this.state.cvSearchKeywordsNice,
        }));

        state.errorMsg = "";

        if (this.state.tags.length !== 0) {
            let totalOnThisPage = (state.vacancies === undefined) ? state.candidates.length : state.vacancies.length;
            state.infoMsg = "Total Results: " + state.totalItems + " (showing: " + ( ( (stateNow.activePage-1) * stateNow.itemsCountPerPage) + 1) + "-" + ( ( (stateNow.activePage-1) * stateNow.itemsCountPerPage) + totalOnThisPage ) + ")";
        } else {
            state.infoMsg = "";
        }

        localStorage.setItem("singlePagination", JSON.stringify({
            idList: response.data.data.data.map(item => {
                return item.id;
            }),
            page: this.state.activePage,
            type: this.props.type
        }))

        console.log("S!!!!!!!!!!!!!!!EARCH IN DONE", JSON.parse(localStorage.getItem("singlePagination")))

        this.setState(state, () => {

            let autoLoadResult = localStorage.getItem("autoLoadResult");
            let autoLoadResultType = localStorage.getItem("autoLoadResultType");

            if (autoLoadResult !== undefined) {

                localStorage.removeItem("autoLoadResult");
                localStorage.removeItem("autoLoadResultType")

                let pagination = localStorage.getItem("singlePagination");

                if (window.globalVars.utilities.isENU(pagination)) {
                    return;
                }

                pagination = JSON.parse(pagination);;

                if (pagination.idList[0] !== undefined) {

                    if (autoLoadResultType === "vacancy") {
                        // window.history.pushState('page', '123', '/jobs/post/' + (autoLoadResult === "first" ? pagination.idList[0] : pagination.idList[pagination.idList.length-1]));
                        window.location.href = '/jobs/post/' + (autoLoadResult === "first" ? pagination.idList[0] : pagination.idList[pagination.idList.length-1]);
                    } else if (autoLoadResultType === "candidate") {
                        window.location.href = '/view-profile/' + (autoLoadResult === "first" ? pagination.idList[0] : pagination.idList[pagination.idList.length-1]);
                    }

                }

            }

        })

    }

    updateFeaturedTags = () => {

        let tags = JSON.parse(localStorage.getItem("tags"));

        if ( (tags !== null) && (tags !== undefined) ) {

            let allTags = [];
            let featuredTags = [];

            if ( (this.state.nextSearchType === "vacancy") || (this.state.nextSearchType === "outside-vacancy") ) {

                if ( (!Utilities.isENU(tags.featured)) && (!Utilities.isENU(tags.featured.vacancy)) && (tags.featured.vacancy.length > 0) ) {
                    featuredTags = tags.featured.vacancy
                }

                allTags = tags.vacancyTags;

            } else if (this.state.nextSearchType === "candidate") {

                if ( (!Utilities.isENU(tags.featured)) && (!Utilities.isENU(tags.featured.candidate)) && (tags.featured.candidate.length > 0) ) {
                    featuredTags = tags.featured.candidate
                }

                allTags = tags.candidateTags;

            }

            if (allTags === undefined) {
                return;
            }

            allTags.sort(function (a, b) {
                let nameA = a.text.toUpperCase();
                let nameB = b.text.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            })

            featuredTags = featuredTags.map(item => { item.text = item.text.split(" - ")[0]; return item; })

            featuredTags = featuredTags.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.text === thing.text
                ))
            )

            // allTags.map(item => {
            //     if (item.cat.indexOf("Job Type") !== -1)
            //         
            //     return item;
            // })

            // if ((suggestions[tag.cat].filter(t => (t.text.split(" - ")[0] === this.state.allTags[i].text.split(" - ")[0])).length === 0)) {

            this.setState({
                allTags: allTags,
                featuredTags: featuredTags
            })

        } else {

            setTimeout(function() {
                this.updateFeaturedTags();
            }.bind(this), 300);

        }

    }

    tagsPush = (tags) => {

        if (tags[0] === "potnoodle" || tags[0] === "Film" || tags[0] === "Music" || tags[0] === "Gaming" || tags[0] === "Technology") {
            tags = tags.map(item => {
                return {
                    id: uniqid(),
                    text: item
                }
            })
            setTimeout(function() {
                this.setState({
                    tags: tags
                }, function() {
                    this.runSearch(true);
                })
            }.bind(this), 500);
        } else {
            setTimeout(function() {

                let newTags = []
                tags = tags.map(item => item.replace(/-/g, ' '))
                console.log(tags);
                for (let i = 0; i < this.state.allTags.length; i++) {
                    if (tags.indexOf(this.state.allTags[i].text.toLowerCase()) !== -1) {
                        newTags.push({
                            id: uniqid(),
                            text: this.state.allTags[i].text
                        })
                    }
                }
                tags = newTags

                if (tags.map(item => item.text)[0])
                    document.title = window.globalVars.pageTitleBefore + tags.map(item => item.text)[0] + (this.props.type === "vacancy" ? " - Jobs" : " - Grads") + window.globalVars.pageTitleAfter;

                if (tags.length !== 0) {
                    this.setState({
                        tags: tags
                    }, function() {
                        this.runSearch(true);
                    })
                }
    
            }.bind(this), 500);
        }

    }

    componentWillUnmount() {
        clearInterval(CHECK_INTERVAL);
    }

    clearFilters() {

        this.setState({
            universityFilter: [],
            cityFilter: [],
            sectorFilter: [],
            salaryToFilter: 0,
            salaryFromFilter: 0,
            salaryUnit: "Per Year",
            countryFilter: [],
            educationEndDateFilter: [],
            subjectFilter: [],
            remoteWorkFilter: [],
            employmentTypeFilter: [],
            employmentHoursFilter: [],
            kickstartFilter: [],
            activityFilter: [],
            degreeFilter: [],
            noticePeriodFilter: [],
            mustNotHaveFilter: [],
            mustHaveFilter: [],
            educationEndDateStartDate: moment(),
            educationEndDateEndDate: moment(),
            tags: [],
            showAdvancedFilters: false
        }, function() {
            this.runSearch();
        })

    }

    componentDidMount = () => {

        let user = window.localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            if (user.type === 3)
                this.setState({ showKickstarterFilter: true })
        }

        if (this.props.home === "true") {
            this.setState({ heroReview: HERO_REVIEWS[Math.floor(Math.random() * HERO_REVIEWS.length)] })
        }

        let params = window.globalVars.utilities.parseUrl(window.location.href);
        let pageNumberFromUrl = false;

        if ( (isMobile) && (this.state.pageRangeDisplayed !== 1) ) {

            this.setState({
                nextPageText:  <ArrowIcon />,
                prevPageText: <ArrowIcon />,
                firstPageText: <ArrowDoubleIcon />,
                lastPageText: <ArrowDoubleIcon />,
                pageRangeDisplayed: 1
            })

        }

        let baseLocation = localStorage.getItem("baseLocation");
        if (baseLocation) {
            this.setState({
                baseLocation: baseLocation
            })
        }

        // Reading page number from URL
        if ( (params[1] !== undefined) && (params[1] !== "") && (!isNaN(params[1])) ) {
            this.setState({
                activePage: parseInt(params[1])
            })
            pageNumberFromUrl = true;
            // Removing old search, it's not relevant anymore
            localStorage.removeItem('previousSearch');
        }

        if (params[1] !== undefined) {
            localStorage.removeItem("currentSearch");
            localStorage.removeItem("previousSearch");
            if ((params[1] === "potnoodle")) {
                this.tagsPush(["Pot Noodle"])
            } else if (params[2]) {
                this.tagsPush([params[2]])
            }
        }

        // Show featured dropdown when tag editor's input has focus
        clearInterval(CHECK_INTERVAL);
        CHECK_INTERVAL = setInterval(function() {

            let suggestions = document.getElementsByClassName("ReactTags__suggestions").length;

            if ( (suggestions === 0) && (!this.state.showFeaturedTags) ) {
                this.setState({
                    showFeaturedTags: true
                })
            } else if ( (suggestions === 1) && (this.state.showFeaturedTags) ) {
                this.setState({
                    showFeaturedTags: false
                })
            }

        }.bind(this), 150)

        // Loading tag cache from local strorage
        this.updateFeaturedTags();

        // Restore latest search if navigated away
        let currentSearch =  JSON.parse(localStorage.getItem("currentSearch"));
        let previousSearch = JSON.parse(localStorage.getItem("previousSearch"));

        // Load previous search when navigating back from details page
        if (!Utilities.isENU(previousSearch)) {

            // Categoriy mismatch
            if (previousSearch.category !== currentSearch.category) {
            // Restoring previous state for regular search
            } else if (
                (JSON.stringify(previousSearch.tags) === JSON.stringify(currentSearch.tags)) &&
                (previousSearch.type === this.state.nextSearchType) &&
                ( (previousSearch.outsideCity === baseLocation) || ( (previousSearch.outsideCity === null) && (baseLocation === "London") ) ) &&
                (previousSearch.category === "regular") &&
                (currentSearch.category === "regular") &&
                (!this.props.cvSearch)
            ) {

                let newState = {}

                if (previousSearch.type === "candidate") {
                    newState.candidates = previousSearch.results;
                } else if (previousSearch.type === "vacancy") {
                    newState.vacancies = previousSearch.results;
                }

                if ( (currentSearch !== null) && (currentSearch !== undefined) ) {
                    this.setState({
                        tags: currentSearch.tags,
                        universityFilter: (currentSearch.universityFilter || []),
                        sort: (this.state.sort || 'date'),
                        cityFilter: (currentSearch.cityFilter || []),
                        sectorFilter: (currentSearch.sectorFilter || []),
                        salaryToFilter: (currentSearch.salaryToFilter || 0),
                        salaryFromFilter: (currentSearch.salaryFromFilter || 0),
                        salaryUnit: (this.state.salaryUnit || "Per Year"),
                        countryFilter: (currentSearch.countryFilter || []),
                        educationEndDateFilter: (currentSearch.educationEndDateFilter || []),
                        subjectFilter: (currentSearch.subjectFilter || []),
                        remoteWorkFilter: (this.state.remoteWorkFilter || []),
                        employmentTypeFilter: (this.state.employmentTypeFilter || []),
                        employmentHoursFilter: (this.state.employmentHoursFilter || []),
                        kickstartFilter: (this.state.kickstartFilter || []),
                        activityFilter: (currentSearch.activityFilter || []),
                        degreeFilter: (currentSearch.degreeFilter || []),
                        noticePeriodFilter:(currentSearch.noticePeriodFilter || []),
                        educationEndDateStartDate: (this.state.educationEndDateStartDate || moment()),
                        educationEndDateEndDate: (this.state.educationEndDateEndDate || moment()),
                    })
                }

                if (!pageNumberFromUrl) {
                    newState.activePage = previousSearch.page;
                }

                newState.totalItems = previousSearch.total;
                newState.errorMsg = "";

                this.setState(newState, function() {

                });

                return;

            // Restoring previous state for regular search
            } else if (
                (previousSearch.category === "cv") &&
                (this.props.cvSearch)
            ) {

                let newState = {}

                newState.activePage = previousSearch.page;
                newState.cvSearchAllResults = previousSearch.cvResults;
                newState.candidates = newState.cvSearchAllResults.slice((newState.activePage - 1)*12, (newState.activePage - 1)*12 + 12)
                newState.cvSearchKeywords = previousSearch.cvSearchKeywords;
                newState.cvSearchKeywordsNot = previousSearch.cvSearchKeywordsNot;
                newState.cvSearchKeywordsNice = previousSearch.cvSearchKeywordsNice;
                newState.totalItems = previousSearch.total;

                setTimeout(function() {
                    document.getElementById('full-cv-search-input-input').value = newState.cvSearchKeywords;
                    if (document.getElementById('full-cv-search-not-input-input') !== null)
                        document.getElementById('full-cv-search-not-input-input').value = newState.cvSearchKeywordsNot;
                    if (document.getElementById('full-cv-search-nice-input-input') !== null)
                        document.getElementById('full-cv-search-nice-input-input').value = newState.cvSearchKeywordsNice;
                }.bind(this), 500)

                newState.errorMsg = "";

                this.setState(newState);

                return;

            } else if (
                (previousSearch.category === "outside") &&
                (this.props.city)
            ) {
                // TODO
            } else {
            }

        }

        // if not match for old results then run search
        if ( (currentSearch !== null) && (currentSearch !== undefined) ) {

            this.setState({
                tags: currentSearch.tags,
                universityFilter: (currentSearch.universityFilter || []),
                sort: (this.state.sort || "date"),
                cityFilter: (currentSearch.cityFilter || []),
                sectorFilter: (currentSearch.sectorFilter || []),
                salaryToFilter: (currentSearch.salaryToFilter || 0),
                salaryFromFilter: (currentSearch.salaryFromFilter || 0),
                salaryUnit: (currentSearch.salaryUnit || "Per Year"),
                countryFilter: (currentSearch.countryFilter || []),
                educationEndDateFilter: (currentSearch.educationEndDateFilter || []),
                subjectFilter: (currentSearch.subjectFilter || []),
                remoteWorkFilter: (this.state.remoteWorkFilter || []),
                employmentTypeFilter: (this.state.employmentTypeFilter || []),
                employmentHoursFilter: (this.state.employmentHoursFilter || []),
                kickstartFilter: (this.state.kickstartFilter || []),
                activityFilter: (currentSearch.activityFilter || []),
                degreeFilter: (currentSearch.degreeFilter || []),
                noticePeriodFilter:(currentSearch.noticePeriodFilter || []),
                educationEndDateStartDate: (this.state.educationEndDateStartDate || moment()),
                educationEndDateEndDate: (this.state.educationEndDateEndDate || moment()),
                // TODO restore must have must not have
            }, function() {
                if (this.props.autoSearch) {
                    if (this.props.home !== "true")
                        this.runSearch();
                }
            })

        } else {

            if (this.props.cvSearch) {

                this.setState({
                    candidates: [],
                    vacancies: [],
                    errorMsg: "Search between thousands of CVs",
                    infoMsg: "",
                });

            } else {
                this.setState({}, function() {
                    if (this.props.home !== "true")
                        this.runSearch();
                })  
            }

        }

    }

    render() {

        // Sort, Recent Searches
        let optionsDOM;
        let listDOM;
        let paginationDOM;
        let recentlyViewedDOM;
        let faqDOM;
        let quickSearchesDOM;

        if (this.props.tagsPush !== undefined) {
            this.parseStringQuery(this.props.tagsPush)
        }

        if (this.props.showOptions) {
            optionsDOM = <div className="search-options" ref={this.listRef}>
                <div className="container">
                    <section>
                        <h3>Current Search</h3>
                        <TagsContainer closeable={true}
                            tags={this.state.tags}
                            onClose={(id) => {
                                this.handleTagDelete(id);
                            }}
                        />
                    </section>
                    <section className="sort-wrapper">
                        <Dropdown
                            title="Sort"
                            id=""
                            onChange={this.updateSearchParams}
                            light="true"
                            theme="2"
                            items={
                                [
                                    {
                                        title: 'Newest first',
                                        data: 'date'
                                    },
                                    {
                                        title: 'Top rated first',
                                        data: 'rate'
                                    },
                                    {
                                        title: 'Most viewed first',
                                        data: 'view'
                                    },
                                ]
                            }
                            onClick={function(sortType) {
                                this.setState({
                                    sort: sortType,
                                    activePage: 1
                                }, function() {
                                    this.runSearch();
                                })
                            }.bind(this)}
                        >
                        </Dropdown>
                    </section>
                </div>
            </div>
        }

        if (this.props.showList) {
            listDOM = <div className="search-list">
                <div className="main-container">
                    { (this.state.errorMsg !== "") ? <div className="error-msg">{this.state.errorMsg}</div> : <div></div> }
                    { (this.state.infoMsg !== "") ? <div className="info-msg">{this.state.infoMsg}</div> : <div></div> }
                    <div className="result-list">

                        { (this.props.type === "candidate") ? this.state.candidates.map(item => {
                            return (
                                <CandidatePreviewCard
                                    key={(item.id || uniqid())}
                                    data={item}
                                    tagsPush={(tags) => { this.tagsPush(tags) }}
                                />
                            )
                        }) : null }

                        { ( (this.props.type === "vacancy") || (this.props.type === "outside-vacancy") ) ? this.state.vacancies.map(item => {
                            return (
                                <VacancyPreviewCard
                                    key={item.id}
                                    data={item}
                                    city={this.props.city}
                                    type={this.props.type}
                                    tagsPush={(tags) => { this.tagsPush(tags) }}
                                />
                            )
                        }) : null }


                    </div>
                </div>
            </div>
        }

        if (this.props.showPagination) {
            paginationDOM = <div className="pagination-wrapper" data-section="global-search">
                <div className="page-state">{this.state.activePage} OF { (this.state.totalItems < 2) ? "1" : (Math.round(this.state.totalItems / this.state.itemsCountPerPage) + 1 ) }</div>
                <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItems}
                    pageRangeDisplayed={this.state.pageRangeDisplayed}
                    onChange={this.updateSearchParams}
                    prevPageText={this.state.prevPageText}
                    nextPageText={this.state.nextPageText}
                    firstPageText={this.state.firstPageText}
                    lastPageText={this.state.lastPageText}
                />
            </div>
        }

        if (this.props.showRecentlyViewed) {
            recentlyViewedDOM = <RecentlyViewed
                type={this.props.type}
                tagsPush={(tags) => { this.tagsPush(tags) }}
            />
        }

        if (this.props.showFAQ) {
            faqDOM = <FAQContainer title={ window.region === "PN" ? "FAQ" : "Why Choose GradBay" } type={this.props.type} />
        }

        if (this.props.showQuickSearches) {

            quickSearchesDOM = <QuickSearch onChange={(item) => {this.parseStringQuery(item)}} />

        }

        let featuredTagsDropdownItems = this.state.allTags.filter(item => item.text !== "Pot Noodle");

        if (this.state.featuredTags && this.state.featuredTags.length > 0) {
            featuredTagsDropdownItems = this.state.featuredTags.filter(item => item.text !== "Pot Noodle");;
        }

        let AdvancedFilterToggleDOM = <div className="advanced-filters-toggle clearfix">
            { (this.state.showAdvancedFilters) &&
                <button className="toggle" onClick={ () => { this.setState({ showAdvancedFilters: !this.state.showAdvancedFilters }) } }>Hide Advanced Search</button>
            }
            { (!this.state.showAdvancedFilters && window.region === "GB") &&
                <button className="toggle underline-on-hover" onClick={ () => {
                        if (this.props.home === "true") {
                            if (this.state.nextSearchType === "vacancy") window.location.href = "/jobs";
                            else window.location.href = "/graduates";
                        } else {
                            this.setState({ showAdvancedFilters: !this.state.showAdvancedFilters }, function() {
                                if (document.getElementById('full-cv-search-not-input-input') !== null)
                                    document.getElementById('full-cv-search-not-input-input').value = this.state.cvSearchKeywordsNot;
                                if (document.getElementById('full-cv-search-nice-input-input') !== null)
                                    document.getElementById('full-cv-search-nice-input-input').value = this.state.cvSearchKeywordsNice;
                            })
                        }
                    }
                }>
                    Show Advanced Search
                </button>
            }
        </div>

        if (this.props.home) {
            AdvancedFilterToggleDOM = null;
        }

        return (

            <div data-tags-count={this.state.tags.length}>

                <div className="global-search" data-next-search-type={this.state.nextSearchType} data-home={this.props.home} data-service={this.props.service} data-theme={this.props.theme || "1"} data-search-bar-active={ this.state.searchBarActive }>

                    <div className="main">

                        { (this.props.submission) && <h1 id="submission-title"> </h1> }
                        { (this.props.submission) && <h2 id="submission-sub"> </h2> }

                        { (this.props.maintenance) &&
                            <div className="job-search-container">
                                <h1>{this.props.h2Override || "Graduate Hiring. Made Simple."}</h1>
                                <h2 style={{ color: 'white' }}>{this.props.h3Override || "GradBay is an inclusive marketplace for hiring top-rated graduate talent."}</h2>
                            </div>
                        }

                        { (!this.props.submission && !this.props.maintenance) &&
                            <div className="job-search-container">
                                <h1>{this.props.h2Override || "Graduate Hiring. Made Simple."}</h1>
                                <h2 style={{ color: 'white' }}>{this.props.h3Override || "GradBay is an inclusive marketplace for hiring top-rated graduate talent."}</h2>
                                { (this.props.cvSearch) ?
                                    <div className="visible full-cv-search-wrapper" data-active={ (this.state.searchBarActive || this.state.ignoreHandleBlur) }>
                                        <div className="full-cv-search-input">
                                            <input
                                                id="full-cv-search-input-input"
                                                onChange={(e) => {
                                                    this.setState({
                                                        cvSearchKeywords: e.target.value
                                                    })
                                                }}
                                                placeholder="Nodejs, PHP"
                                                onKeyPress={ event => {
                                                    if (event.key === 'Enter') {
                                                        this.runSearch();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <button className="search-btn" onClick={() => {this.runSearch(true, undefined, "button")}}>Search</button>
                                        {/* <button className="search-btn mobile" onClick={() => {this.runSearch(true, undefined, "button")}}>
                                            <SearchIcon />
                                        </button> */}
                                        {AdvancedFilterToggleDOM}
                                    </div>
                                :
                                    <div className="visible" data-active={ (this.state.searchBarActive || this.state.ignoreHandleBlur) }>
                                        <div className="type">
                                            { (window.region === "GB") ?
                                                <Dropdown
                                                    title={ (this.state.nextSearchType === "candidate") ? "Search Grads" : "Search Jobs" }
                                                    id=""
                                                    light="true"
                                                    theme="2"
                                                    items={[
                                                        {
                                                            title: "Search Jobs",
                                                            data: "vacancy"
                                                        },
                                                        {
                                                            title: "Search Grads",
                                                            data: "candidate"
                                                        },
                                                    ]}
                                                    onClick={(tags) => {
                                                        this.updateNextSearchType(tags)
                                                    }}
                                                >
                                                </Dropdown>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="hr"></div>
                                        <div className="separator"></div>
                                        <div className="search-input">
                                            <ReactTags
                                                className="tag-editor"
                                                inline
                                                placeholder={(window.region === "GB") ? "Pick your tags" : "Search Internships"}
                                                minQueryLength={1}
                                                tags={this.state.tags}
                                                autofocus={ (isMobile) ? false : false }
                                                handleDelete={this.handleTagDelete}
                                                handleAddition={this.handleTagAddition}
                                                delimiters={delimiters}
                                                handleInputFocus={this.handleInputFocus}
                                                handleInputBlur={ function() {} /* this.handleInputBlur */ }
                                                handleInputChange={(textInputValue) => { this.suggestTags(textInputValue) }}
                                            />
                                            <button className="search-btn" onClick={() => {this.runSearch(true, undefined, "button")}}>Search</button>
                                            {/* <button className="search-btn mobile" onClick={() => {this.runSearch(true, undefined, "button")}}>
                                                <SearchIcon />
                                            </button> */}
                                            {AdvancedFilterToggleDOM}
                                        </div>
                                        { (this.state.skillLevel.editing) &&
                                            <div className="skill-level-picker" onClick={() => {
                                                    this.setState({
                                                        skillLevel: {
                                                            editing: false,
                                                            skill: '',
                                                            type: ''
                                                        },
                                                    })
                                                }}>
                                                <div>
                                                    <h4>Select the minimum skill level for {this.state.skillLevel.skill.text}</h4>
                                                    <div className="picker">
                                                        { ( (this.state.skillLevel.type.indexOf("Skills") > -1) ? LEVELS_SKILLS : LEVELS_LANGUAGES).map(item => {
                                                            return (
                                                                <div onClick={() => { this.addLevelsTag(item) }}>{item}</div>
                                                            )
                                                        }) }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                { (window.region === "PN") &&
                                    <div className="powered-by">
                                        Powered by <img src={Logo} />
                                    </div>
                                }
                                { (this.state.searchBarActive) &&
                                    <div className="dropdown featured-tags">
                                        <div>
                                            <div className="featured-tags tags-container">
                                                <CloseIcon onClick={() => { this.setState({ searchBarActive: false }) }} />
                                                { (["Job Type", "Sector", "Location", "Skills", "Languages", "Trait"]).map(section => {
                                                    return (
                                                        <div>
                                                            <h3>{section.replace("Type", "Function").replace("Trait", "Traits")}</h3>
                                                            <div className="tags-container" data-type={this.state.searchBarActive ? "suggestions" : "featured"}>
                                                                { (this.state.suggestionsKeyword) && this.state.suggestions[section].map(item => {
                                                                    return (
                                                                        <div
                                                                            onClick={() => {
                                                                                this.addTagToEditor(item.id, (item.name || item.text), window.region === "PN");
                                                                            }}
                                                                            data-id={item.id}
                                                                            className='tag'>
                                                                                <div className={ (this.state.tags.filter(t => (t.text.split(" - ")[0] === item.text)).length > 0) ? "active" : "" }>
                                                                                    {(item.name || item.text)}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                }) }
                                                                { (!this.state.suggestionsKeyword) && featuredTagsDropdownItems.map(item => {

                                                                    if (item.catName === "Job" && section === "Job Type") {

                                                                    } else if (section !== item.catName ) {
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <div
                                                                            onClick={() => {
                                                                                this.addTagToEditor(item.id, (item.name || item.text), window.region === "PN");
                                                                            }}
                                                                            data-id={item.id}
                                                                            className='tag'>
                                                                                <div className={ (this.state.tags.filter(t => (t.text.split(" - ")[0] === item.text)).length > 0) ? "active" : "" }>
                                                                                    {(item.name || item.text)}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                }) }
                                                            </div>
                                                        </div>
                                                    )
                                                }) }
                                            </div>
                                        </div>
                                    </div>
                                }
                                { ( (this.props.type === "candidate") || (this.state.nextSearchType === "candidate") ) &&
                                    <div className="advanced-filters-toggle clearfix" style={{ marginTop: 16 }}>
                                        <div className="cv-search-toggle" style={{ margin: "auto", width: "100%" }}>
                                            <p><span className="search-keyword">Search </span>Video Profiles</p>
                                            <div className="toggle" data-status={(this.props.cvSearch === false)}>
                                                <input class="tgl tgl-flat" id="graduate-status" type="checkbox" defaultChecked={(this.props.cvSearch === true) ? "checked" : ""}/>
                                                <label class="tgl-btn" for="graduate-status" onClick={() => {
                                                    if (this.props.cvSearch) {
                                                        window.location.href = "/graduates";
                                                    } else {
                                                        window.location.href = "/cv-search";
                                                    }
                                                }}></label>
                                            </div>
                                            <p><span className="search-keyword">Search </span>CVs</p>
                                        </div>
                                    </div>
                                }
                                { (this.state.showAdvancedFilters) &&
                                    <div className="advanced-filters">
                                        <h3 style={{ fontSize: 24, letterSpacing: 0.18 }}>Advanced Filters</h3>
                                        { (this.props.cvSearch) &&
                                            <div className="cv-search">
                                                <div className="custom-right">
                                                    <h3>Nice to Have</h3>
                                                    <input id="full-cv-search-nice-input-input" className="custom" onChange={(e) => { this.setState({ cvSearchKeywordsNice: e.target.value }) }} />
                                                </div>
                                            </div>
                                        }
                                        { (this.props.cvSearch) &&
                                            <div className="cv-search">
                                                <div className="custom-right">
                                                    <h3>Must not have</h3>
                                                    <input id="full-cv-search-not-input-input" className="custom" onChange={(e) => { this.setState({ cvSearchKeywordsNot: e.target.value }) }} />
                                                </div>
                                            </div>
                                        }
                                        { (!this.props.cvSearch && this.state.showKickstarterFilter && this.props.type === "candidate") &&
                                            <div className="purple-tags clearfix">
                                                <UncontrolledTooltip placement="bottom" target="kick-tp">
                                                    Show only candidates who meet the criteria for the UK Government Kickstart Scheme.
                                                </UncontrolledTooltip>
                                                <h3 id="kick-tp">Suitable for Kickstart Scheme</h3>
                                                <ReactTags
                                                    className="tag-editor"
                                                    inline
                                                    suggestions={[{ text: "Yes", id: "Yes" }]}
                                                    placeholder="Suitable for KS"
                                                    minQueryLength={0}
                                                    tags={this.state.kickstartFilter}
                                                    autocomplete={true}
                                                    autofocus={false}
                                                    handleDelete={this.handleTagKickstartDeletion}
                                                    handleAddition={this.handleTagKickstartAddition}
                                                    delimiters={delimiters}
                                                    handleFilterSuggestions={(textInputValue, possibleSuggestionsArray) => {
                                                        textInputValue = textInputValue.toLowerCase();
                                                        return possibleSuggestionsArray.filter(item => {
                                                            return (item.text.toLowerCase().indexOf(textInputValue) > -1)
                                                        }).slice(0, 10)
                                                        // this.suggestTags(textInputValue.toLowerCase());
                                                    }}
                                                />
                                            </div>
                                        }
                                        { (!this.props.cvSearch) &&
                                            <div className="salary">
                                                <h3 className="sub-filter">Salary</h3>
                                                <div className="salary-options">
                                                    <div className="range">
                                                        <InputRange
                                                            maxValue={
                                                                (this.state.salaryUnit === "Per Year") ? 80000 :
                                                                (this.state.salaryUnit === "Per Month") ? 8000 :
                                                                (this.state.salaryUnit === "Per Day") ? 1000 :
                                                                500
                                                            }
                                                            minValue={
                                                                (this.state.salaryUnit === "Per Year") ? 8000 :
                                                                (this.state.salaryUnit === "Per Month") ? 1000 :
                                                                (this.state.salaryUnit === "Per Day") ? 50 :
                                                                10
                                                            }
                                                            step={
                                                                (this.state.salaryUnit === "Per Year") ? 1000 :
                                                                (this.state.salaryUnit === "Per Month") ? 500 :
                                                                (this.state.salaryUnit === "Per Day") ? 50 :
                                                                10
                                                            }
                                                            formatLabel={value => `£${value}`}
                                                            value={{
                                                                min: this.state.salaryFromFilter,
                                                                max: this.state.salaryToFilter
                                                            }}
                                                            onChange={ (value) => {
                                                                console.log("CHANGING NOW", value);
                                                                this.setState({
                                                                    salaryFromFilter: value.min,
                                                                    salaryToFilter: value.max,
                                                                    hasSalaryRangeChanged: true
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="period">
                                                        <div data-selected={ this.state.salaryUnit === "Per Year" } onClick={() => { this.setState({ salaryUnit: "Per Year", salaryFromFilter: 8000, salaryToFilter: 80000 }) }}>Year</div>
                                                        <div data-selected={ this.state.salaryUnit === "Per Month" } onClick={() => { this.setState({ salaryUnit: "Per Month", salaryFromFilter: 1000, salaryToFilter: 8000 }) }}>Month</div>
                                                        <div data-selected={ this.state.salaryUnit === "Per Day" } onClick={() => { this.setState({ salaryUnit: "Per Day", salaryFromFilter: 50, salaryToFilter: 1000 }) }}>Day</div>
                                                        <div data-selected={ this.state.salaryUnit === "Per Hour" } onClick={() => { this.setState({ salaryUnit: "Per Hour", salaryFromFilter: 10, salaryToFilter: 500 }) }}>Hour</div>
                                                        {/* <select style={{ padding: '10px 24px' }} tabindex="4" onChange={(e) => {
                                                            this.setState({
                                                                salaryUnit: e.target.value,
                                                                salaryFromFilter: (e.target.value === "Per Year") ? 8000 :
                                                                    (e.target.value === "Per Month") ? 1000 :
                                                                    (e.target.value === "Per Day") ? 50 :
                                                                    10,
                                                                salaryToFilter: (e.target.value === "Per Year") ? 80000 :
                                                                (e.target.value === "Per Month") ? 8000 :
                                                                (e.target.value === "Per Day") ? 1000 :
                                                                500
                                                            })
                                                        }} id="salaryUnit" value={this.state.salaryUnit}>
                                                            <option value="Per Year">Per Year</option>
                                                            <option value="Per Month">Per Month</option>
                                                            <option value="Per Day">Per Day</option>
                                                            <option value="Per Hour">Per Hour</option>
                                                        </select> */}
                                                    </div>
                                                </div>
                                            </div>
                                        } 
                                        { /* (!this.props.cvSearch) &&
                                            <div>
                                                <div className="custom-right" style={{ width: '100%' }}>
                                                    <div>
                                                        <input className="custom" defaultValue={this.state.salaryFromFilter} onChange={(e) => { this.setState({ salaryFromFilter: e.target.value }) }}></input>
                                                    </div>
                                                    <div>
                                                        <span>TO</span>
                                                        <input className="custom" defaultValue={this.state.salaryToFilter} onChange={(e) => { this.setState({ salaryToFilter: e.target.value }) }}></input>
                                                    </div>
                                                    <div>
                                                        <span>PER</span>
                                                        <select style={{ padding: '10px 24px' }} tabindex="4" onChange={(e) => {
                                                            this.setState({
                                                                salaryUnit: e.target.value,
                                                            })
                                                        }} id="salaryUnit" value={this.state.salaryUnit}>
                                                            <option value="Per Year">Year</option>
                                                            <option value="Per Month">Month</option>
                                                            <option value="Per Day">Day</option>
                                                            <option value="Per Hour">Hour</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        */ }
                                        { ( (this.props.type === "candidate") && (!this.props.cvSearch) ) && <h3 className='sub-filter'>Education</h3> }
                                        { ( (this.props.type === "candidate") && (!this.props.cvSearch) ) &&
                                            <div className="purple-tags">
                                                <h3>University</h3>
                                                <ReactTags
                                                    className="tag-editor"
                                                    inline
                                                    suggestions={window.globalVars.autoSuggestions.UNIVERSITIES}
                                                    placeholder="University"
                                                    minQueryLength={0}
                                                    tags={this.state.universityFilter}
                                                    autocomplete={true}
                                                    autofocus={false}
                                                    handleDelete={this.handleTagUniversityDeletion}
                                                    handleAddition={this.handleTagUniversityAddition}
                                                    delimiters={delimiters}
                                                    handleFilterSuggestions={(textInputValue, possibleSuggestionsArray) => {
                                                        textInputValue = textInputValue.toLowerCase();
                                                        return possibleSuggestionsArray.filter(item => {
                                                            return (item.text.toLowerCase().indexOf(textInputValue) > -1)
                                                        }).slice(0, 10)
                                                        // this.suggestTags(textInputValue.toLowerCase());
                                                    }}
                                                />
                                            </div>
                                        }
                                        { ( (this.props.type === "candidate") && (!this.props.cvSearch) ) &&
                                            <div className="purple-tags">
                                                <h3>Subject</h3>
                                                <ReactTags
                                                    className="tag-editor"
                                                    inline
                                                    placeholder="Subject"
                                                    suggestions={window.globalVars.autoSuggestions.SUBJECTS}
                                                    minQueryLength={0}
                                                    tags={this.state.subjectFilter}
                                                    autocomplete={true}
                                                    autofocus={false}
                                                    handleDelete={this.handleTagSubjectDeletetion}
                                                    handleAddition={this.handleTagSubjectAddition}
                                                    delimiters={delimiters}
                                                    handleFilterSuggestions={(textInputValue, possibleSuggestionsArray) => {
                                                        textInputValue = textInputValue.toLowerCase();
                                                        return possibleSuggestionsArray.filter(item => {
                                                            return (item.text.toLowerCase().indexOf(textInputValue) > -1)
                                                        }).slice(0, 10)
                                                    }}
                                                />
                                            </div>
                                        }
                                        { ( (this.props.type === "candidate") && (!this.props.cvSearch) ) &&
                                            <div className="purple-tags">
                                                <h3>Degree</h3>
                                                <ReactTags
                                                    className="tag-editor"
                                                    inline
                                                    placeholder="Degree"
                                                    suggestions={window.globalVars.autoSuggestions.DEGREES}
                                                    minQueryLength={0}
                                                    tags={this.state.degreeFilter}
                                                    autocomplete={true}
                                                    autofocus={false}
                                                    handleDelete={this.handleTagDegreeDeletion}
                                                    handleAddition={this.handleTagDegreeAddition}
                                                    delimiters={delimiters}
                                                    handleFilterSuggestions={(textInputValue, possibleSuggestionsArray) => {
                                                        textInputValue = textInputValue.toLowerCase();
                                                        return possibleSuggestionsArray.filter(item => {
                                                            return (item.text.toLowerCase().indexOf(textInputValue) > -1)
                                                        }).slice(0, 10)
                                                    }}
                                                />
                                            </div>
                                        }
                                        { ( (this.props.type === "candidate") && (!this.props.cvSearch) ) &&
                                            <div className="date-range-picker-custom">
                                                <h3>Education End</h3>
                                                <DateRangePicker
                                                    numberOfMonths={1}
                                                    startDate={this.state.educationEndDateStartDate}
                                                    startDateId={ (this.state.educationEndDateStartDate !== null) ? (this.state.educationEndDateStartDate.format("MMM Do YY")) : "" }
                                                    endDate={this.state.educationEndDateEndDate}
                                                    endDateId={ (this.state.educationEndDateEndDate !== null) ? (this.state.educationEndDateEndDate.format("MMM Do YY")) : "" }
                                                    onDatesChange={({ startDate, endDate }) => {
                                                        this.setState({ educationEndDateStartDate: startDate, educationEndDateEndDate: endDate })
                                                    }}
                                                    isOutsideRange={() => false}
                                                    focusedInput={this.state.focusedInput}
                                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                />
                                            </div>
                                        }
                                        { ( (this.props.type === "candidate") && (!this.props.cvSearch) ) && <h3 className='sub-filter'>Availability</h3> }
                                        { ( (this.props.type === "candidate") && (!this.props.cvSearch) ) &&
                                            <div className="green-tags">
                                                <h3>Last Logged in</h3>
                                                <div className="alt-tags">
                                                    { [
                                                        "1 day ago",
                                                        "2 days ago",
                                                        "1 week ago",
                                                        "2 weeks ago",
                                                        "1 month ago",
                                                        "2 months ago",
                                                        "3 months ago",
                                                        "6 months ago"
                                                    ].map(item => {
                                                        return (
                                                            <div data-selected={this.state.activityFilter.indexOf(item) !== -1} className="taga" onClick={() => { this.setState({ activityFilter: [item] }) }}>{item}</div>
                                                        )
                                                    })

                                                    }
                                                </div>
                                            </div>
                                        }
                                        { ( (this.props.type === "candidate") && (!this.props.cvSearch) ) &&
                                            <div className="green-tags">
                                                <h3>Place of work</h3>
                                                <ReactTags
                                                    className="tag-editor"
                                                    inline
                                                    placeholder="City"
                                                    suggestions={window.globalVars.autoSuggestions.CITIES}
                                                    autofocus={false}
                                                    minQueryLength={0}
                                                    tags={this.state.cityFilter}
                                                    autocomplete={true}
                                                    handleDelete={this.handleTagCityDeletion}
                                                    handleAddition={this.handleTagCityAddition}
                                                    delimiters={delimiters}
                                                    handleFilterSuggestions={(textInputValue, possibleSuggestionsArray) => {
                                                        textInputValue = textInputValue.toLowerCase();
                                                        return possibleSuggestionsArray.filter(item => {
                                                            return (item.text.toLowerCase().indexOf(textInputValue) > -1)
                                                        }).slice(0, 10)
                                                    }}
                                                />
                                            </div>
                                        }
                                        { ( (this.props.type === "vacancy") && (!this.props.cvSearch) ) &&
                                            <div className="green-tags">
                                                <h3>Place of Work</h3>
                                                <ReactTags
                                                    className="tag-editor"
                                                    inline
                                                    placeholder="Location"
                                                    suggestions={window.globalVars.autoSuggestions.CITIES}
                                                    minQueryLength={0}
                                                    tags={this.state.cityFilter}
                                                    autocomplete={true}
                                                    autofocus={false}
                                                    handleDelete={this.handleTagCityDeletion}
                                                    handleAddition={this.handleTagCityAddition}
                                                    delimiters={delimiters}
                                                    handleFilterSuggestions={(textInputValue, possibleSuggestionsArray) => {
                                                        textInputValue = textInputValue.toLowerCase();
                                                        return possibleSuggestionsArray.filter(item => {
                                                            return (item.text.toLowerCase().indexOf(textInputValue) > -1)
                                                        }).slice(0, 10)
                                                    }}
                                                />
                                            </div>
                                        }
                                        { (!this.props.cvSearch) &&
                                            <div className="green-tags">
                                                <h3>Remote Work</h3>
                                                <div className="alt-tags">
                                                    { ((this.props.type === "vacancy") ? ["Yes", "No", "Maybe", "Explicitly remote"] : ["Yes", "No", "Remote Only"]).map(item => {
                                                        return (
                                                            <div data-selected={this.state.remoteWorkFilter.indexOf(item) !== -1} className="taga" onClick={() => {
                                                                let remoteWorkFilter = this.state.remoteWorkFilter;
                                                                if (remoteWorkFilter.indexOf(item) !== -1) {
                                                                    remoteWorkFilter.splice(remoteWorkFilter.indexOf(item), 1);
                                                                } else {
                                                                    remoteWorkFilter.push(item);
                                                                }
                                                                this.setState({ activePage: 1, remoteWorkFilter: remoteWorkFilter })
                                                            }}>{item}</div>
                                                        )
                                                    })

                                                    }
                                                </div>
                                            </div>
                                        }
                                        { (!this.props.cvSearch) &&
                                            <div className="green-tags">
                                                <h3>Employment Type</h3>
                                                <div className="alt-tags">
                                                    { [
                                                        "Permanent",
                                                        "Temporary",
                                                        "Internship",
                                                        "Contract",
                                                        "Fixed Term"
                                                    ].map(item => {
                                                        return (
                                                            <div data-selected={this.state.employmentTypeFilter.indexOf(item) !== -1} className="taga" onClick={() => {
                                                                let employmentTypeFilter = this.state.employmentTypeFilter;
                                                                if (employmentTypeFilter.indexOf(item) !== -1) {
                                                                    employmentTypeFilter.splice(employmentTypeFilter.indexOf(item), 1);
                                                                } else {
                                                                    employmentTypeFilter.push(item);
                                                                }
                                                                this.setState({ activePage: 1, employmentTypeFilter: employmentTypeFilter })
                                                            }}>{item}</div>
                                                        )
                                                    })

                                                    }
                                                </div>
                                            </div>
                                        }
                                        { (!this.props.cvSearch) &&
                                            <div className="green-tags">
                                                <h3>Employment Hours</h3>
                                                <div className="alt-tags">
                                                    { [
                                                        "Full-time",
                                                        "Part-time",
                                                    ].map(item => {
                                                        return (
                                                            <div data-selected={this.state.employmentHoursFilter.indexOf(item) !== -1} className="taga" onClick={() => {
                                                                let employmentHoursFilter = this.state.employmentHoursFilter;
                                                                if (employmentHoursFilter.indexOf(item) !== -1) {
                                                                    employmentHoursFilter.splice(employmentHoursFilter.indexOf(item), 1);
                                                                } else {
                                                                    employmentHoursFilter.push(item);
                                                                }
                                                                this.setState({ activePage: 1, employmentHoursFilter: employmentHoursFilter })
                                                            }}>{item}</div>
                                                        )
                                                    })

                                                    }
                                                </div>
                                            </div>
                                        }

                                        { ( (this.props.type === "candidate") && (!this.props.cvSearch) ) &&
                                            <div className="green-tags">
                                                <h3>Notice Period</h3>
                                                <div className="alt-tags">
                                                    { [
                                                        "None",
                                                        "1 week",
                                                        "2 weeks",
                                                        "3 weeks",
                                                        "1 month",
                                                        "2 months",
                                                        "3 months",
                                                        "6 months",
                                                    ].map(item => {
                                                        return (
                                                            <div data-selected={this.state.noticePeriodFilter.indexOf(item) !== -1} className="taga" onClick={() => {
                                                                let noticePeriodFilter = this.state.noticePeriodFilter;
                                                                if (noticePeriodFilter.indexOf(item) !== -1) {
                                                                    noticePeriodFilter.splice(noticePeriodFilter.indexOf(item), 1);
                                                                } else {
                                                                    noticePeriodFilter.push(item);
                                                                }
                                                                this.setState({ activePage: 1, noticePeriodFilter: noticePeriodFilter })
                                                            }}>{item}</div>
                                                        )
                                                    })

                                                    }
                                                </div>
                                            </div>
                                        }

                                        { ( (this.props.type === "vacancy") && (!this.props.cvSearch) ) &&
                                            <div className="green-tags">
                                                <h3>Sector</h3>
                                                <ReactTags
                                                    className="tag-editor"
                                                    inline
                                                    placeholder="Sector"
                                                    suggestions={this.state.allTags.filter(item => {
                                                        return (item.cat === "J Sector") ? item : null;
                                                    })}
                                                    minQueryLength={0}
                                                    tags={this.state.sectorFilter}
                                                    autocomplete={true}
                                                    autofocus={false}
                                                    handleDelete={this.handleTagSectorDeletion}
                                                    handleAddition={this.handleTagSectorAddition}
                                                    delimiters={delimiters}
                                                    handleFilterSuggestions={(textInputValue, possibleSuggestionsArray) => {
                                                        textInputValue = textInputValue.toLowerCase();
                                                        return possibleSuggestionsArray.filter(item => {
                                                            return (item.text.toLowerCase().indexOf(textInputValue) > -1)
                                                        }).slice(0, 10)
                                                    }}
                                                />
                                            </div>
                                        }

                                        { ( ( (this.props.type === "candidate") || (this.props.type === "outside-vacancy") ) && (!this.props.cvSearch) ) &&
                                            <div style={{ marginTop: 16 }} className="purple-tags">
                                                <h3>Must Have</h3>
                                                <ReactTags
                                                    className="tag-editor"
                                                    inline
                                                    placeholder="Must have"
                                                    suggestions={this.state.allTags}
                                                    minQueryLength={0}
                                                    autofocus={false}
                                                    tags={this.state.mustHaveFilter}
                                                    autocomplete={true}
                                                    handleDelete={this.handleTagMustHaveDeletetion}
                                                    handleAddition={this.handleTagMustHaveAddition}
                                                    delimiters={delimiters}
                                                />
                                            </div>
                                        }
                                        {  ( ( (this.props.type === "candidate") || (this.props.type === "outside-vacancy") ) && (!this.props.cvSearch) ) &&
                                            <div className="red-tags">
                                                <h3>Must Not Have</h3>
                                                <ReactTags
                                                    className="tag-editor"
                                                    inline
                                                    placeholder="Must have"
                                                    suggestions={this.state.allTags}
                                                    minQueryLength={0}
                                                    autofocus={false}
                                                    tags={this.state.mustNotHaveFilter}
                                                    autocomplete={true}
                                                    handleDelete={this.handleTagMustNotHaveDeletetion}
                                                    handleAddition={this.handleTagMustNotHaveAddition}
                                                    delimiters={delimiters}
                                                />
                                            </div>
                                        }
                                        <button style={{ marginTop: 24, backgroundColor: "#db3951" }} className="search-btn clear-all-filters" onClick={() => { this.clearFilters() }}>Clear filters</button>
                                        <button className="search-btn" onClick={() => {this.runSearch(true, undefined, "button")}}>Apply Filters</button>
                                    </div>
                                }

                            </div>
                        }

                        { ( (this.props.home === "true") && (this.state.heroReview) ) &&
                            <div className="reviews">
                                <div>
                                    <div className="details">
                                        <div>
                                            <div className="stars">
                                                { this.state.heroReview.stars > 0 && <StarIcon /> }
                                                { this.state.heroReview.stars > 1 && <StarIcon /> }
                                                { this.state.heroReview.stars > 2 && <StarIcon /> }
                                                { this.state.heroReview.stars > 3 && <StarIcon /> }
                                                { this.state.heroReview.stars > 4 && <StarIcon /> }
                                            </div>
                                            <h3>{this.state.heroReview.name}</h3>
                                            <p>{this.state.heroReview.role}</p>
                                        </div>
                                    </div>
                                    <div className="person">
                                        <img alt={"Review of " + this.state.heroReview.name} src={this.state.heroReview.image} />
                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                    {
                        (this.props.backButtonText) ?
                            <div className="main-container ar-career-fair">
                                <BackButton title={this.props.backButtonText} />
                                { (this.props.additionalLink) &&
                                    <a className="additional-link" href={this.props.additionalLink.link} target="_blank">{this.props.additionalLink.title}</a>
                                }
                                <a className="additional-link" href="https://ar.potnoodle.com/" target="_blank">AR Careers Fair</a>
                            </div>
                        :
                        null
                    }

                </div>

                { (this.props.maintenance) &&
                    <div className="maintenance" style={{
                        backgroundColor: "rgb(247 69 69)",
                        color: "white",
                        padding: 20,
                        fontWeight: 700,
                        fontSize: "1.2em",
                        textAlign: "center",
                        width: "100%"
                    }}>
                        We are carrying out scheduled maintenance today, please check back later.
                    </div>
                }
                {optionsDOM}
                {listDOM}
                {paginationDOM}
                { window.region === "GB" && recentlyViewedDOM }
                {faqDOM}
                {quickSearchesDOM}

            </div>

        );

    }

}

export default GlobalSearch;
