import React, { Component } from "react";
import uniqid from "uniqid";
import Dropdown from "./Dropdown";
import FAQ from "./FAQ";
import "./FAQContainer.css";
import "./TypePicker.css";
import utilities from "./Utilities";
// import FAQImg from '../images/new-design/animation-img/faq.gif'
import FAQmp4 from "../images/new-design/animation-img/FAQ.mp4";
import FAQogg from "../images/new-design/animation-img/FAQ.ogg";

let Utilities = new utilities();
class FAQContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }
    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        this.setState(
            {
                type: this.props.type,
            },
            function() {
                this.updateData();
            }
        );
    }

    handleChange = (value) => {
        this.setState(
            {
                type: value === "Graduate" ? "candidate" : "vacancy",
            },
            function() {
                this.updateData();
            }.bind(this)
        );
    };

    updateData = () => {
        let data = [];
        if (window.region === "PN") {
            data = [
                {
                    question: "What are the open and closing dates of the website for 2024?",
                    answer: "Pot Noodle Internships will be open in two bursts throughout 2024. The first 6 week burst runs from February 1st to March 14th. The second burst will be open from September 10th to October 22nd."
                },
                {
                    question: "Do I need to have a university degree to apply?",
                    answer:
                        "A University degree is not necessary to apply to the internships unless specifically requested on the internship vacancy.",
                },
                {
                    question: "When do the internships take place?",
                    // answer:
                    //     "The internships take place from April up until the end of 2022. The exact timing of each internship depends on when the Employer is happy to hire and when you have the availability to join their team.",
                    answer:"The internships take place from January 2024 until the end of 2024. The exact timing of each internship depends on when the Employer is happy to hire and when you have the availability to join their team.",
                },
                {
                    question: "How long are the internships and are they paid?",
                    answer:
                        "All the internships are paid and the details of the salary will be highlighted on each vacancy.",
                },
                {
                    question: "Could the internships be extended?",
                    answer:
                        "Yes, the Employers can extend the internship or hire the intern without incurring any recruitment fees. The Employer would need to agree a salary with the intern moving forward and provide an employment contract.",
                },
                {
                    question: "Can I apply to multiple internships?",
                    answer:
                        "It’s best to apply to the internships that are of real interest where both the company and role appeal to you. If there are multiple internships that tick those boxes, then feel free to apply!",
                },
                {
                    question: "Can anyone apply to these internships?",
                    answer:
                        "Yes! Anyone aged 16-24 can apply to these internships whether they are in education or not.",
                },
                {
                    question: "How do I apply?",
                    answer:
                        "This campaign allows you to apply with just a video introduction, so get your smart phone out, take a quick selfie video expressing your interests and upload it to your profile.",
                },
                {
                    question: "Where do the internships take place?",
                    // answer:
                    //     "Depending on coronavirus restrictions, they’ll either take place remotely or in the office of the Employer.",
                    answer:"They'll either take place remotely or in the office of the Employer. The working arrangements will be highlighted on each vacancy.",
                },
                {
                    question: "How can I check the status of my application?",
                    answer:
                        "The status of your applications can be found in the Job Application section of your dashboard.",
                },
                {
                    question: "Can I contact employers?",
                    answer:
                        "Employers can only message you if you applied to their internship listing, once a message thread is started you will be notified via your registered email address and can reply through our messenger system.",
                },
            ];
        } else if (this.state.type === "candidate") {
            data = [
                {
                    question: "Why choose GradBay?",
                    answer:
                        "GradBay provides a free and easy to use platform for searching and applying to graduate jobs. GradBay uses intelligent advanced search technology to help you find exactly the type of job you’re looking for, and video profiling to make the application process much more efficient for both you and the potential employer (hiring managers are twice as likely to open a video profile than a traditional CV when given the chance!). On top of this there is an experienced team to provide support throughout the process, and who have a large network of client companies in a range of sectors. GradBay gives you, the candidate, a high degree of autonomy and independence with your profile. Unlike other job finding websites or graduate recruitment agencies you, the candidate, have control over how you are presented to companies.",
                },
                {
                    question: "What is GradBay?",
                    answer:
                        "GradBay is a graduate recruitment agency based in London. Recruitment agencies, unlike other job websites (which can act more like noticeboards for companies), are an efficient way of being put in touch with companies you might not necessarily have heard about and a good way of finding unusual jobs or positions you might not have otherwise considered. GradBay is specially tailored to graduates, and uses a tagging system to make matches that appeal both to the candidate as well as the client company. Whether you are an STEM graduate or have just finished a humanities degree, GradBay can help get you noticed by businesses in a variety of sectors.",
                },
                {
                    question: "How does it work?",
                    answer:
                        "Graduate recruitment agencies match clients who might be looking for a very specific type of person for a role with candidates that fit that description. They make the process of finding and applying to jobs far more efficient, both for graduates and for companies. Specifically with regards to GradBay though, you send a CV and if it’s accepted you create a profile on the website. This profile includes images, videos and tags which allow companies to browse candidates according to how well you’re matched in terms of interests and skills. You can tailor your tags, pictures, and videos giving you a lot of independence that other graduate recruitment agencies can’t always provide. GradBay will also set questions for you to answer in a video format, this means that employers can make better judgements before calling you to an interview, making the entire process more efficient. For candidates, the service is free.",
                },
                {
                    question: "What do you need from me?",
                    answer:
                        "To start with, you only need two things: the right to work in the UK, and your CV. It is also useful (but not essential) to have some idea of what you want to do as this makes it easier to match you with jobs you’d actually want, and thus save time for both you and the company that is hiring. If accepted, you’d also need access to a laptop or PC with a working webcam in order to produce the videos for your profile. The system is simple, user friendly and anyone looking for a graduate job can apply, regardless of degree class or subject.",
                },
                {
                    question: "Does it cost me anything?",
                    answer:
                        "No! This service is entirely free for candidates. It is illegal and unethical for recruitment agencies to charge candidates. Instead, graduate recruitment agencies make money by charging companies. This business model works because companies might have a very specific type of person in mind for a job but looking for that person can be like looking for a needle in a haystack, so it makes more sense for a company to pay a recruitment agency to match appropriate candidates and pay the agency rather than spend money on advertising jobs to everyone and then trawling through many (potentially unsuitable) applications themselves. Because it’s such a useful tool for companies, recruitment agencies can charge them for the service, and there is no cost for candidates.",
                },
                {
                    question: "What types of jobs do you recruit for?",
                    answer:
                        "This depends on what client companies are looking for, but theoretically, all types of jobs can be found through graduate recruitment agencies. Companies in a variety of sectors, like the finance, charity, or energy sectors, will use graduate recruitment agencies. Within these companies there will be different roles which they are recruiting for as well – marketing, sales, human resources, research to name just a few. There are also many more sectors and positions which are more specific to degree types (suitable for STEM graduates or linguists for instance), or which are more unusual so less likely to be heard about outside the platform. Because recruitment agencies have so many client companies there is a wide range of job types available for candidates, including permanent jobs, internships and grad schemes.",
                },
                {
                    question: "How do I find a graduate job?",
                    answer:
                        "Knowing what you are good at and what you’d enjoy is the most important part of finding a graduate job. To find out what sort of career would be appropriate for you it is important to spend some time researching different sectors and companies and get to know what’s out there as much as possible (you can do this through online research, internships, volunteering etc.). Once you know what you’d be interested in, you can get in touch with companies directly or through recruiters and websites. There are many different options out there but being proactive and getting involved as much as possible is a good way to find out what works for you as well as a great way to meet new people and network.",
                },
                {
                    question: "What is a graduate recruitment scheme?",
                    answer:
                        "A graduate recruitment scheme is a training programme offered by a company for recent university graduates. The employer will offer a placement in a department within the company and train graduates in that department. Often the training will involve very transferrable skills such as management, communication and presentation, but it can also be more specific to the relevant sector (such as finance, or law). Large companies tend to run graduate recruitment schemes as they can afford to invest time and money into training graduates, however there are many programmes out there in various sectors.  They are a great way to gain skills and experience, and often at the end of a placement a company will offer a job within the company depending on how successful the placement was.",
                },
                {
                    question: "What are the benefits of a video CV?",
                    answer:
                        "A video CV is a great addition to a traditional CV as it gives you the chance to make more of an impression on an employer. A video CV provides more of an opportunity for personality to shine through than a traditional CV, and also gives more scope for individualism. They are a great way to be noticed, and a useful tool for demonstrating qualities that could be listed on a traditional CV such as communication skills. A video CV may not always be appropriate, but where possible they can add just a little bit more character to an application, which is likely to make you a more memorable",
                },
                {
                    question: "What happens if my placement doesn’t work out?",
                    answer:
                        "As with any other walk of life, you can’t expect everything to be perfect the first time around. GradBay understands this, which is why GradBay would assist graduates that want to leave a placement for whatever reason. GradBay can assist a graduate with leaving a company in a professional manner, on top of this, GradBay would discuss the issues with the candidate, which would help to find a more appropriate placement for the candidate next time, while also making the people they match to the company a better fit in future. It is important to remember that all experience is good experience when it comes to job hunting: not only can you use skills you learn along the way in future scenarios, but the placement can still be used to compliment your CV even if it didn’t go as originally planned.",
                },
            ];
        } else if (this.state.type === "vacancy") {
            data = [
                {
                    question: "What is GradBay?",
                    answer:
                        "Recruitment agencies connect potential employees to companies and businesses. GradBay is a graduate recruitment agency, so unlike some other job websites and recruitment companies, the candidates being recruited are all graduates. GradBay is a new, London based platform, set up by experienced recruiters. It makes use of the latest technology in order to set up suitable matches between companies and candidates. GradBay makes finding and hiring graduates simpler and more efficient for everyone involved. Graduates can set up and customise profiles on the platform, which makes it easier for businesses to see at a glance which candidates would be most suitable, and then businesses can easily manage applications through the applicant tracking system. ",
                },
                {
                    question: "How does it work?",
                    answer:
                        "Candidates use the GradBay platform to set up profiles which have videos, CVs and portfolios. They can then use the job search engine, which uses a tag based system, to find job opportunities that appeal to them and which they would be suitable for. They then apply to the jobs through the platform. Businesses can use the applicant tracking system to evaluate and manage applications, which is also made more efficient through the simple and effective lay out of the graduate profile page. Alternatively, businesses can send the job specifications to GradBay and be sent candidate shortlists within 48 hours. Businesses are only charged if an applicant is successful and given a job, when this happens, there is also a three month replacement service/money back guarantee.",
                },
                {
                    question: "How much does it cost?",
                    answer:
                        "You are only charged if GradBay finds a successful candidate for your business. The service is free to trial, with no upfront costs. If a successful candidate is found for your business through GradBay, the standard fee is 15% of whatever the starting salary of the graduate is. On top of this, GradBay offers a three month free replacement period/money back guarantee. GradBay offers flexible payment and generous rebate terms for start-ups. Get in touch to find out more!",
                },
                {
                    question: "Why use a graduate recruitment agency?",
                    answer:
                        "There are two main scenarios which make recruitment agencies worth it: when a business has too many applicants it becomes inefficient to filter through them all looking for that perfect candidate; and when a company (or a position) does not have enough exposure and there are not enough people applying to roles. In the first case, looking for a candidate is like looking for a needle in a haystack, and recruitment companies make the whole process more time efficient and usually more cost efficient too. In the second case, recruitment agencies provide a good way for businesses to increase exposure and find new applicants that might not have otherwise have heard about them. Recruitment agencies are there to find a good match for both the candidate and the company, they aim to find someone with all the skills and qualifications to fill a position which can otherwise be a long, costly and drawn out process.",
                },
                {
                    question: "Why choose GradBay?",
                    answer:
                        "GradBay has been set up by experienced recruiters, who have worked with hundreds of clients and managed 2500 placements in the past four years alone. The new platform uses a combination of intelligent advanced search technology and video technology to make sure potential employees are well suited to your company. You can have a very specific type of person in mind for a position, and GradBay will be able to use the advanced tag system to find graduates suitable for the position and filter out candidates that would be less likely to fit the description.  You can undertake the search yourself using key words and watching video profiles to create shortlists, or you can send the job specifications and be sent candidate shortlists within 48 hours. GradBay has a three month free replacement service/money back guarantee, which is a clear indicator of the confidence with which shortlists and matches are produced.",
                },
                {
                    question: "Do you recruit for my sector?",
                    answer:
                        "Yes! The only thing GradBay specialises in is graduates. This means that if the position you want to fill is suitable for a graduate then GradBay will be able to recruit for it. GradBay has a comprehensive database of candidates with profiles on the website, with a range of skills, interests and degrees. Whatever the job, GradBay optimises the search for candidates, and makes the process of employing someone simple and efficient. GradBay works across all sectors and locations to match graduates with companies, and because of the intelligent search technology, companies can make their requirements more specific than if advertising through other channels.",
                },
                {
                    question: "What are the benefits of hiring a graduate?",
                    answer:
                        "Graduates may not have a wealth of experience in the workplace, but they do have excellent transferrable skills. Literacy, numeracy, self-motivation, presentation skills and computing skills are all things students have to pick up over the course of their degrees. On top of this, graduates are keen for experience and eager to learn, meaning they can be trained up fairly easily for a variety of positions as needed. Being young, they can bring fresh new ideas and approaches to a team as well, so although they might need a little time investment, hiring a graduate can really pay off.",
                },
                {
                    question:
                        "How do I interview a graduate for a position within my company?",
                    answer:
                        "Since personality and aptitude are both key in the work place, an interviewer should aim to be as probing as possible where these are concerned. Values are also an important thing to ascertain at the interview stage: making sure the candidate’s values align well with the company values is key to creating a healthy work place. Since every company will value different things to differing extents, there is no set way of interviewing a graduate and no particular set of questions that will be enlightening. Instead companies must decide what they value in a candidate and ask questions relevant to that.",
                },
                {
                    question:
                        "What is the average salary a university graduate is  expected to receive?",
                    answer:
                        "Graduates expect a reasonable salary based on their relevant experience, the nature of the position and the location where the position is based. A more experienced graduate will expect to be paid more, as will graduates that are given positions of higher responsibility. In areas such as London, where the living costs are high, a graduate will need to be paid more than in other areas. Many entry level jobs advertise positions at £18 000 and above, but if unsure there are ways to ascertain what a reasonable salary is. Asking a candidate what their salary expectations are, or advertising a position with a salary range can be a useful approach if unsure of an exact figure.",
                },
                {
                    question:
                        "What happens if the graduate leaves their placement early?",
                    answer:
                        "Although GradBay puts a lot of time and energy into finding the perfect graduate for you, no system is 100% foolproof. However, GradBay is confident enough in its matches that if a candidate leaves a position for any reason within the first three months, there will be a 100% full refund of the fee paid. On top of this, GradBay takes the on the responsibility to work out what went wrong: talking to the business and the graduate to find out how to make future placements more successful. Typical reasons for Graduates leaving placements early is that they are left without the required supervision to develop their skills, leaving them feeling dejected. Given the necessary training, followed by responsibility and guidelines as to what is expected of them, a graduate can excel and can often exceed expectations.",
                },
            ];
        } else if (this.state.type === "kickstart") {
            data = [
                {
                    question:
                        "What happens if I don’t want to hire any of the candidates put forward by the Job Centre Plus?",
                    answer:
                        "You don’t have to hire anyone if you’re not satisfied with the calibre of candidates after the interview process. This means you won’t receive the funding. ",
                },
                {
                    question: "When does the Employer receive the funding?",
                    answer:
                        "The Employer receives the funding once the candidate has started work and is signed up to the Employers payroll. This will be noted by the HMRC who will inform DWP and the funding will be released to GradBay, who will promptly pay the funding to the Employer.",
                },
                {
                    question: "How do the wages get paid?",
                    answer:
                        "Once your employee is on your payroll you will pay them for the hours worked on the last Friday of the month. HMRC will inform DWP that your employees first salary has been processed and the DWP will then reimburse you directly. This process will repeat for months 2,3,4,5 and 6.",
                },
                {
                    question:
                        "What happens if my employee leaves their position?",
                    answer:
                        "If your employee leaves their post, you won’t be asked to repay the £1,500 funding but you will be encouraged to source another candidate and start the process again.",
                },
                {
                    question:
                        "Do I need employers' liability insurance for part-time workers?",
                    answer:
                        " Yes, you do need employers' liability insurance for part-time workers. It's compulsory to have employers' liability insurance if you have any employees, even if they're part-time or temporary, even if it’s just one employee.",
                },
                {
                    question:
                        "Do I need to include my employee on the company pension scheme?",
                    answer:
                        "If you’re Kickstart employee is earning less than £10,000 they do not need to be included on your pension scheme. However, if you would like to include them on your pension scheme then you can.",
                },
                {
                    question: "What is a Work Coach?",
                    answer:
                        "The Work Coach is a representative of the Job Centre Plus. The Work Coach will lead the search to source relevant candidates who meet your job requirements. Once the Work Coach finds relevant candidates, he/she will instruct these candidates to contact you directly with their CV.",
                },
                {
                    question:
                        "Can I, the Employer, advertise the vacancies through social media or job boards?",
                    answer:
                        "No, only the Job Centre Plus can advertise the Kickstart vacancies.",
                },
                {
                    question:
                        "Can I, the Employer, find my own candidate for the Kickstart Scheme?",
                    answer:
                        "No, only the Work Coach can source candidates for you. If you hire a candidate who has not been put forward by your Work Coach then you won’t receive the funding or reimbursement of wages.",
                },
                {
                    question: "Does GradBay charge a fee?",
                    answer:
                        "No, GradBay does not charge a fee for processing your application or assisting with any queries you have.",
                },
            ];
        }
        this.setState({
            data: data,
        });
    };
    render() {
        let selectedTypeLabel = "Graduate";
        if (this.state.type === "vacancy") {
            selectedTypeLabel = "Employer";
        }
        return (
            <div
                className="faq-container clearfix"
                data-section={this.props.section}
            >
                {!Utilities.isENU(this.props.title) ? (
                    <h2 className="title">{this.props.title}</h2>
                ) : null}
                {this.props.showTypePicker ? (
                    <div className="type-picker">
                        <span>I am a</span>
                        <Dropdown
                            title={selectedTypeLabel}
                            id=""
                            onChange={null}
                            light="true"
                            theme="3"
                            items={[
                                {
                                    title: "Graduate",
                                    data: "Graduate",
                                },
                                {
                                    title: "Employer",
                                    data: "Employer",
                                },
                            ]}
                            onClick={function(data) {
                                this.handleChange(data);
                            }.bind(this)}
                        ></Dropdown>
                    </div>
                ) : null}
                <div className="container">
                    <div className="faq_head">
                        <h1>FAQ'S</h1>
                    </div>
                    <div className="row">
                        {/* <div className="col-md-6"> */}
                        {/* <figure>
                                <img src={FAQImg} alt="faq image" />
                            </figure> */}
                        {/* <div className="vide_wrap">
                                <video autoplay="play" muted="yes" loop="yes">
                                    <source src={FAQmp4} type="video/mp4" />
                                    <source src={FAQogg} type="video /ogg" />
                                </video> 
                            </div>    */}
                        {/* </div> */}
                        <div className="col-md-12">
                            <div className="faq_lists">
                                {this.state.data.map((item, i) => {
                                    if (i % 2 === 0)
                                        return (
                                            <FAQ
                                                altDropdown={
                                                    this.props.altDropdown
                                                }
                                                key={uniqid()}
                                                question={item.question}
                                                answer={item.answer}
                                            />
                                        );
                                })}
                                {this.state.data.map((item, i) => {
                                    if (i % 2 !== 0)
                                        return (
                                            <FAQ
                                                altDropdown={
                                                    this.props.altDropdown
                                                }
                                                key={uniqid()}
                                                question={item.question}
                                                answer={item.answer}
                                            />
                                        );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default FAQContainer;
