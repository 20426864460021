import React, { Component } from 'react';
import PrivacyNoticeData from './components/PrivacyNoticeData';

class PrivacyNotice extends Component {

    componentDidMount(){
        document.title = window.globalVars.pageTitleBefore + "Privacy policy" + window.globalVars.pageTitleAfter;
    }
   
    render() {
        return (
            <div className="faq_page">
                <div className="faq_content">
                    <div className="containers">
                        <PrivacyNoticeData  />
                    </div>
                </div>    
            </div>
        )
    }

}

export default PrivacyNotice;
