import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './PaidInternship.css'


const PaidInternship = (props) => {
  return (
    <div className={`paidinternship bg-${props.bgColor} text-${props.textColor} ${props.sectionSize}`}>
        <div className="container">
            {props.topRightImg ? 
                <span className="top_right_media">
                    <img src={props.topRightImg} alt="" />
                </span> : null
            }
            {props.bottomLeftImg ? 
                <span className="top_right_media bottom_left_media">
                    <img src={props.bottomLeftImg} alt="" />
                </span> : null
            }
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{props.title}</h2>
                    </div>
                </div>
            </div>    
        </div>
    </div>
  );
};

export default PaidInternship;

