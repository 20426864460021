import React, { Component } from 'react';
import PurpleBackground from '../images/blue-background.png';
import GreenBackground from '../images/green-background-1.png';
import './CTACard.css';


class CTACard extends Component {

    render() {

        return (
            <div className="cta-card" data-style={this.props.style} data-alt-background={this.props.altBackground}>
                <div className="foreground">
                    {this.props.children}
                </div>
                <div className="background">
                    { (this.props.style === 1) &&
                        <img alt="Background" src={GreenBackground} />
                    }
                    { (this.props.style === 2) &&
                        <img alt="Background 2" src={PurpleBackground} />
                    }
                </div>
            </div>
        );

    }

}

export default CTACard;
