import Loadable from 'react-loadable';
import LoaderEmpty from './components/LoaderEmpty.js';
import MetaTags from 'react-meta-tags';
import React, { Component } from 'react';
import Features from './components/Features';
import GlobalSearch from './components/GlobalSearch';
import OurService from './components/OurService';
import Statistics from './components/Statistics';
import './Home.css';


import VectorImg from './images/2023/bottom_vector.png'
import BannerTextImg from './images/2023/home_text_new.png'
import HeroBg from './components/core_modules/HeroBg.js';
import PaidInternship from './components/core_modules/PaidInternship.js';
import VideoIcon from './images/2023/ClapperBoard.png'
import GameFrameIcon from './images/2023/Frame.png'
import AlbumFrameIcon from './images/2023/album.png'
import FilmRollImg from './images/2023/filmroll.png'
import IntershipsSectors from './components/core_modules/IntershipsSectors.js';
import ContentMedia from './components/core_modules/ContentMedia.js';
import VisitAdvicePortal from './components/core_modules/VisitAdvicePortal.js';


const HomeIntroPN = Loadable({ loader: () => import ('./components/HomeIntroPN.js'), loading: LoaderEmpty });
const CompaniesSlider = Loadable({ loader: () => import ('./components/CompaniesSlider.js'), loading: LoaderEmpty });
const BlogPreview = Loadable({ loader: () => import ('./components/BlogPreview.js'), loading: LoaderEmpty });
const Reviews = Loadable({ loader: () => import ('./components/Reviews.js'), loading: LoaderEmpty });

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            showBlog: false,
            maintenance: false
        }
    }
    componentDidMount() {
        console.log('check auto pull')
        document.title = window.globalVars.pageTitleBefore + "Home" + window.globalVars.pageTitleAfter;
        window.scrollTo(0, 0)
        window.globalVars.utilities.trackPageview("/");
        window.globalVars.utilities.trackPageData({
            pageName: 'Home',
            destinationURL: window.location.href
        });

        setTimeout(function() {
            this.setState({ showBlog: true })
        }.bind(this), 1000);

        if (window.location.href.indexOf("maintenance") !== -1) {
            this.setState({ maintenance: true });
        }
    }

    render() {
        return (
            <div style={ (window.region === "PN") ? { backgroundColor: '#f4f4f4' } : {} }>
                { (window.region === "PN") &&
                    <MetaTags>
                        <meta name="robots" content="index" />
                        <meta name="googlebot" content="index" />
                        <meta id="meta-description" name="description" content="Calling all hungry students! Are you ready to Stir Up Your Future? We’ve partnered with GradBay to bring you internships surrounding your passions! How nice."/>
                    </MetaTags>
                }
                <HeroBg
                    pageName="home"
                    BannerTextImg={BannerTextImg}
                    tagLine="true"
                    vectorImage={VectorImg}
                />
                <PaidInternship
                    sectionSize="full"
                    title="GET PAID TO DO AN INTERNSHIP YOU LOVE"
                    bgColor="red"
                    textColor="white"
                    topRightImg={VideoIcon}
                    bottomLeftImg={GameFrameIcon}
                />
                <IntershipsSectors
                    title="and start exploring your dream industries"
                    bottomLeftImg={AlbumFrameIcon}
                    pageLayout="Internship"
                />
                {/* <ContentMedia /> */}
                <PaidInternship
                    title="See what life looks like when you Stir UP YOUR FUTURE"
                    bgColor="yellow"
                    textColor="black"
                    topRightImg={FilmRollImg}
                />
                <VisitAdvicePortal
                    
                />

                {/* { window.region === "PN" && <HomeIntroPN /> }
                { (this.state.showBlog) &&
                    <div className="company-slider-home-wrapper">
                        <h4>{ (window.region === "GB") ? "Currently advertising on GradBay" : "Companies Offering Internships" }</h4>
                        <CompaniesSlider type={ (window.region === "GB") ? null : "pot-noodle-home" } />
                        <div className="slider_right_move pb-3">
                            <CompaniesSlider type={ (window.region === "GB") ? null : "pot-noodle-home-rtl" } />
                        </div>
                    </div>
                } */}
            </div>
        );

    }

}

export default Home;
