import React, { Component } from 'react';
import uniqid from 'uniqid';
import './QuickSearch.css';


class QuickSearch extends Component {

    constructor(props) {

        super(props);

        this.state = {
            data: this.props.data
        }

    }

    // randomTag() {
    //     let tags = ["Glasgow", "London", "Aberdeen", "Birmingham", "Full-time", "£28.000", "Health", "Technology", "Manchester", "Digital", "Sales", "Start up", "HR", "Tech", "Design", "Sport", "Retail", "Operations", "Developer", "Designer", "French", "German", "Chinese", "Spanish"];
    //     return tags[Math.floor((Math.random() * tags.length))]
    // }

    render() {

        let data = [
            {
                query: [
                    ["Account Management", "20-24K", "Central London"],
                    ["Digital Marketing", "Analyst", "East London"],
                    ["Marketing", "16-19K", "East London"],
                    ["Tech Start-Up", "20-24K", "Central London"]
                ]
            },
            {
                query: [
                    ["Media Planner", "Digital", "Central London"],
                    ["Account Executive", "Client Services", "Account Management"],
                    ["Market Research", "20-24K", "Central London"],
                    ["Sales", "Business Development", "Recruitment"]
                ]
            },
            {
                query: [
                    ["Healthcare", "Central London", "Analyst"],
                    ["Sport", "Marketing", "20-24K"],
                    ["Retail", "Operations", "20-24K"],
                    ["Data Analyst", "Media", "20-24K"]
                ]
            }
        ]

        return (
            <div className="quick-search clearfix">
                <div className="main-container">
                    <h2>Most Popular Searches</h2>
                    {data.map(item => {
                        return (
                            <div key={uniqid()} className="category">
                                {item.query.map(item => {
                                    return <button key={uniqid()} onClick={() => {this.props.onChange(item)}}>{item.join(", ")}</button>
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );

    }

}

export default QuickSearch;
