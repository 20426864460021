import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './VisitAdvicePortal.css';
import TechheadPhones from '../../images/2023/techheadphones.png'
import VideoThumbnail1 from '../../images/2023/video1.png'
import VideoThumbnail2 from '../../images/2023/video2.png'
import VideoThumbnail3 from '../../images/2023/video3.png'
import VideoPlayerModal from '../VideoPlayerModal';
import { useState } from 'react';


const VisitAdvicePortal = (props) => {
  const [videoModalUrl,setVideoModalUrl] = useState(null)

  const openVideoModal = (url) => {
    setVideoModalUrl(url)
  };

  const closeVideoModal = () => {
    setVideoModalUrl(null)
  };

  return (
    <>
      <div className="visitAdvicePortal">
          <span className="bottom_left_media">
              <img src={TechheadPhones} alt="" />
          </span> 
          <div className="container">
              <div className="row">
                <div className="col-md-12">
                    <div className="video_content_wrap">
                      <div className="inner_video_wrap">
                        <figure>
                          <span className="play_icon" onClick={()=>openVideoModal("https://gradbay.s3.eu-west-2.amazonaws.com/FILM-POT-NOODLE.mp4")}>
                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
                              <circle cx="31.5411" cy="31.5411" r="31.0411" fill="#333333" fill-opacity="0.5" stroke="black"/>
                              <path d="M46.3884 28.6001L26.8043 18.7665C25.7514 18.241 24.5262 18.2786 23.5116 18.8791C22.497 19.4796 21.9036 20.5305 21.9036 21.6941V41.38C21.9036 42.5436 22.497 43.5945 23.5116 44.195C24.0477 44.514 24.6603 44.6829 25.2537 44.6829C25.7706 44.6829 26.3066 44.5703 26.7852 44.3264L46.3692 34.4927C47.4987 33.9297 48.1878 32.8038 48.1878 31.5652C48.1878 30.3266 47.5179 29.1631 46.3884 28.6001Z" fill="black"/>
                            </svg>
                          </span>
                          <img src={VideoThumbnail1} alt="video thumbnail" />
                        </figure>
                      </div>
                      <div className="inner_video_wrap">
                        <figure>
                        <span className="play_icon" onClick={()=>openVideoModal("https://gradbay.s3.eu-west-2.amazonaws.com/GAMING-POT-NOODLE.mp4")}>
                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
                              <circle cx="31.5411" cy="31.5411" r="31.0411" fill="#333333" fill-opacity="0.5" stroke="black"/>
                              <path d="M46.3884 28.6001L26.8043 18.7665C25.7514 18.241 24.5262 18.2786 23.5116 18.8791C22.497 19.4796 21.9036 20.5305 21.9036 21.6941V41.38C21.9036 42.5436 22.497 43.5945 23.5116 44.195C24.0477 44.514 24.6603 44.6829 25.2537 44.6829C25.7706 44.6829 26.3066 44.5703 26.7852 44.3264L46.3692 34.4927C47.4987 33.9297 48.1878 32.8038 48.1878 31.5652C48.1878 30.3266 47.5179 29.1631 46.3884 28.6001Z" fill="black"/>
                            </svg>
                          </span>
                          <img src={VideoThumbnail3} alt="video thumbnail" />
                        </figure>
                      </div>
                      <div className="inner_video_wrap">
                        <figure>
                          <span className="play_icon" onClick={()=>openVideoModal("https://gradbay.s3.eu-west-2.amazonaws.com/TECH-POT-NOODLE.mp4")}>
                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
                              <circle cx="31.5411" cy="31.5411" r="31.0411" fill="#333333" fill-opacity="0.5" stroke="black"/>
                              <path d="M46.3884 28.6001L26.8043 18.7665C25.7514 18.241 24.5262 18.2786 23.5116 18.8791C22.497 19.4796 21.9036 20.5305 21.9036 21.6941V41.38C21.9036 42.5436 22.497 43.5945 23.5116 44.195C24.0477 44.514 24.6603 44.6829 25.2537 44.6829C25.7706 44.6829 26.3066 44.5703 26.7852 44.3264L46.3692 34.4927C47.4987 33.9297 48.1878 32.8038 48.1878 31.5652C48.1878 30.3266 47.5179 29.1631 46.3884 28.6001Z" fill="black"/>
                            </svg>
                          </span>
                          <img src={VideoThumbnail2} alt="video thumbnail" />
                        </figure>
                      </div>
                    </div>
                    <div className="visit_advice_cta text-center">
                      <Link to="/advice-portal" className="visit_advice_cta_btn">Visit our Advice Portal</Link>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <VideoPlayerModal
        url={videoModalUrl}
        // type={video_type}
        autoplay={true}
        close={() => {
          closeVideoModal();
        }}
      />
    </>
  );
};

export default VisitAdvicePortal;

