/* eslint-disable */
import CloseIcon from '-!svg-react-loader!../icons/x.svg';
import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import './TagsContainer.css';

class TagsContainer extends Component {

    constructor(props) {
        super(props);
        this.divRef = React.createRef()
        this.state = {
            tags: [],
            showAll: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            tags: nextProps.tags
        })
    }

    componentDidMount() {
        this.setState({
            tags: this.props.tags
        })
    }

    onClose(i) {
        if (typeof this.props.onClose !== 'undefined') {
            this.props.onClose(i);
        }
    }

    onClick(e, i) {
        if (typeof this.props.onClick !== 'undefined') {
            this.props.onClick(e, i);
        }
    }

    viewMore = (category, status) => {
        if (status) {
            this.setState(prevData => (
                {
                    ...prevData,
                    [category]: false
                }
            ))
        } else {
            this.setState(prevData => (
                {
                    ...prevData,
                    [category]: category
                }
            ))
        }
    }

    showMoreButton = (category) => {
        const categoryKey = category + 'Button'
        this.setState(prevData => (
            {
                ...prevData,
                [categoryKey]: true
            }
        ))
    }

    render() {
        if (this.state.tags === undefined) {
            return (
                <div className="tags-container">
                    <Skeleton width="90px" />
                    <Skeleton width="90px" />
                    <Skeleton width="100px" />
                    <Skeleton width="110px" />
                    <Skeleton width="100px" />
                </div>
            );
        }

        if (this.state.tags.length === 0) {
            return "";
        }

        let tags = JSON.parse(localStorage.getItem("tags"));

        if (this.props.groupped && tags && tags.candidateTags) {

            let categories = {
                // "Job Type": [],
                "Sector": [],
                "Skills": [],
                // "Trait": [],
                "Location": [],
                "Languages": [],
            }

            for (let i = 0; i < tags.tags.length; i++) {
                let tag = tags.tags[i];
                tag.cat = tag.cat.replace("J ", "").replace("G ", "");
                if (categories[tag.cat]) {
                    categories[tag.cat].push(tags.tags[i].text);
                } else {
                    // console.log("NOT FOUND: " + tags.tags[i].text)
                }
            }

            if (this.props.title) {

                let location = null;
                let sector = null;
                let jobCategoryTags = "";
                for (let i = 0; i < this.state.tags.length; i++) {
                    let tagName = this.state.tags[i].name;
                    for (let category in categories) {
                        if (categories[category].indexOf(tagName) !== -1) {
                            if (category === "Sector" && !sector) {
                                sector = tagName
                            } else if (category === "Location" && !location) {
                                location = tagName
                            }
                            break;
                        }
                    }
                }
                if (sector && location) {
                    jobCategoryTags = sector + " " + this.props.type + " in " + location
                } else if (sector) {
                    jobCategoryTags = sector + " " + this.props.type
                } else if (location) {
                    jobCategoryTags = this.props.type + " in " + location
                } else {
                    jobCategoryTags = this.props.type
                }
                console.log("@@ SETTING TITLE " + this.props.title.replace(/ \|/g, ','))
                document.title = window.globalVars.pageTitleBefore + this.props.title.replace(/ \|/g, ',') + " - " + jobCategoryTags + window.globalVars.pageTitleAfter;

            }



            return (
                <div className="tags-container groupped" data-region={this.props.region} data-show-all={this.state.showAll} data-style={this.props.theme || 1}>
                    {Object.keys(categories).map(category => {
                        let categoryTags = categories[category];
                        let count = 0
                        let catName = category.replace(/ +/g, "");
                        let temp = catName + 'Button'

                        return (
                            <section key={category} className={`profile_tag ${catName}`}>
                                <h5 className="category-name">
                                    {category.replace("Type", "Function")}
                                    {this?.state?.[temp] &&
                                        <span ref={this.divRef} className="view_more_btn hide" onClick={() => this.viewMore(catName, this?.state?.[catName])}>{this?.state?.[catName] ? 'View Less' : 'View More'}</span>
                                    }

                                </h5>
                                <div className="category-tags">
                                    {this.state.tags.map((tag) => {

                                        if (categoryTags.indexOf(tag.name.split(" - ")[0]) !== -1) {
                                            count++
                                            return (
                                                <>
                                                    {(count < 4) ?
                                                        <div key={tag.id} data-id={tag.id} className='tag'>
                                                            <span> {(tag.name)}</span>
                                                        </div>
                                                        : <>
                                                            {this?.state?.[temp] ?
                                                                null
                                                                : this.showMoreButton(catName)
                                                            }
                                                            {
                                                                this?.state?.[catName] ? <div key={tag.id} data-id={tag.id} className='tag'>
                                                                    <span> {(tag.name)}</span>
                                                                </div> : ''
                                                            }
                                                        </>
                                                    }
                                                </>
                                            )

                                        }
                                        //console.log("NOT FOUND " + tag.name)
                                    })}
                                </div>
                            </section>
                        )
                    })
                    }
                    {(this.props.region !== "PN") && <button onClick={() => { this.setState({ showAll: !this.state.showAll }) }}>{(this.state.showAll) ? "Show less tags" : "Show all tags"}</button>}
                </div >
            )

        }

        return (
            <div className="tags-container" data-style={this.props.theme || 1}>
                {this.state.tags.map(item => {
                    let tagText = item.name || item.text;
                    let tagPresent = this?.props?.selectedTag?.find(element => element == tagText);
                    return (<div style={{ background: tagPresent ? '#DDDDDD' : '' }} onClick={(e) => { this.onClick(e, (item.name || item.text)); }} key={item.id} className="tag" data-id={item.id}>
                        <span>{tagText}</span>
                        {(this.props.closeable ? <CloseIcon onClick={() => this.onClose(item.id)} /> : "")}
                    </div>)
                })}
            </div>

        );

    }

}

export default TagsContainer;
