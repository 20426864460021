import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './HeroBg.css'


const HeroBg = (props) => {
  return (
    <div className={`hero ${props.pageName}`}>
        <div className="container">
            {props.vectorImage ? 
            <div className="home_bottom_vector">
                <img src={props.vectorImage} alt="banner vector" />
            </div>
            : null
            }
            <div className="banner_vector banner_text_wrap" >
                <img src={props.BannerTextImg} className="banner_text" alt="banner vector" />
            </div>
            {props.tagLine ? <div className="tagline_bg">
                {/* <h3><span>#</span>joinjay</h3> */}
                <Link to="/all/internships" className="cta_btn">
                    Explore Internships
                </Link>
            </div> : null}
        </div>
    </div>
  );
};

export default HeroBg;

