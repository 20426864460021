import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './ContentMedia.css'
import MediaImg from '../../images/2023/jai.png'

const ContentMedia = (props) => {
  return (
    <div className="content_media">
       <div className="container">
        <div className="row">
            <div className="col-md-6">
                <figure>
                    <img src={MediaImg} alt="Hestabit" />
                </figure>
            </div>
            <div className="col-md-6">
                <div className="content_section">
                    <h2>Jay's here to help!</h2>
                    <p>Take it from Jay, your regular 9-5 isn't for everyone. Tired of the blinding office lights, Jay decided to Stir Up his future and follow his passions. </p>
                    <p>So, what are you waiting for? It's time to</p>
                    {/* <h4><span className='hash font-bold'>#</span>JoinJay</h4> */}
                    {/* <Link to="/jaysstory" className="cta_btn">Jay's Story</Link> */}
                </div>
            </div>
         </div>
       </div>
    </div>
  );
};

export default ContentMedia;

