import React, { Component } from 'react';
import './Loader.css';
import Spinner from './Spinner';


class PageLoader extends Component {

    render() {

        return (
            <div className="full-screen-loader">
            </div>
        );

    }

}

export default PageLoader;
