import React, { Component } from 'react';
import './Countdown.css';

class Countdown extends Component {

    constructor(props) {
        super(props);
        console.log(this.props.target)
        let nextTarget = {day: this.props.target.getDate(), month: this.props.target.getMonth(), year: this.props.target.getFullYear()};
        let headerText = this.figureOutHeaderText(nextTarget.day);
        let targetDate = new Date(nextTarget.year, nextTarget.month, nextTarget.day);
        this.targetDate = targetDate.getTime();
        this.getTimeTill(this.targetDate, (timeRemaining)=>{
            this.state = {
            timeRemaining,
            headerText
            }
        });
    }

    figureOutNextTargetDate(){
        let current = new Date();
        let year = current.getFullYear();
  
        let month;
        if(current.getMonth() == 11){
          month = 0; 
          year++;
        } else { 
          month = current.getMonth();
        }
            
        let day = current.getDate();
        if(day == 1){
          day = 2;
        } else if(day >= 2 && day < 20) {
          day = 20;
        } else if(day >= 20){
          day = 1;
          month++;
          if(month == 11){
            month = 0;
            year++;
          }
        }
        return {
          year,
          month,
          day
        }
    }
    figureOutHeaderText(day){
       if(day == 1){
         return "Till the next theme is annouced:";
       } else if(day >= 2 && day < 20) {
         return "Till the voting begins:";
       } else if(day >= 20){
         return "Till the winner is decided:";
       }
    }
    getTimeTill(targetTime, callback){
       let current = new Date();
       let currentTime = current.getTime();
       let millis = targetTime - currentTime;
       let f = Math.floor;
       let seconds = f(millis / 1000);
       let minutes = f(seconds / 60);
       let hours = f(minutes / 60);
       let daysRemaining = f(hours / 24);
       let hoursRemaning = (hours % 24);
       let minutesRemaning = (minutes % 60);
       let secondsRemaning = (seconds % 60);
      
        let timeRemaing = {
          "days": daysRemaining, 
          "hours": hoursRemaning,
          "minutes": minutesRemaning,
          "seconds": secondsRemaning
        };
       callback(timeRemaing);
    }
    componentDidMount(){
      this.timeInterval = setInterval(() => {
        this.getTimeTill(this.targetDate,(timeRemaining)=>{
          this.setState({
            timeRemaining
          })
        });
      }, 1000);
    }
    componentWillUnMount(){
      clearInterval(this.timeInterval);
    }

    render() {

        return (
            <div className='countdown'>
                {/* <h2>{(this.props.targetI === 0) ? "1st step ends:" : (this.props.targetI === 1) ? "2nd step ends:" : "Last step ends:"}</h2> */}
                <section>
                    <div className="days section">
                        <label>{this.state.timeRemaining.days}</label>
                        <p>Days</p>
                    </div>
                    <div className="hours section">
                        <label >{this.state.timeRemaining.hours}</label>
                        <p>Hours</p>
                    </div>
                    <div className="minutes section">
                        <label>{this.state.timeRemaining.minutes}</label>
                        <p>Minutes</p>
                    </div>
                    <div className="minutes section">
                        <label>{this.state.timeRemaining.seconds}</label>
                        <p>Seconds</p>
                    </div>
                </section>
            </div>
        );

    }

}

export default Countdown;
