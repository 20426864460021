const _ = require('lodash')

function isEmpty(value) {
    return !_.trim(value) ? true : false
}

function isEmail(value) {
    return value && /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value.trim()) ? true : false
}

function isNumber(value) {
    return value && /^[0-9]*$/i.test(value) ? true : false
}

function isChar(value) {
    return value && value.match(/^[a-zA-Z ]+(\s{0,1}[a-zA-Z ])*$/) ? true : false;
}

function isAlphanumeric(value) {
    return value && value.match(/^[0-9a-zA-Z,]+(\s{0,1}[0-9a-zA-Z, ])*$/) ? true : false;
}

function isLowerCase(value) {
    return value && /[a-z]/.test(value) ? true : false;
}

function isUpperCase(value) {
    return value && /[A-Z]/.test(value) ? true : false;
}

function isBlankSpace(value) {
    return value && / /.test(value) ? true : false;
}

function minLenght(value, length) {
    return value && value.length < length ? true : false;
}

function maxLenght(value, length) {
    return value && value.length > length ? true : false;
}

function minMaxLenght(value, min_lenght, max_lenght) {
    return value && (value.length < min_lenght || value.length > max_lenght) ? true : false;
}

function isPassUpperLowerNumAndSpecialChar() {
    return value && value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$/) ? true : false
}

function isAlphanumericWithSpace(value) {
    return value && !value.match(/^[a-zA-Z0-9 ]*$/) ? true : false
}

function isEmoji(value) {
    return value && value.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/) ? true : false
}

function validateAge(dateString, age) {
    // Parse the user input date
    const userDate = new Date(dateString);
    
    // Get the current date
    const currentDate = new Date();
    
    // Calculate the age difference in years
    const ageDiff = currentDate.getFullYear() - userDate.getFullYear();
    
    if (ageDiff < age) {
      return false;
    } else if (ageDiff === age) {
      // If the user's birthdate is in the current year, check the months and days
      const currentMonth = currentDate.getMonth();
      const userMonth = userDate.getMonth();
      if (userMonth > currentMonth) {
        return false;
      } else if (userMonth === currentMonth) {
        const currentDay = currentDate.getDate();
        const userDay = userDate.getDate();
        if (userDay > currentDay) {
          return false;
        }
      }
    }
    
    return true;
}

module.exports = {
    isEmpty,
    isEmail,
    isNumber, 
    isChar,
    isAlphanumeric,
    isLowerCase,
    isUpperCase,
    isBlankSpace,
    minLenght,
    maxLenght,
    minMaxLenght,
    isPassUpperLowerNumAndSpecialChar,
    isAlphanumericWithSpace,
    isEmoji,
    validateAge
}