import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Footer.css';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() {
        return (
            <div className='footer'>
                <div className="background">
                </div>
                <div className="foreground">
                    <div className="main-container clearfix">
                        <div className="quick-links">
                            <h2>Quick Links</h2>

                            <Link to="/jobs">Search Jobs</Link>
                            <Link to="/privacy">Privacy Policy</Link>
                            <Link to="/about">About Us</Link>
                            <Link to="/terms/employers">T&C (Employers)</Link>
                            {/*<Link to="/terms/employers-subscription">T&C Subscription (Employers)</Link>*/}
                            <Link to="/terms">T&C (Graduates)</Link>
                            {/*<Link to="/terms/potnoodle-graduates">Pot Noodle Internships T&C (Graduates)</Link>*/}
                            <Link to="/privacy">Privacy Policy</Link>
                            <Link to="/graduates">Search Grads</Link>
                            <Link to="/faq">Frequently Asked Questions</Link>
                            <Link to="/insight">Insight</Link>
                            <Link to="/contact-us">Contact Us</Link>
                            <a href="tel:0203-976-6500">Call 0203 976 6500</a>
                            
                        </div>

                        <div className="credits">

                            <p>GradBay</p>

                            <TrustBox />

                        </div>

                    </div>

                </div>

            </div>
        );

    }

}

const TrustBox = () => {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div ref={ref} className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5bd9e3e51323830001470b78" data-style-height="20px" data-style-width="100%" data-theme="light">>
            <a href="https://uk.trustpilot.com/review/gradbay.com" target="_blank" rel="noopener"> Trustpilot
            </a>
        </div>
    );
};

export default Footer;
