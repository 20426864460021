import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './IntershipsSectors.css'
import MusicImg from '../../images/2023/music_lock.png'
import GameImg from '../../images/2023/game.png'
import TechImg from '../../images/2023/tech.png'
import FilmImg from '../../images/2023/film.png'
import PublishingImg from '../../images/2023/publishing.png'
import Hospitality from '../../images/2023/hospitality.png'
import InterviewImg from '../../images/2023/plan_image.png'

const IntershipsSectors = (props) => {

  return (
    <div className={`interships_sectors ${props.bgImgPosition}`}>
        {props.topRightImg ? 
            <span className="top_right_media">
                <img src={props.topRightImg} alt="" />
            </span> : null
        }
        {props.bottomLeftImg ? 
            <span className={`top_right_media bottom_left_media ${props.bottomLeftImgPosition}`}>
                <img src={props.bottomLeftImg} alt="" />
            </span> : null
        }
       {props.pageLayout == 'Internship' ? 
        <div className="container">
        <div className="row">
            <div className="col-md-12">
            {props.title ? <h2>{props.title}</h2> : null}
                <div className="internship_sectors_wrap">
                    <div className="sector_inner">
                        <div className="media_wrap">
                            <Link to="/music" className="whole_link"></Link>
                            <h3>Music <span>Music</span></h3>
                        </div>
                        <span className="sector_icon music">
                            <img src={MusicImg} alt="music Image" />
                        </span>
                    </div>
                    <div className="sector_inner Gaming">
                        <div className="media_wrap">
                            <Link to="/gaming" className="whole_link"></Link>
                            <h3>Gaming <span>Gaming</span></h3>
                        </div>
                        <span className="sector_icon gaming">
                            <img src={GameImg} alt="Gaming Image" />
                        </span>
                    </div>
                    <div className="sector_inner">
                        <div className="media_wrap">
                            <Link to="/tech" className="whole_link"></Link>
                            <h3>Tech <span>Tech</span></h3>
                        </div>
                        <span className="sector_icon tech">
                            <img src={TechImg} alt="Tech Image" />
                        </span>
                    </div>
                    <div className="sector_inner">
                        <div className="media_wrap">
                            <Link to="/film" className="whole_link"></Link>
                            <h3>Film <span>Film</span></h3>
                        </div>
                        <span className="sector_icon film">
                            <img src={FilmImg} alt="Film Image" />
                        </span>
                    </div>
                    <div className="sector_inner Publishing">
                        <div className="media_wrap">
                            <Link to="/publishing" className="whole_link"></Link>
                            <h3>Publishing <span>Publishing</span></h3>
                        </div>
                        <span className="sector_icon Publishing">
                            <img src={PublishingImg} alt="Publishing Image" />
                        </span>
                    </div>
                    <div className="sector_inner Hospitality">
                        <div className="media_wrap">
                            <Link to="/hospitality" className="whole_link"></Link>
                            <h3>Hospitality <span>Hospitality</span></h3>
                        </div>
                        <span className="sector_icon Hospitality">
                            <img src={Hospitality} alt="Hospitality Image" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
       </div>
       : <>
            <div className="container content_section_wrap">
                <div className="row">
                    <div className="col-md-6">
                        <div className="content_wrap">
                            <h3>WELCOME TO The HOME OF ALL THINGS INTERNSHIPS</h3>
                            <p>This is the place to find that all important career advice for your next career win.</p>
                            {/* <p>Whether you are going to <h4><span className='hash font-bold'>#</span>joinjay</h4> or simply need a bit of help, we've got you covered.</p> */}
                            <p>Check out our articles below.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content_media_wrap">
                            <figure>
                                <img src={InterviewImg} alt="interview Image" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </>
        }
    </div>
  );
};

export default IntershipsSectors;

