// import ReactGA4 from "react-ga4"

class Utilities {

    GAInitialized = false;

    getFromCache = (id) => {
        let data = localStorage.getItem(id + '-cache');
        if (data) {
            try {
                data = JSON.parse(data);
            } catch (e) {
                console.error(e);
                return null;
            }

        }
        return data;
    }

    getViewHistory = (type) => {

        let user = localStorage.getItem("user");

        if (!user) {
            return [];
        }

        user = JSON.parse(user);

        if (!user.viewHistory) {
            return [];
        }

        let viewHistory = user.viewHistory.filter(item => item.category === type).reverse();
        let ids = [];

        for (let i = 0; i < viewHistory.length; i++) {
            let id = viewHistory[i].bullhornId;
            if (ids.indexOf(id) === -1) {
                ids.push(id);
            }
        }

        return ids;

    }

    randomStr = (length, all) => {
        let text = "";
        let possible = "abcdefghijklmnopqrstuvwxyz";
        if (all) possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    }

    saveToCache = (id, data) => {
        localStorage.setItem(id + '-cache', JSON.stringify(data));
    }

    timeSince = (date) => {

        let seconds = Math.floor((new Date() - new Date(date)) / 1000);

        let interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " years ago";
        }

        interval = Math.floor(seconds / 2592000);

        if (interval > 1) {
            return interval + " months ago";
        }

        interval = Math.floor(seconds / 86400);

        if (interval > 1) {
            return interval + " days ago";
        }

        interval = Math.floor(seconds / 3600);

        if (interval > 1) {
            return interval + " hours ago";
        }

        interval = Math.floor(seconds / 60);

        if (interval > 1) {
            return interval + " mins ago";
        }

        return "just now";

    }

    capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    parseUrl = (url) => {
        let urlParts = url.split('/');
        urlParts.splice(0, 3);
        return urlParts
    }

    ordinalSuffix = (i) => {

        let j = i % 10,
            k = i % 100;

        if (j === 1 && k !== 11) {
            return i + "st";
        }

        if (j === 2 && k !== 12) {
            return i + "nd";
        }

        if (j === 3 && k !== 13) {
            return i + "rd";
        }

        return i + "th";

    }

    // initTracking = () => {

    //     if ((window.location.hostname.indexOf("potnoodleinternships-update.hestawork.com") !== -1) || (window.location.hostname.indexOf("localhost") !== -1)) {
    //         ReactGA4.initialize("G-DZQN0BLXPP")
    //     } else if (window.location.hostname.indexOf("potnoodleinternships.com") !== -1) {
    //         ReactGA4.initialize("G-VLC1PTBGHW")
    //     }

    //     let user = JSON.parse(localStorage.getItem("user"));

    //     if ((user !== null) && (user !== undefined)) {

    //         if (user.type === 3) {
    //             window.amplitude.setUserId(user.bullhornContactId)
    //         } else if (user.type === 4) {
    //             window.amplitude.setUserId(user.bullhornId)
    //         } else {
    //             window.amplitude.setUserId("a")
    //         }

    //         window.amplitude.setUserProperties({
    //             'email': user.email
    //         })

    //     } else {

    //     }

    //     this.GAInitialized = true;

    // }

    trackEvent = (category, action, strProps, objProps) => {
    
        let gaEvent = {
            category: category,
            action: action,
        };
    
        if (strProps !== undefined) {
            gaEvent.label = strProps.toString();
        }
    
        if (objProps) {
            Object.assign(gaEvent, objProps);
        }
    
        // Log event to Amplitude
        if (window.amplitude) {
            window.amplitude.logEvent(action, objProps);
        } else {
            console.error('Amplitude is not initialized.');
        }
    
        // Log event to GA4
        // if (typeof gtag === 'function') {
        //     gtag('event', action, {
        //         'event_category': category,
        //         'event_label': strProps,
        //         ...objProps
        //     });
        // } else {
        //     console.error('Google Analytics is not initialized.');
        // }
    }

    trackPageview = (url) => {
        // if (typeof gtag === 'function') {
        //     gtag('config', 'G-DZQN0BLXPP', {
        //         'page_path': '/' + url
        //     });
        // } else {
        //     console.error('Google Analytics is not initialized.');
        // }
    }
    

    isENU = (field) => {

        if ((field === undefined) || (field === null) || (field === "")) {
            return true;
        } else {
            return false;
        }

    }

    isNU = (field) => {

        if ((field === undefined) || (field === null)) {
            return true;
        } else {
            return false;
        }

    }

    htmlToText = (html) => {
        let div = document.createElement("div");
        div.innerHTML = html;
        return (div.textContent || div.innerText || "");
    }

    getBase64 = (file) => {

        return new Promise(function (resolve, reject) {

            let reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result)
            };

            reader.onerror = function (error) {
                reject(error)
            };

        })

    }

    dayDifference = (date1, date2) => {
        return Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
    }

    replaceAll = (str, search, replacement) => {
        return str.replace(new RegExp(search, 'g'), replacement);
    };

    dataURLtoFile = (dataurl, filename) => {

        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });

    }

    generateRandomStr = (length) => {

        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;

    }

    shorten = (str, maxLen, separator = ' ') => {
        if (str.length <= maxLen) return str;
        return str.substr(0, str.lastIndexOf(separator, maxLen));
    }

    shortenToChar = (str, maxLen) => {
        if (str.length <= maxLen) return str;
        return str.substr(0, maxLen) + "..";
    }

    formatDate = (dateObj, format) => {

        var str = new Date();

        if (dateObj !== undefined) {
            str = new Date(dateObj);
        }

        if ((typeof dateObj === 'string') || (typeof dateObj === 'number')) {
            str = new Date(dateObj * 1000);
        }

        var dateStr = "";
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        var months;
        var days;
        var years;
        var hours;
        var minutes;

        switch (format) {

            case "MM/YYYY":
                months = (str.getMonth() + 1);
                years = str.getFullYear();
                if (months < 10) months = "0" + months
                dateStr = months + "/" + years;
                break;

            case "DD/MM/YYYY":
                months = (str.getMonth() + 1);
                days = (str.getDate());
                years = str.getFullYear();
                if (months < 10) months = "0" + months
                if (days < 10) days = "0" + days
                dateStr = days + "/" + months + "/" + years;
                break;

            case "DD/MM":
                months = (str.getMonth() + 1);
                days = (str.getDate());
                if (months < 10) months = "0" + months
                if (days < 10) days = "0" + days
                dateStr = days + "/" + months;
                break;

            case "YYYY-MM-DD":
                months = (str.getMonth() + 1);
                days = (str.getDate());
                years = str.getFullYear();
                if (months < 10) months = "0" + months
                if (days < 10) days = "0" + days
                dateStr = years + "-" + months + "-" + days;
                break;

            case "HH:MM":
                hours = str.getHours()
                minutes = str.getMinutes()
                if (hours < 10) hours = "0" + hours;
                if (minutes < 10) minutes = "0" + minutes
                dateStr = hours + ":" + minutes;
                break;

            case "mm DD":
                months = monthNames[(str.getMonth())];
                days = (str.getDate());
                if (days < 10) days = "0" + days
                dateStr = months + " " + days;
                break;

            default:
                months = (str.getMonth() + 1);
                if (months < 10) months = "0" + months
                years = str.getFullYear();
                if (years < 10) years = "0" + years
                days = (str.getDate());
                if (days < 10) days = "0" + days
                hours = str.getHours()
                if (hours < 10) hours = "0" + hours
                minutes = str.getMinutes()
                if (minutes < 10) minutes = "0" + minutes
                dateStr = years + "-" + months + "-" + days + " " + hours + ":" + minutes
                break;

        }

        return dateStr;

    }

    JSONparse = (data) => {
        if (data) {
            let replace = /&quot;/gi;
            let replase2 = /&shy;/gi;
            let valid_data = data.replace(replace, '"').replace(replase2, '').replace(/­\n/g, '').replace(/­­­­­-/g, '')
            let json_data = JSON.parse(valid_data.replace(/(\r\n|\n|\r)/gm, ""))
            return json_data;
        } else {
            return
        }
    }

    trackPageData = (data) => {
        // const isMobile = () => {
        //     var check = false;
        //     (function(a) {
        //         if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
        //                 .test(a)
        //                 || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
        //                     .test(a.substr(0, 4))) {
        //             check = true;
        //         }
        //     })(navigator.userAgent || navigator.vendor || window.opera);
        //     return check;
        // }

        // var channelVal;
        // if (isMobile()) {
        //     channelVal = "Mobile Site";
        // } else {
        //     channelVal = "Brand Site";
        // }

        // let digitalData = {
        //     siteInfo : {
        //         channel : '',
        //         sitetype : 'Non-D2'
        //     },
        //     page : {
        //         pageInfo : {
        //             destinationURL : data.destinationURL || "DESTINATION URL-To be set on link clicks"
        //         },
        //         category : {
        //             pageType : data.pageType || "PAGE TYPE-To be set on each page change"
        //         }
        //     },
        //     video : [],
        //     campaign: [],
        //     product : [],
        //     privacy : {
        //         accessCategories : [ {
        //             domains : []
        //         } ]
        //     },
        //     component : [],
        //     trackingInfo : {
        //         GID : '',
        //         "un" : "",
        //         tool : [ {
        //             ids : ""
        //         } ]
        //     },
        //     promotion : []
        // }

        // digitalData.siteInfo.channel = channelVal;
        // digitalData.page.category.primaryCategory = channelVal;
        // digitalData.trackingInfo = {};
        // digitalData.trackingInfo.tool = [ {} ];
        // digitalData.trackingInfo.tool[0] = {};
        // digitalData.trackingInfo.tool[1] = {};
        // digitalData.privacy = {}; //Optional
        // digitalData.page.attributes = {};
        // digitalData.privacy.accessCategories = [ {} ]; //Optional
        // digitalData.privacy.accessCategories[0].domains = []; //Optional
        // digitalData.event = [];
        // digitalData.page.pageInfo.pageName = data.pageName || "PAGENAME"; //To be set on each page change
        // digitalData.page.pageInfo.language = data.language || "EN";
        // digitalData.page.category.subCategory1 = data.subCategory1 || "Foods";
        // digitalData.page.category.subCategory2 = data.subCategory2 || "Foods"
        // digitalData.page.category.subCategory3 = data.subCategory3 || "Foods";
        // digitalData.page.attributes.contentType = data.contentType || "CONTENT TYPE OF PAGE"; // To be set on each page change. Should contain 404 for error pages
        // digitalData.page.attributes.articleName = data.articleName || "ARTICLE NAME";  //Populate only on Article pages
        // digitalData.page.attributes.brandCategory = data.brandCategory || 'BRAND CATEGORY';
        // digitalData.page.attributes.country = data.country || 'UK';
        // digitalData.page.attributes.globalBrand = data.globalBrand || 'Pot Noodle UK';
        // digitalData.page.attributes.localBrand = data.localBrand || 'Pot Noodle UK';
        // //digitalData.user[n].profile[n].profileInfo.crmID = 'CRM ID'; //Optional
        // digitalData.sitespeed = [];
        // digitalData.sitespeed.applicationID = "applicationID";
        // digitalData.sitespeed.licenseKey = "LicenceKey";
        // digitalData.sitespeed.locale = "locale";
        // digitalData.trackingInfo.tool[0].id = data.gaTrackingId || "UA-46983247-3,G-F94K5BRH01";
        // // digitalData.trackingInfo.tool[1].id = data.adobeReportSuiteId || "unilever-potnoodleinternships-uk-uat,unilever-allbrands-uat";
        // digitalData.trackingInfo.tool[1].id = data.adobeReportSuiteId || "unilever-potnoodleinternships-uk,unilever-global-allbrands";
        // // digitalData.siteInfo.internalDomain = data.internalDomain || "potnoodleinternships-update.hestawork.com";
        // digitalData.siteInfo.internalDomain = data.internalDomain || "potnoodleinternships.com";
    }
}

export default Utilities;
