/* eslint-disable */
import FavoriteIcon from '-!svg-react-loader!../icons/favorite.svg';
import React, { Component } from 'react';
import { AiFillHeart } from 'react-icons/ai';
import Skeleton from 'react-loading-skeleton';
import { Link } from "react-router-dom";
import uniqid from 'uniqid';
import noProfilePhotoImage from '../images/no-profile-picture.png';
import PotNoodleBadge from '../images/potnoodle-badge-2.png';
import './CandidatePreviewCard.css';
import permissions from './Permissions';
import TagsContainer from './TagsContainer.js';
import utilities from './Utilities';
import WishlistCollectionPicker from './WishlistCollectionPicker.js';

let Utilities = new utilities();
let Permissions = new permissions();

class CandidatePreviewCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            favorite: false,
            potnoodle: false,
            lastOpened: null,
            removeWishList:false,
            wishlistCollectionPickerModal: null,
        }
    }

    componentDidMount() {
        if (this.props.data.id !== undefined) {
            let wishList = JSON.parse(localStorage.getItem("candidateWishList"));
            if (Utilities.isENU(window.globalVars.user)) {
                let guestWishListType = JSON.parse(localStorage.getItem("guestWishListType"));
                if (guestWishListType === "candidate") {
                    wishList = JSON.parse(localStorage.getItem("guestWishList"));
                }
            }
            if ((wishList !== undefined) && (wishList !== null)) {
                let wishListEntries = wishList.filter(item => item.startsWith(this.props.data.id + "||"));
                this.setState({ favorite: (wishListEntries.length > 0) })
            }
            let user = localStorage.getItem("user");
            if (user) {
                user = JSON.parse(user);
                if (user.viewHistory) {
                    for (let i = 0; i < user.viewHistory.length; i++) {
                        if ((user.viewHistory[i].category === "candidate") && (user.viewHistory[i].bullhornId === this.props.data.id)) {
                            this.setState({ lastOpened: "Viewed " + window.globalVars.utilities.timeSince(user.viewHistory[i].date) })
                        }
                    }
                }
            }
            if (this.props.data.customTextBlock3 !== undefined && this.props.data.customTextBlock3 !== null && this.props.data.customTextBlock3.length !== 0) {
                for (let i = 0; i < this.props.data.customTextBlock3.length; i++) {
                    if (this.props.data.customTextBlock3[i] === "Pot Noodle") {
                        this.setState({
                            potnoodle: true
                        })
                    }
                }
            }
        }
    }
    clickOn = (e) => {
        this.props.onClick && this.props.onClick()
        if (this.props.data.id === undefined) {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }
    }
    pushTags = (e, tag) => {
        if (typeof this.props.tagsPush !== 'undefined') {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            this.props.tagsPush([tag]);
        }
    }

    toggleWishListPicker = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (window.globalVars.user) {
            this.setState({ wishlistCollectionPickerModal: this.props.data.id })
        } else {
            this.toggleWishList();
        }
    }

    toggleWishList = (collection) => {
        let wishList = [];
        if (!collection) {
            collection = "Default"
        }
        if (Utilities.isENU(window.globalVars.user)) {
            window.location.href = "/login";
            return
        } else {
            wishList = JSON.parse(localStorage.getItem("candidateWishList"))
        }
        let favorite = false;
        let serverAction = "add"
        if ((wishList === undefined) || (wishList === null)) {
            wishList = [];
        }
        let hash = this.props.data.id + "||" + collection;
        let location = wishList.indexOf(hash)
        if (location > -1) {
            wishList.splice(location, 1);
            serverAction = "remove"
        } else {
            wishList.push(hash);
            favorite = true;
            window.globalVars.utilities.trackEvent("wishlist", "Added to Wishist", this.state.id, {
                id: this.props.data.id
            });
        }

        if (Utilities.isENU(window.globalVars.user)) {
            localStorage.setItem("guestWishList", JSON.stringify(wishList));
            localStorage.setItem("guestWishListType", JSON.stringify("candidate"));
        } else {
            localStorage.setItem("candidateWishList", JSON.stringify(wishList));
        }

        this.setState({
            favorite: favorite
        })

        var node = document.getElementsByClassName('wish-list-count');
        if (node[0] !== undefined) node[0].innerHTML = wishList.length
        if (node[1] !== undefined) node[1].innerHTML = wishList.length

        if (!Utilities.isENU(window.globalVars.user)) {
            window.globalVars.server.api({
                method: "post",
                url: "api/user/wishlist/" + serverAction,
                data: {
                    id: hash
                },
                then: function (res) {
                    if(res?.data?.success){
                        this.setState({ removeWishList: true });
                    }
                }.bind(this),
                catch: function (e) {
                }.bind(this)
            })
        }
    }

    closeModalOnSave = () =>{
        this.setState({ wishlistCollectionPickerModal: null });
        if(this.state.removeWishList){
            window.location.href='/saved/grads'
        }
    }

    strSplice = (sentence, start, delCount, newSubStr) => {
        return sentence.slice(0, start) + newSubStr + sentence.slice(start);
    }

    getMatchesInText = (candidate) => {
        let DOM = "";
        candidate.matches.map(item => {
            DOM += '<div class="sentence">'
            item.match.map(match => {
                match = match.toLowerCase();
                let disposableSentence = item.sentence.toLowerCase();
                let startIndex = disposableSentence.lastIndexOf(match);
                while (startIndex !== -1) {
                    startIndex = disposableSentence.lastIndexOf(match);
                    if (startIndex === -1)
                        continue;
                    item.sentence = this.strSplice(item.sentence, startIndex + match.length, 0, "</span>");
                    item.sentence = this.strSplice(item.sentence, startIndex, 0, "<span class='word' data-match='true'>");
                    disposableSentence = disposableSentence.substr(0, startIndex);
                }
                DOM += item.sentence.split(" ").map(item => {
                    return item === "|" ? '<span class="hidden" style="width:' + (Math.random() * (72 - 24 + 1) + 24) + 'px;"></span>' : item
                }).join(" ");
            })
            DOM += '</div>'
        })
        return { __html: DOM };
    }

    render() {
        let candidate = this.props.data || {
            id: undefined,
            tags: undefined,
            profilePictureURL: undefined,
            name: undefined,
        };
        if (!Utilities.isENU(candidate.id)) {
            let preparedTags = [];
            if ((candidate.customTextBlock3 !== undefined) && (candidate.customTextBlock3 !== null)) {
                preparedTags = candidate.customTextBlock3.map(item => {
                    return {
                        name: item,
                        id: uniqid()
                    }
                })
            }
            let profilePicture = candidate.customTextBlock7;
            if (Utilities.isENU(profilePicture)) {
                profilePicture = noProfilePhotoImage

            } else {
                profilePicture = window.globalVars.utilities.JSONparse(profilePicture);
                if (profilePicture.status !== "Public") {
                    profilePicture = noProfilePhotoImage
                } else {
                    profilePicture = window.globalVars.bucket + window.globalVars.bucketGraduateProfilePictureDir + candidate.id + "/" + profilePicture.fileName
                }
            }
            candidate.name = candidate.firstName + " " + candidate.lastName;
            if (candidate.name === "- -") candidate.name = candidate.id;
            candidate.profilePictureURL = profilePicture;
            candidate.description = ((Utilities.isENU(candidate.customTextBlock1)) ? "No Description" : candidate.customTextBlock1),
                candidate.tags = preparedTags
        }
        let FavoriteButton;
        // Show favorite button if logged out or logged in as an employer
        if (Permissions.isAllowed("CANDIDATE_WISHLIST")) {
            FavoriteButton = <button className="favorite" onClick={(e) => { this.toggleWishListPicker(e) }} data-active={this.state.favorite}>
                <AiFillHeart color='red' />
            </button>
        }

        return (
            <div className="col-md-4">
                <Link onClick={(e) => { this.clickOn(e); }} to={"/view-profile/" + candidate.id || "#"} className="candidate-preview-card" data-matches={(candidate.matches !== undefined)} data-potnoodle={this.state.potnoodle} data-loading={candidate.id === undefined ? "true" : "false"}>
                    <div className="clearfix">
                        <div className="left">
                            <div className="profile-picture">
                                {candidate.profilePictureURL === undefined ? <Skeleton heigh="120" /> : <img alt={"Cover for " + candidate.name} src={candidate.profilePictureURL} />}
                                {(this.state.potnoodle) &&
                                    <img alt="PotNoodle Badge" className="potnoodle-badge" src={PotNoodleBadge} />
                                }
                            </div>
                            <h3 className="name" data-redacted={(candidate.name === "- -")}>{candidate.name || <Skeleton />}</h3>
                        </div>
                        <div className="right">
                            <TagsContainer
                                tags={candidate.tags}
                                closeable={false}
                                theme="2"
                                onClick={(e, tag) => { this.pushTags(e, tag) }}
                            />
                            {(this.state.lastOpened !== null) &&
                                <label className="last-open">{this.state.lastOpened}</label>
                            }
                            <div className="options">
                                {candidate.id === undefined ? <div className="main"> <Skeleton width="120px" /> </div> : <button className="main">View Profile</button>}
                                <div className="button-group">
                                    {candidate.id === undefined ?
                                        <div>
                                            <Skeleton width="28px" />
                                        </div>
                                        :
                                        <div>
                                            {FavoriteButton}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {(candidate.id) && (candidate.matches) &&
                            <div className="matches clearfix" dangerouslySetInnerHTML={this.getMatchesInText(candidate)}>

                            </div>
                        }
                    </div>
                    <WishlistCollectionPicker
                        isOpen={this.state.wishlistCollectionPickerModal !== null}
                        id={this.state.wishlistCollectionPickerModal}
                        toggle={() => this.closeModalOnSave()}
                        select={(collection) => { this.toggleWishList(collection) }}
                    />
                </Link>
            </div>            
        );
    }
}

export default CandidatePreviewCard;
