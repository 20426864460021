import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isSafari } from 'react-device-detect';
import './BackButton.css';


class BackButton extends Component {

    static contextTypes = {
        //router: () => true,
        //router: function() { return PropTypes.func.isRequired; }
        //router: PropTypes.object
        router: PropTypes.object.isRequired
    }

    back = () => {

        if (window.location.href.indexOf("/submission/") !== -1) {
            window.close();
            return;
        }

        if (isSafari) {
            this.context.router.history.go(-2)
        } else {
            this.context.router.history.goBack();
        }

        if (this.props.onClick !== undefined) {
            this.props.onClick();
        }

    }

    render() {

        return (
            <a className="back-button underline-on-hover" href="#" onClick={this.back}>{ (window.location.href.indexOf("/submission/") !== -1) ? "Back to applications" : this.props.title}</a>
        );

    }

}

export default BackButton;
