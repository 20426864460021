/* eslint-disable */
import CheckIcon from '-!svg-react-loader!../icons/check.svg';
import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import Skeleton from 'react-loading-skeleton';
import './ConfirmationInput.css';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import EditButton from './EditButton';
import { FiX } from "react-icons/fi";



class ConfirmationInput extends Component {

    constructor(props) {
        super(props);
        let college_names = localStorage.getItem("collegeNames")
        this.state = {
            value: undefined,
            originalValue: undefined,
            editing: false,
            error: null,
            success: null,
            setOriginal: false,
            college_names: college_names !== undefined ? JSON.parse(college_names) : [],
            ambassador: []
        };
    }

    componentDidMount(){
        if(this.props?.autosuggest === 'AMBASSADOR'){
            this.getAmbassador()
        }
    }

    componentWillReceiveProps(nextProps) {

        let value = nextProps.value;

        if ( (nextProps.saveFromParent === true) && (this.state.editing) ) {

            this.toggleEditing();

        }

        if (value !== undefined) {

            if (value === null) {
                value = ""
            }

            if ( (value !== this.state.value) && (!this.state.setOriginal) ) {

                if (this.props.type === "date") {

                    if (value.indexOf("/") > -1) {
                        let year = value.substr(6, 4);
                        let month = value.substr(3, 2);
                        let day = value.substr(0, 2);
                        value = year + "-" + month + "-" + day;
                    }

                }

                this.setState({
                    value: value,
                    originalValue: value,
                    setOriginal: true
                })

            }

        }

        if (nextProps.errorIfEmpty) {
            if(this.props){
                let dom = document.getElementById(this?.props && this?.props?.domId && this?.props?.domId);
                if (dom?.value === "") {
                    this.setState({
                        error: "This field is required",
                        success: false
                    })
                }

            }
            
        
            
        }

    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {

    }

    toggleEditing = () => {

        if (this.props.readOnly) {
            return;
        }

        if (this.state.editing) {

            this.setState({
                editing: false
            })

            this.props.onChange(this.state.value);

            if (typeof this.props.onSaved !== 'undefined') {
                this.props.onSaved();
            }

            if (this.state.originalValue === this.state.value) {
                return;
            }

            if (this.props.noServerSave) {
                return;
            }

            window.globalVars.server.api({
                method: "post",
                url: (this.props.endpoint || "/api/graduate/update-graduate-field"),
                data: {
                    bullhornId: this.props.id,
                    field: this.props.field,
                    value: this.state.value
                },
                then: function(res) {

                    if (res.data.success === false) {

                        this.setState({
                            error: res.data.error,
                            success: false
                        })

                    } else {

                        document.getElementById(this.props.domId).classList = "";

                        this.setState({
                            success: true,
                            error: null
                        }, function() {
                            setTimeout(function() {
                                this.setState({
                                    success: null
                                })
                            }.bind(this), 2500)
                        })

                    }

                }.bind(this),
                catch: function(e) {

                    this.setState({
                        error: "Unexpected Error",
                        success: false
                    })

                }.bind(this)
            })

        } else {

            this.setState({
                editing: true
            })

            if (typeof this.props.onEdited !== 'undefined') {
                this.props.onEdited();
            }

        }

    }

    getAmbassador = (e) => {
        window.globalVars.server.api({
            method: "get",
            url:  "/api/brand-ambassador/get-all",
            then: function(res) {
                let data = res.data.data
                let arrayObj = data?.sort((a,b) => a.name > b.name ? 1 : -1).map(item => { return { text: item.name, id: item.name } });
                this.setState({ambassador: arrayObj})
            }.bind(this)
        })
    }

    handleChange = (e) => {
        this.setState({
            value: e.target.value
        })
    }

    render() {
        let autosuggest_arr = []
        if(this.props.autosuggest === 'COLLEGE_NAME_PN'){
            autosuggest_arr =  this.state.college_names;
        }else if(this.props.autosuggest === "AMBASSADOR"){
            autosuggest_arr = this.state.ambassador
        }else {
            autosuggest_arr =  (this.props.autosuggest === "UNIVERSITIES") ? window.globalVars.autoSuggestions[this.props.autosuggest].slice(3, window.globalVars.autoSuggestions[this.props.autosuggest].length) : window.globalVars.autoSuggestions[this.props.autosuggest]
        }
        return (
            <div ref={node => this.node = node} data-type={this.props.dataType} className="confirmation-input" data-green={(this.props.greenOnComplete && this.state.value !== "" &&  this.state.value !== null && this.state.value !== undefined)} data-editing={this.state.editing} data-readonly={this.props.readOnly} data-loading={this.state.value === undefined}>
                { ( (this.state.value !== undefined) && (this.props.dataType === "autosuggest") ) &&
                    <div className={ (this.state.error !== null) ? "error" : "ww" }>
                        <input
                            onKeyUp = {(e) => { this.handleChange(e) }}
                            ref = {(input) => { this.nameInput = input; }}
                            onBlur = {this.toggleEditing}
                            onFocus = {this.toggleEditing}
                            defaultValue = {this.state.value}
                            type = {this.props.type}
                            name = {this.props.domId}
                            id = {this.props.domId}
                            placeholder = {this.props.placeholder}
                            tabIndex = {this.props.tabIndex}
                            readOnly = {this.props.readOnly}
                            autoComplete = "new-password"
                            className={ (this.state.error !== null) ? "error" : "" }
                        />
                        <Autocomplete
                            ref={el => this.input = el}
                            getItemValue={(item) => item.text}
                            items={autosuggest_arr || []}
                            shouldItemRender={(item, value) => item.text.toLowerCase().indexOf(this.state.value.toLowerCase()) > -1}
                            renderItem={(item, isHighlighted) => {
                                return (<div key={item.title} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                    {item.text}
                                </div>)
                            }
                            }
                            onMenuVisibilityChange={(a) => {
                                if (a) {
                                    this.toggleEditing();
                                }
                            }}
                            value={this.state.value}
                            onChange={(e) => {
                                this.setState({
                                    value: e.target.value
                                }, function() {
                                    // this.toggleEditing();
                                })
                                if (this.props.immediateChange) {
                                    this.props.onChange(e.target.value)
                                }

                            }
                            }
                            onSelect={(val) => {
                                if ( (this.props.field === "university") && (val.endsWith(", University of")) ) {
                                    val = "University of " + val.replace(", University of", "");
                                }

                                this.setState({
                                    value: val
                                }, function() {
                                    this.toggleEditing();
                                    this.input.blur()
                                })

                            }}
                        />
                        {this.state.value !== '' && this.props.isClearInput !== undefined && this.props.isClearInput === true && 
                            <span  className="close_icon"
                               onClick={() => {
                                    this.setState({
                                        value: ''
                                    }, function() {
                                        this.props.onClearInput()
                                    })
                                }}
                            ><FiX/></span>
                        }
                    </div>
                }
                { ( (this.state.value !== undefined) && (this.props.type !== "date") && (this.props.dataType !== "autosuggest") ) &&
                    <input
                        onKeyUp = {(e) => { this.handleChange(e) }}
                        ref = {(input) => { this.nameInput = input; }}
                        onBlur = {this.toggleEditing}
                        onFocus = {this.toggleEditing}
                        defaultValue = {this.state.value}
                        type = {this.props.type}
                        name = {this.props.domId}
                        id = {this.props.domId}
                        placeholder = {this.props.placeholder}
                        tabIndex = {this.props.tabIndex}
                        readOnly = {this.props.readOnly}
                        autoComplete = "new-password"
                        className={ (this.state.error !== null) ? "error" : "" }
                    />
                }
                { ( (this.state.value !== undefined) && (this.props.type === "date") ) &&
                    <div>
                        {/*<DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={ (this.state.value) ? new Date(Date.parse(this.state.value)) : null }
                            ref={el => this.input = el}
                            onSelect={(date) => {
                                let formattedDate = window.globalVars.utilities.formatDate(date, "DD-MM-YYYY");
                                this.setState({
                                    value: formattedDate
                                }, function() {
                                    this.setState({
                                        editing: true
                                    }, function() {
                                        this.toggleEditing()
                                    })
                                })
                            }}
                            onSelect={(date) => {
                            }}
                        />*/}
                        <input
                            style={{ minHeight: 52 }}
                            onKeyUp = {(e) => { this.handleChange(e) }}
                            onChange = {(e) => { this.handleChange(e) }}
                            onSelect = {(e) => { this.handleChange(e) }}
                            ref = {(input) => { this.nameInput = input; }}
                            onBlur = {this.toggleEditing}
                            onFocus = {this.toggleEditing}
                            defaultValue = {this.state.value}
                            type = {this.props.type}
                            name = {this.props.domId}
                            data-id= "date"
                            id = {this.props.domId}
                            placeholder = {this.props.placeholder}
                            tabIndex = {this.props.tabIndex}
                            readOnly = {this.props.readOnly}
                            autoComplete = "new-password"
                            className={ (this.state.error !== null) ? "error" : "" }
                        />
                    </div>
                }
                { (this.state.value === undefined) &&
                     <Skeleton />
                }
                { ( (!this.props.readOnly) && (this.state.editing) ) &&
                    <button className="save-btn" onClick={() => {
                        if (this.props.dataType === "autosuggest") {
                            this.input.blur()
                            this.toggleEditing();
                        } else if (this.props.type === "date") {
                            this.input.blur()
                        } else {
                            this.nameInput.blur();
                        }
                    }} >
                        Save
                    </button>
                }
                { ( (!this.props.readOnly) && (!this.state.editing) && (this.props.dataType !== "date") ) &&
                    <EditButton onClick={() => {
                        if (this.props.dataType === "autosuggest") {
                            this.input.focus()
                        } else if (this.props.type === "date") {
                            this.nameInput.focus()
                        } else {
                            this.nameInput.focus();
                        }
                    }} />
                }
                { (this.state.error !== null) &&
                    <label className="error">{this.state.error}</label>
                }
                { (this.state.success) &&
                    <label className="success">
                        <CheckIcon />
                    </label>
                }
            </div>
        );

    }

}

export default ConfirmationInput;
