/* eslint-disable */
import HiringToolsIcon from '-!svg-react-loader!../icons/hiring-tools.svg';
import InsighIcon from '-!svg-react-loader!../icons/insight.svg';
import EngagementIcon from '-!svg-react-loader!../icons/messenger.svg';
import PostIcon from '-!svg-react-loader!../icons/post.svg';
import SearchIcon from '-!svg-react-loader!../icons/search-2.svg';
import VideoIcon from '-!svg-react-loader!../icons/videocv-2.svg';
import React, { Component } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import './Features.css';



class Statistics extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <div className="features-home">
                <div className="main-container">
                    <h2>Benefits</h2>
                    <div className="features-wrapper">
                        <div className="feature">
                            <PostIcon />
                            <div>
                                <h3>Post</h3>
                                <p>One place to post grad jobs, receive applications and screen profiles.</p>
                            </div>
                        </div>
                        <div className="feature">
                            <SearchIcon />
                            <div>
                                <h3>Search</h3>
                                <p>Widen the search and use deep dive search tools to help you discover uncovered talent.</p>
                            </div>
                        </div>
                        <div className="feature">
                            <VideoIcon />
                            <div>
                                <h3>Video Profiles</h3>
                                <p>Use video profiles to screen candidates and improve efficiency in your hiring process.</p>
                            </div>
                        </div>
                        <div className="feature">
                            <EngagementIcon />
                            <div>
                                <h3>Engagement</h3>
                                <p>Create hiring campaigns that engage generation Z more effectively than traditional job boards.</p>
                            </div>
                        </div>
                        <div className="feature">
                            <HiringToolsIcon />
                            <div>
                                <h3>Diverse Hiring Tools</h3>
                                <p>Create a diverse selection of candidates for each job and ensure hiring bias is removed from your campaign.</p>
                            </div>
                        </div>
                        <div className="feature">
                            <InsighIcon />
                            <div>
                                <h3>Insight</h3>
                                <p>Use live data driven inisght to learn how to reach your hiring targets more effectively.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );

    }

}

export default Statistics;
