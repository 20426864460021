import React, { Component } from 'react';
import uniqid from 'uniqid';
import CandidatePreviewCard from './CandidatePreviewCard';
import './RecentlyViewed.css';
import VacancyPreviewCard from './VacancyPreviewCard';


class RecentlyViewed extends Component {

    constructor(props) {

        super(props);

        this.state = {
            ids: [undefined, undefined],
            content: []
        }

    }

    componentDidMount() {

        let recentViews = JSON.parse(localStorage.getItem("recentViews"));

        if ( (recentViews === null) || (recentViews === undefined) ) {
            recentViews = {
                candidates: [],
                vacancies: []
            };
            localStorage.setItem("recentViews", JSON.stringify(recentViews));
        }

        let currentTypeResults = (this.props.type === "vacancy") ? recentViews.vacancies : recentViews.candidates;

        if (currentTypeResults.length > 1) {

            this.setState({
                ids: currentTypeResults
            }, function() {
                this.getContent();
            })

        }

    }

    getContent = () => {

        if (this.state.ids[0] !== undefined) {

            let apiURL;

            if (this.props.type === "candidate") {
                apiURL = "/api/graduate/get-by-id"
            } else if (this.props.type === "vacancy") {
                apiURL = "/api/vacancy/get-by-id"
            }

            window.globalVars.server.api({

                method: "post",
                url: apiURL,
                data: {
                    id: this.state.ids
                },

                then: function(res) {


                    this.setState({
                        content: res.data.data.data
                    })

                }.bind(this),

                catch: function(e) {
                    this.setState({
                        ids: [undefined, undefined]
                    })
                }.bind(this)
            })

        }

    }

    pushTags = (e, tag) => {

        if (typeof this.props.tagsPush !== 'undefined') {

            if (e !== "ignore") {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }

            this.props.tagsPush([tag]);

        }

    }

    render() {

        return (
            <div className="recently-viewed" data-empty={ (this.state.ids[0] === undefined) }>
                <div className="main-container">
                    <h2>Recently Viewed</h2>
                    <div className="result-list">
                        {
                            ( (this.props.type === "vacancy") && (this.state.ids[0] !== undefined) ) ?
                                <div>
                                    {
                                        this.state.content.map(item => {

                                            return (
                                                <VacancyPreviewCard
                                                    key={( item.id ? "Recent" + item.id : uniqid() )}
                                                    data={item}
                                                    type="vacancy"
                                                    tagsPush={(tags) => { this.pushTags("ignore", tags) }}
                                                    openQuickApplyModal={ (id) => this.openQuickApplyModal(id) }
                                                />
                                            )

                                        })

                                    }
                                </div>
                            :
                                null
                        }
                        {
                            ( (this.props.type === "candidate") && (this.state.ids[0] !== undefined) ) ?
                                <div>
                                    {
                                        this.state.content.map(item => {

                                            return (
                                                <CandidatePreviewCard
                                                    key={( item.id ? "Recent" + item.id : uniqid() )}
                                                    tagsPush={(tags) => { this.pushTags("ignore", tags) }}
                                                    data={item}
                                                />
                                            )

                                        })

                                    }
                                </div>
                            :
                                null
                        }
                    </div>
                </div>
            </div>
        );

    }

}

export default RecentlyViewed;
